import React, { useState } from 'react';
import AuthContext from './AuthContext';

export default function AuthContextProvider(props) {
  const { children } = props;
 
  const unAuthState = {
    authorized: false,
    email:null,
  };
  //已設定登入成功寫進localStorage
  // 先查看 localStorage 的資料是否表示已登入
  //拿取localstorage資料
  const localAuthStr = localStorage.getItem('loginToken');

  //localAuthStr 有資料才開始
  let localAuth = { ...unAuthState };
  if (localAuthStr) {
    try {
      localAuth = JSON.parse(localAuthStr);
      //展開localAuth 塞進authorized
      localAuth = { ...localAuth, authorized: true };
      
    } catch (ex) {}
  }
  const [auth, setAuth] = useState(localAuth);
  const [email, setEmail] = useState(localAuth.email);


 

  return (
    <AuthContext.Provider value={{ ...auth, setAuth, auth,setEmail,email }}>
      {children}
    </AuthContext.Provider>
  );
}