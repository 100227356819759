import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import style from './styles/LineTemplateComponents.module.css';
import '../../styles/LineAnt.css';
import './styles/LineComponentsRce.css';
import './styles/LineComponentsAnt.css';
import { Layout, Menu, Input, Space, Button, InputNumber, List, Radio, Tag, Pagination } from 'antd';
import {
  EyeOutlined,
  PlusOutlined,
  EditFilled,
  EditOutlined,
  RollbackOutlined,
  DeleteFilled,
  DeleteOutlined
} from '@ant-design/icons';

function LineTemplateComponents(props) {
  const [lineTemplateComponentsCRUD, setLineTemplateComponentsCRUD] = useState('LineTemplateComponentsRead');
  const [searchString, setSearchString] = useState(""); // 搜尋欄輸入的字串
  const [currentPage, setCurrentPage] = useState(1); // 當前頁面
  const [numberPerPage, setNumberPerPage] = useState(6); // 一頁有幾個模板數
  const [lineTemplateComponentsDataArray, setLineTemplateComponentsDataArray] = useState([{}]); // 各模板資料
  const [isEditMode, setIsEditMode] = useState([-1, false]); // [第n項tag資料, 第n項是否可編輯]
  const [updateFieldsOnChangeState, setUpdateFieldsOnChangeState] = useState({}); //更新欄位狀態
  const [addFieldsOnChangeState, setAddFieldsOnChangeState] = useState({ name: "", category: "", description: "", priority: 0 }); // 新增模板欄位onChange狀態
  const lineTemplateComponentsNameRef = useRef("");
  const lineTemplateComponentsCategoryRef = useRef("");
  const lineTemplateComponentsDescriptionRef = useRef("");
  const lineTemplateComponentsPriorityRef = useRef("");
  const apiURL = process.env.REACT_APP_APIURL; // API URL

  /*** ANT排版 ***/
  const { Header, Content, Sider } = Layout;

  /*** 搜尋 ***/
  const { Search } = Input;
  const searchOnChange = (event) => {
    const val = event?.target?.value || "";
    setSearchString(val);
  }
  const onSearch = () => {
    console.log(currentPage);
    requestLineTemplateComponents();
    setIsEditMode([-1, false]); // 返回非編輯模式
  }

  /*** 查詢LineTemplateComponents ***/
  const requestLineTemplateComponents = async() => {
    const url = apiURL + "/api/message/v1/chat-tags?" + new URLSearchParams({
      filter: searchString,
      // limit: numberPerPage,
      // offset: currentPage-1
    });
    console.log(url);
    console.log(searchString);
    console.log(numberPerPage);
    console.log(currentPage);
    const response = await fetch(
      url,
      {
        headers: {
          Accept: "application/json",
        },
      }
    )
    .then((res) => res.json())
    .catch((err) => {
      alert(`模板查詢失敗！\n${err}`);
      throw new Error("RequestLineTemplateComponents failed");
    });
    console.log(response);
    setLineTemplateComponentsDataArray(response); 
    // setUpdateFieldsOnChangeState(response); 
    // try {
    //   const response = await axios.get({
    //     method: 'get',
    //     url: url,
    //     responseType: 'json',
    //   });
    //   console.log(response);
    // } catch (error) {
    //   console.error(error);
    // }
  }
  useEffect(() => {
    requestLineTemplateComponents();
  }, []);
  useEffect(() => {
    console.log(lineTemplateComponentsDataArray);
  }, [lineTemplateComponentsDataArray]);

  /*** 分頁切換 ***/
  const changePage = (pageNumber) => {
    console.log('Page: ', pageNumber);
    setCurrentPage(pageNumber);
    setIsEditMode([-1, false]); // 返回非編輯模式
  };
  const clickLineTemplateComponentsMenuItem = (e) => {
    console.log('click ', e);
    const key = e?.key || "";
    if (key.includes("LineTemplateComponentsRead")) {
      setLineTemplateComponentsCRUD("LineTemplateComponentsRead");
    } else if (key.includes("LineTemplateComponentsAdd")) {
      setLineTemplateComponentsCRUD("LineTemplateComponentsAdd");
    } else if (key.includes("LineTemplateComponentsUpdate")) {
      setLineTemplateComponentsCRUD("LineTemplateComponentsUpdate");
    } else if (key.includes("LineTemplateComponentsDelete")) {
      setLineTemplateComponentsCRUD("LineTemplateComponentsDelete");
    } else {
      setLineTemplateComponentsCRUD("");
    }
  };    

  /*** 限制搜尋數量 ***/
  const [value, setValue] = useState(50);

  /*** 更新欄位onChange ***/
  const updateFieldsOnChange = (e) => {
    e.preventDefault();
    // const value = e?.value || "";
    const name = lineTemplateComponentsNameRef?.current?.input?.value || "";
    const category = lineTemplateComponentsCategoryRef?.current?.input?.value || "";
    const description = lineTemplateComponentsDescriptionRef?.current?.input?.value || "";
    const priority = parseInt(lineTemplateComponentsPriorityRef?.current?.input?.value) || 0;
    const data = { name, category, description, priority };
    console.log(lineTemplateComponentsNameRef);
    console.log(lineTemplateComponentsNameRef.current);
    console.log(value);
    console.log(data);
    setUpdateFieldsOnChangeState(data);
  }

  /*** 更新模板 ***/
  const updateTags = async( e, id ) => {
    e.preventDefault();
    const url = apiURL + "/api/message/v1/chat-tags/" + id;
    console.log(url);
    console.log(updateFieldsOnChangeState);
    const response = await fetch(
      url,
      {
        method: 'PUT',
        body: JSON.stringify(updateFieldsOnChangeState),
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
      }
    )
    .then((res) => res.json())
    .catch((err) => {
      alert(`模板更新失敗！\n${err}`);
      throw new Error("Update Tags failed");
    });
    console.log(JSON.stringify(response));
    const responseId = response?._id || "";
    if( !! responseId ) {
      if( Object.values(lineTemplateComponentsDataArray).length > 0 ) {
        for( let el of lineTemplateComponentsDataArray ) {
          if( Object.values(el).includes(id) ) {
            el['name'] = await response?.name || "";
            el['category'] = await response?.category || "";
            el['description'] = await response?.description || "";
            el['priority'] = await response?.priority || 0;
            alert(`模板內容已更新！\n( ID：${id} )`);
          }
        }
        setIsEditMode([-1, false]); // 返回非編輯模式
      }
    }
  }

  /*** 新增欄位onChange ***/
  const addFieldsOnChange = (e) => {
    e.preventDefault();
    const fieldId = e?.target?.id || "";
    const value = e?.target?.value || "";
    if( !! fieldId ) {
      console.log(fieldId);
      console.log(value);
      switch(fieldId) {
        case "TagsName":
          setAddFieldsOnChangeState({...addFieldsOnChangeState, ['name']: value});
          break;
        case "TagsCategory":
          setAddFieldsOnChangeState({...addFieldsOnChangeState, ['category']: value});
          break;
        case "TagsDescription":
          setAddFieldsOnChangeState({...addFieldsOnChangeState, ['description']: value});
          break;
        case "TagsPriority":
          const intValue = parseInt(value);
          setAddFieldsOnChangeState({...addFieldsOnChangeState, ['priority']: intValue});
          break;
      }
    }
  }
  useEffect(() => {
    console.log(addFieldsOnChangeState);
  }, [addFieldsOnChangeState]);

  /*** 儲存最近新增的模板 ***/
  const recentTagsStore = (attrObj) => {
    const recentTagsData = window.localStorage.getItem('rctatt') ?? "";
    let recentTagsArray = !! recentTagsData ? JSON.parse(recentTagsData) : []; 
    recentTagsArray.push(attrObj);
    const tagsDataJson = JSON.stringify(recentTagsArray);
    window.localStorage.setItem('rctatt', tagsDataJson);
  }

  /*** 新增模板 ***/
  const addTags = async(e, id, name) => {
    e.preventDefault();
    console.log(id);
    console.log(name);
    const attrObj = {
      key: id, 
      label: name,
      // onClick: ({ item, key, keyPath, domEvent }) => {
      //   console.log(key);
      // }
    };
    recentTagsStore(attrObj);
    // const url = apiURL + "/api/message/v1/chat-tags/";
    // console.log(url);
    // console.log(addFieldsOnChangeState);
    // const response = await fetch(
    //   url,
    //   {
    //     method: 'POST',
    //     body: JSON.stringify(addFieldsOnChangeState),
    //     headers: {
    //       "Content-Type": "application/json",
    //       "Accept": "application/json",
    //     },
    //   }
    // )
    // .then((res) => res.json())
    // .catch((err) => {
    //   alert(`模板新增失敗！\n${err}`);
    //   throw new Error("Add Tags failed");
    // });
    // console.log(JSON.stringify(response));
    // const responseId = response?._id || "";
    // const responseStatus = response?.statusCode || "";
    // if( ! responseId ) {
    //   const responseErrorMsg = response?.message || "";
    //   if( responseStatus === 500 ) {
    //     alert(`模板新增失敗！\n( 模板名稱已存在或模板內容未輸入完整 )`);
    //   } else if( responseStatus === 400 ) {
    //     alert(`模板新增失敗！\n( 模板名稱已存在或模板內容未輸入完整 )`);
    //   } else {
    //     alert(`模板新增失敗！\n${responseErrorMsg}`);
    //   }
    //   return false;
    // } else {
    //   const name = await response?.name || "";
    //   const category = await response?.category || "";
    //   const description = await response?.description || "";
    //   const priority = await response?.priority || 0;
    //   const data = { _id: responseId, name, category, description, priority };
    //   tagsDataArray.push(data);
    //   alert(`【${name}】模板已新增！\n( ID：${responseId} )`);
    //   setAddFieldsOnChangeState({}); // 清空欄位
    //   requestTags(name); // 查詢已新增的模板
    //   setAfterAddShow({"display":"block"});  // 顯示已新增的模板
    // }
  }  

  /*** 查詢Tags ***/
  const requestTags = async(searchString) => {
    const url = apiURL + "/api/message/v1/chat-tags?" + new URLSearchParams({
      filter: searchString,
    });
    console.log(url);
    console.log(searchString);
    const response = await fetch(
      url,
      {
        headers: {
          Accept: "application/json",
        },
      }
    )
    .then((res) => res.json())
    .catch((err) => {
      alert(`模板查詢失敗！\n${err}`);
      throw new Error("RequestTags failed");
    });
    console.log(response);
    setLineTemplateComponentsDataArray(response); 
  }
  
  /*** 刪除模板 ***/
  const deleteTags = async( e, id, name ) => {
    e.preventDefault();
    if( window.confirm(`確定要刪除【${name}】模板？\n( ID：${id} )`) ) {
      const url = apiURL + "/api/message/v1/chat-tags/" + id;
      console.log(url);
      console.log(updateFieldsOnChangeState);
      const response = await fetch(
        url,
        {
          method: 'DELETE',
          headers: {
            "Accept": "*/*",
          },
        }
      )
      // .then((res) => res.json())
      .then((res) => {
        console.log(res);
        if( res.ok ) {
          for( let [index, el] of lineTemplateComponentsDataArray.entries() ) {
            if( Object.values(el).includes(id) ) {
              lineTemplateComponentsDataArray.splice(index, 1);
              alert(`【${el['name']}】模板已刪除！\n（ID：${id}）`);
            }
          }
          setIsEditMode([-1, false]); // 返回非編輯模式
        } else {
            alert(`模板刪除失敗！`);
            throw new Error("Delete Tags failed");
        }
      })
      .catch((err) => {
        alert(`模板刪除失敗！\n${err}`);
        throw new Error("Delete Tags failed");
      });
      // const responseId = response?._id || "";
      // if( !! responseId ) {
      // }
    } else {
      return;
    }
  }

  /*** 分頁顯示設定 ***/
  const data = [ // 樣板資料
    {
      _id: "001",
      name: "測試模板1",
      category: "模板類別",
      description: "模板描述",
      priority: 0,
      __v: 0
    },
    {
      _id: "002",
      name: "測試模板2",
      category: "模板類別",
      description: "模板描述",
      priority: 0,
      __v: 0
    },
    {
      _id: "003",
      name: "測試模板3",
      category: "模板類別",
      description: "模板描述",
      priority: 0,
      __v: 0
    },
    {
      _id: "004",
      name: "測試模板4",
      category: "模板類別",
      description: "模板描述",
      priority: 0,
      __v: 0
    },
    {
      _id: "004",
      name: "測試模板4",
      category: "模板類別",
      description: "模板描述",
      priority: 0,
      __v: 0
    },
    {
      _id: "004",
      name: "測試模板4",
      category: "模板類別",
      description: "模板描述",
      priority: 0,
      __v: 0
    },
    {
      _id: "004",
      name: "測試模板4",
      category: "模板類別",
      description: "模板描述",
      priority: 0,
      __v: 0
    },
    {
      _id: "004",
      name: "測試模板4",
      category: "模板類別",
      description: "模板描述",
      priority: 0,
      __v: 0
    },
    {
      _id: "004",
      name: "測試模板4",
      category: "模板類別",
      description: "模板描述",
      priority: 0,
      __v: 0
    },
    {
      _id: "004",
      name: "測試模板4",
      category: "模板類別",
      description: "模板描述",
      priority: 0,
      __v: 0
    },
    {
      _id: "004",
      name: "測試模板4",
      category: "模板類別",
      description: "模板描述",
      priority: 0,
      __v: 0
    },
    {
      _id: "004",
      name: "測試模板4",
      category: "模板類別",
      description: "模板描述",
      priority: 0,
      __v: 0
    },
    {
      _id: "004",
      name: "測試模板4",
      category: "模板類別",
      description: "模板描述",
      priority: 0,
      __v: 0
    }
  ];


  useEffect(()=>{
    console.log(isEditMode)
    console.log(isEditMode[0])
    console.log(isEditMode[1])
  },[isEditMode]);
  return (
    <Layout id="lineTemplateComponentsLayout" className={style.lineTemplateComponentsLayout}>
      {/* <div className={`${ComponentStyle.title}`}><h1>LineTemplateComponents</h1></div> */}
      <Content className={style.lineTemplateComponentsOuterContent}>
        <Content className={style.lineTemplateComponentsContent}>
          <section id={"lineTemplateComponentsSearchSec"} className={style.lineTemplateComponentsSearchSec}>
            <div className={style.lineTemplateComponentsSearchDiv}>
              <Search className={`${style.lineTemplateComponentsInput} ${style.lineTemplateComponentsSearch}`} addonBefore={<span className={style.lineTemplateComponentsSearchTitle}>模板查詢</span>} placeholder="請輸入欲查詢的模板名稱" allowClear size="large" onChange={(e) => {searchOnChange(e)}} onSearch={onSearch} enterButton />
            </div>
            <List
              className={style.lineTemplateComponentsList}
              locale={{}}
              pagination={{ 
                position: "bottom", 
                align: "center",
                hideOnSinglePage: true,
                showQuickJumper: true,
                // total: 50,
                current: currentPage,
                pageSize: numberPerPage,
                onChange: changePage
              }}
              dataSource={lineTemplateComponentsDataArray}
              renderItem={(item, index) => (
                Object.keys(lineTemplateComponentsDataArray[0]).includes('_id') ? (
                  <List.Item>
                    <List.Item.Meta
                      className={style.lineTemplateComponentsListItemMeta}
                      avatar={
                        <Tag className={style.lineTemplateComponentsTagIcon} color="geekblue">{item.name}</Tag>
                      }
                      title={
                        <div className={`${style.lineTemplateComponentsDescriptionItem} ${style.lineTemplateComponentsDescriptionItemTitle}`}>
                          <span className={style.lineTemplateComponentsDescriptionID}>{"模板ID："}</span><span className={style.lineTemplateComponentsDescriptionText}>{item._id}</span>
                          <div className={style.lineTemplateComponentsEditAndDelIconDiv}>
                            <PlusOutlined className={style.lineTemplateComponentsAddIcon} onClick={(e) => { addTags(e, item._id, item.name) }} />
                            {/* <EditOutlined className={style.lineTemplateComponentsEditAndDelIcon} onClick={(e) => { setIsEditMode([index, true]) }} /> */}
                            {/* <EditFilled className={style.lineTemplateComponentsAddIcon} /> */}
                            {/* <DeleteOutlined className={style.lineTemplateComponentsEditAndDelIcon} onClick={(e) => { deleteTags(e, item._id, item.name) }} /> */}
                            {/* <DeleteFilled className={style.lineTemplateComponentsAddIcon} /> */}
                          </div>
                        </div>
                      }
                      description={
                        <div className={style.lineTemplateComponentsDescriptionDiv}>
                          <div className={style.lineTemplateComponentsDescriptionItem}>
                            <span className={style.lineTemplateComponentsDescriptionLabel}>{"模板名稱："}</span>
                            <span className={style.lineTemplateComponentsDescriptionText}>{item.name}</span>
                          </div>
                          <div className={style.lineTemplateComponentsDescriptionItem}>
                            <span className={style.lineTemplateComponentsDescriptionLabel}>{"模板類別："}</span>
                            <span className={style.lineTemplateComponentsDescriptionText}>{item.category}</span>
                          </div>
                          <div className={style.lineTemplateComponentsDescriptionItem}>
                            <span className={style.lineTemplateComponentsDescriptionLabel}>{"模板描述："}</span>
                            <span className={style.lineTemplateComponentsDescriptionText}>{item.description}</span>
                          </div>
                          <div className={style.lineTemplateComponentsDescriptionItem}>
                            <span className={style.lineTemplateComponentsDescriptionLabel}>{"優先權："}</span>
                            <span className={style.lineTemplateComponentsDescriptionText}>{item.priority}</span>
                          </div>
                          {/* <div className={style.lineTemplateComponentsDescriptionItem}>
                            <span className={style.lineTemplateComponentsDescriptionLabel}>{"其他資訊："}</span><span className={style.lineTemplateComponentsDescriptionText}>{item.__v}</span>
                          </div> */}
                        </div>
                      }
                    />
                  </List.Item>
                ) : (
                  <></>
                )
              )}
            />
          </section>
        </Content>
      </Content>
    </Layout>
  )
}

export default LineTemplateComponents