import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import style from './styles/navbarstyles.module.css'

import LOGO from './kidProLogo.svg'
import LOGOsmall from './kidProLogoSamll.svg'
import 'bootstrap/dist/css/bootstrap.min.css';

/* uaa整合的部分 */
import Popup from './Popup';

function Navigationbar() {

  const kidprologinURL = process.env.REACT_APP_KIDPROLOGINURL // KidPro 登入導向URL
  const loginToken = window.localStorage.getItem('loginToken') ? window.localStorage.getItem('loginToken') : ""; // 取得Login Token
  let kidproLogoURL =  window.localStorage.getItem('loginToken') ? kidprologinURL + loginToken : "https://kid-pro.com"; // 若Login Token存在，則KidPro LOGO導向首頁並登入；否則僅導向首頁

  return (
    <>
    <Navbar expand="sm" className={`px-3 ${style.Navbar}`}>
      {/* <Container> */}
        <Navbar.Brand href={ kidproLogoURL } target='_blank'>
          <div className={style.kdlogobox}>
            <img  className={style.kdlogo} src={LOGO}></img>
          </div>
          <div className={style.smalllogobox}>
            <img  className={style.smalllogo} src={LOGOsmall}></img>
          </div>
        </Navbar.Brand>
        {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
        <Navbar.Collapse className={style.NavbarCollapse} id="basic-navbar-nav">
          {/* <Nav className="me-auto">
            <NavDropdown title="關於我們" id="basic-nav-dropdown"  className ={style.Navigationbar} >
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="育兒知識" id="basic-nav-dropdown" className ={style.Navigationbar} >
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="課程/商城" id="basic-nav-dropdown" className ={style.Navigationbar}>
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown>

            
           
          </Nav> */}
        </Navbar.Collapse>      
      {/* </Container> */}
    </Navbar>
    {/* <p className ={style.Navbar_promote}>9/1-10/31累計購課2堂 好禮3選1</p> */}
    </>
  );
}

export default Navigationbar;