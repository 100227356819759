import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import style from './styles/LineAttribute.module.css';
import '../../styles/LineAnt.css';
import './styles/LineComponentsRce.css';
import './styles/LineComponentsAnt.css';
import { Layout, Menu, Input, Space, Button, InputNumber, List, Radio, Tag, Pagination } from 'antd';
import {
  EyeOutlined,
  PlusOutlined,
  EditFilled,
  EditOutlined,
  RollbackOutlined,
  DeleteFilled,
  DeleteOutlined
} from '@ant-design/icons';

function LineAttribute(props) {
  const [lineAttributeCRUD, setLineAttributeCRUD] = useState('LineAttributeRead');
  const [searchString, setSearchString] = useState(""); // 搜尋欄輸入的字串
  const [currentPage, setCurrentPage] = useState(1); // 當前頁面
  const [numberPerPage, setNumberPerPage] = useState(6); // 一頁有幾個屬性數
  const [lineAttributeDataArray, setLineAttributeDataArray] = useState([{}]); // 各屬性資料
  const [isEditMode, setIsEditMode] = useState([-1, false]); // [第n項tag資料, 第n項是否可編輯]
  const [updateFieldsOnChangeState, setUpdateFieldsOnChangeState] = useState({}); //更新欄位狀態
  const [addFieldsOnChangeState, setAddFieldsOnChangeState] = useState({ name: "", category: "", description: "", priority: 0 }); // 新增屬性欄位onChange狀態
  const lineAttributeNameRef = useRef("");
  const lineAttributeCategoryRef = useRef("");
  const lineAttributeDescriptionRef = useRef("");
  const lineAttributePriorityRef = useRef("");
  const apiURL = process.env.REACT_APP_APIURL; // API URL

  /*** ANT排版 ***/
  const { Header, Content, Sider } = Layout;

  /*** 搜尋 ***/
  const { Search } = Input;
  const searchOnChange = (event) => {
    const val = event?.target?.value || "";
    setSearchString(val);
  }
  const onSearch = () => {
    console.log(currentPage);
    requestLineAttribute();
    setIsEditMode([-1, false]); // 返回非編輯模式
  }

  /*** 查詢LineAttribute ***/
  const requestLineAttribute = async() => {
    const url = apiURL + "/api/message/v1/chat-tags?" + new URLSearchParams({
      filter: searchString,
      // limit: numberPerPage,
      // offset: currentPage-1
    });
    console.log(url);
    console.log(searchString);
    console.log(numberPerPage);
    console.log(currentPage);
    const response = await fetch(
      url,
      {
        headers: {
          Accept: "application/json",
        },
      }
    )
    .then((res) => res.json())
    .catch((err) => {
      alert(`屬性查詢失敗！\n${err}`);
      throw new Error("RequestLineAttribute failed");
    });
    console.log(response);
    setLineAttributeDataArray(response); 
    // setUpdateFieldsOnChangeState(response); 
    // try {
    //   const response = await axios.get({
    //     method: 'get',
    //     url: url,
    //     responseType: 'json',
    //   });
    //   console.log(response);
    // } catch (error) {
    //   console.error(error);
    // }
  }
  useEffect(() => {
    requestLineAttribute();
  }, []);
  useEffect(() => {
    console.log(lineAttributeDataArray);
  }, [lineAttributeDataArray]);

  /*** 分頁切換 ***/
  const changePage = (pageNumber) => {
    console.log('Page: ', pageNumber);
    setCurrentPage(pageNumber);
    setIsEditMode([-1, false]); // 返回非編輯模式
  };
  const clickLineAttributeMenuItem = (e) => {
    console.log('click ', e);
    const key = e?.key || "";
    if (key.includes("LineAttributeRead")) {
      setLineAttributeCRUD("LineAttributeRead");
    } else if (key.includes("LineAttributeAdd")) {
      setLineAttributeCRUD("LineAttributeAdd");
    } else if (key.includes("LineAttributeUpdate")) {
      setLineAttributeCRUD("LineAttributeUpdate");
    } else if (key.includes("LineAttributeDelete")) {
      setLineAttributeCRUD("LineAttributeDelete");
    } else {
      setLineAttributeCRUD("");
    }
  };    

  /*** 限制搜尋數量 ***/
  const [value, setValue] = useState(50);

  /*** 更新欄位onChange ***/
  const updateFieldsOnChange = (e) => {
    e.preventDefault();
    // const value = e?.value || "";
    const name = lineAttributeNameRef?.current?.input?.value || "";
    const category = lineAttributeCategoryRef?.current?.input?.value || "";
    const description = lineAttributeDescriptionRef?.current?.input?.value || "";
    const priority = parseInt(lineAttributePriorityRef?.current?.input?.value) || 0;
    const data = { name, category, description, priority };
    console.log(lineAttributeNameRef);
    console.log(lineAttributeNameRef.current);
    console.log(value);
    console.log(data);
    setUpdateFieldsOnChangeState(data);
  }

  /*** 更新屬性 ***/
  const updateAttribute = async( e, id ) => {
    e.preventDefault();
    const url = apiURL + "/api/message/v1/chat-tags/" + id;
    console.log(url);
    console.log(updateFieldsOnChangeState);
    const response = await fetch(
      url,
      {
        method: 'PUT',
        body: JSON.stringify(updateFieldsOnChangeState),
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
      }
    )
    .then((res) => res.json())
    .catch((err) => {
      alert(`屬性更新失敗！\n${err}`);
      throw new Error("Update Attribute failed");
    });
    console.log(JSON.stringify(response));
    const responseId = response?._id || "";
    if( !! responseId ) {
      if( Object.values(lineAttributeDataArray).length > 0 ) {
        for( let el of lineAttributeDataArray ) {
          if( Object.values(el).includes(id) ) {
            el['name'] = await response?.name || "";
            el['category'] = await response?.category || "";
            el['description'] = await response?.description || "";
            el['priority'] = await response?.priority || 0;
            alert(`屬性內容已更新！\n( ID：${id} )`);
          }
        }
        setIsEditMode([-1, false]); // 返回非編輯模式
      }
    }
  }

  /*** 新增欄位onChange ***/
  const addFieldsOnChange = (e) => {
    e.preventDefault();
    const fieldId = e?.target?.id || "";
    const value = e?.target?.value || "";
    if( !! fieldId ) {
      console.log(fieldId);
      console.log(value);
      switch(fieldId) {
        case "AttributeName":
          setAddFieldsOnChangeState({...addFieldsOnChangeState, ['name']: value});
          break;
        case "AttributeCategory":
          setAddFieldsOnChangeState({...addFieldsOnChangeState, ['category']: value});
          break;
        case "AttributeDescription":
          setAddFieldsOnChangeState({...addFieldsOnChangeState, ['description']: value});
          break;
        case "AttributePriority":
          const intValue = parseInt(value);
          setAddFieldsOnChangeState({...addFieldsOnChangeState, ['priority']: intValue});
          break;
      }
    }
  }
  useEffect(() => {
    console.log(addFieldsOnChangeState);
  }, [addFieldsOnChangeState]);

  /*** 儲存最近新增的屬性 ***/
  const recentAttributeStore = (attrObj) => {
    const recentAttributeData = window.localStorage.getItem('rctatt') ?? "";
    let recentAttributeArray = !! recentAttributeData ? JSON.parse(recentAttributeData) : []; 
    recentAttributeArray.push(attrObj);
    const attributeDataJson = JSON.stringify(recentAttributeArray);
    window.localStorage.setItem('rctatt', attributeDataJson);
  }

  /*** 新增屬性 ***/
  const addAttribute = async(e, id, name) => {
    e.preventDefault();
    console.log(id);
    console.log(name);
    const attrObj = {
      key: id, 
      label: name,
      // onClick: ({ item, key, keyPath, domEvent }) => {
      //   console.log(key);
      // }
    };
    recentAttributeStore(attrObj);
    // const url = apiURL + "/api/message/v1/chat-tags/";
    // console.log(url);
    // console.log(addFieldsOnChangeState);
    // const response = await fetch(
    //   url,
    //   {
    //     method: 'POST',
    //     body: JSON.stringify(addFieldsOnChangeState),
    //     headers: {
    //       "Content-Type": "application/json",
    //       "Accept": "application/json",
    //     },
    //   }
    // )
    // .then((res) => res.json())
    // .catch((err) => {
    //   alert(`標籤新增失敗！\n${err}`);
    //   throw new Error("Add Attribute failed");
    // });
    // console.log(JSON.stringify(response));
    // const responseId = response?._id || "";
    // const responseStatus = response?.statusCode || "";
    // if( ! responseId ) {
    //   const responseErrorMsg = response?.message || "";
    //   if( responseStatus === 500 ) {
    //     alert(`標籤新增失敗！\n( 標籤名稱已存在或標籤內容未輸入完整 )`);
    //   } else if( responseStatus === 400 ) {
    //     alert(`標籤新增失敗！\n( 標籤名稱已存在或標籤內容未輸入完整 )`);
    //   } else {
    //     alert(`標籤新增失敗！\n${responseErrorMsg}`);
    //   }
    //   return false;
    // } else {
    //   const name = await response?.name || "";
    //   const category = await response?.category || "";
    //   const description = await response?.description || "";
    //   const priority = await response?.priority || 0;
    //   const data = { _id: responseId, name, category, description, priority };
    //   tagsDataArray.push(data);
    //   alert(`【${name}】標籤已新增！\n( ID：${responseId} )`);
    //   setAddFieldsOnChangeState({}); // 清空欄位
    //   requestAttribute(name); // 查詢已新增的標籤
    //   setAfterAddShow({"display":"block"});  // 顯示已新增的標籤
    // }
  }  

  /*** 查詢Attribute ***/
  const requestAttribute = async(searchString) => {
    const url = apiURL + "/api/message/v1/chat-tags?" + new URLSearchParams({
      filter: searchString,
    });
    console.log(url);
    console.log(searchString);
    const response = await fetch(
      url,
      {
        headers: {
          Accept: "application/json",
        },
      }
    )
    .then((res) => res.json())
    .catch((err) => {
      alert(`標籤查詢失敗！\n${err}`);
      throw new Error("RequestAttribute failed");
    });
    console.log(response);
    setLineAttributeDataArray(response); 
  }
  
  /*** 刪除屬性 ***/
  const deleteAttribute = async( e, id, name ) => {
    e.preventDefault();
    if( window.confirm(`確定要刪除【${name}】屬性？\n( ID：${id} )`) ) {
      const url = apiURL + "/api/message/v1/chat-tags/" + id;
      console.log(url);
      console.log(updateFieldsOnChangeState);
      const response = await fetch(
        url,
        {
          method: 'DELETE',
          headers: {
            "Accept": "*/*",
          },
        }
      )
      // .then((res) => res.json())
      .then((res) => {
        console.log(res);
        if( res.ok ) {
          for( let [index, el] of lineAttributeDataArray.entries() ) {
            if( Object.values(el).includes(id) ) {
              lineAttributeDataArray.splice(index, 1);
              alert(`【${el['name']}】屬性已刪除！\n（ID：${id}）`);
            }
          }
          setIsEditMode([-1, false]); // 返回非編輯模式
        } else {
            alert(`屬性刪除失敗！`);
            throw new Error("Delete Attribute failed");
        }
      })
      .catch((err) => {
        alert(`屬性刪除失敗！\n${err}`);
        throw new Error("Delete Attribute failed");
      });
      // const responseId = response?._id || "";
      // if( !! responseId ) {
      // }
    } else {
      return;
    }
  }

  /*** 分頁顯示設定 ***/
  const data = [ // 樣板資料
    {
      _id: "001",
      name: "測試屬性1",
      category: "屬性類別",
      description: "屬性描述",
      priority: 0,
      __v: 0
    },
    {
      _id: "002",
      name: "測試屬性2",
      category: "屬性類別",
      description: "屬性描述",
      priority: 0,
      __v: 0
    },
    {
      _id: "003",
      name: "測試屬性3",
      category: "屬性類別",
      description: "屬性描述",
      priority: 0,
      __v: 0
    },
    {
      _id: "004",
      name: "測試屬性4",
      category: "屬性類別",
      description: "屬性描述",
      priority: 0,
      __v: 0
    },
    {
      _id: "004",
      name: "測試屬性4",
      category: "屬性類別",
      description: "屬性描述",
      priority: 0,
      __v: 0
    },
    {
      _id: "004",
      name: "測試屬性4",
      category: "屬性類別",
      description: "屬性描述",
      priority: 0,
      __v: 0
    },
    {
      _id: "004",
      name: "測試屬性4",
      category: "屬性類別",
      description: "屬性描述",
      priority: 0,
      __v: 0
    },
    {
      _id: "004",
      name: "測試屬性4",
      category: "屬性類別",
      description: "屬性描述",
      priority: 0,
      __v: 0
    },
    {
      _id: "004",
      name: "測試屬性4",
      category: "屬性類別",
      description: "屬性描述",
      priority: 0,
      __v: 0
    },
    {
      _id: "004",
      name: "測試屬性4",
      category: "屬性類別",
      description: "屬性描述",
      priority: 0,
      __v: 0
    },
    {
      _id: "004",
      name: "測試屬性4",
      category: "屬性類別",
      description: "屬性描述",
      priority: 0,
      __v: 0
    },
    {
      _id: "004",
      name: "測試屬性4",
      category: "屬性類別",
      description: "屬性描述",
      priority: 0,
      __v: 0
    },
    {
      _id: "004",
      name: "測試屬性4",
      category: "屬性類別",
      description: "屬性描述",
      priority: 0,
      __v: 0
    }
  ];


  useEffect(()=>{
    console.log(isEditMode)
    console.log(isEditMode[0])
    console.log(isEditMode[1])
  },[isEditMode]);
  return (
    <Layout id="lineAttributeLayout" className={style.lineAttributeLayout}>
      {/* <div className={`${ComponentStyle.title}`}><h1>LineAttribute</h1></div> */}
      <Content className={style.lineAttributeOuterContent}>
        <Content className={style.lineAttributeContent}>
          <section id={"lineAttributeSearchSec"} className={style.lineAttributeSearchSec}>
            <div className={style.lineAttributeSearchDiv}>
              <Search className={`${style.lineAttributeInput} ${style.lineAttributeSearch}`} addonBefore={<span className={style.lineAttributeSearchTitle}>屬性查詢</span>} placeholder="請輸入欲查詢的屬性名稱" allowClear size="large" onChange={(e) => {searchOnChange(e)}} onSearch={onSearch} enterButton />
            </div>
            <List
              className={style.lineAttributeList}
              locale={{}}
              pagination={{ 
                position: "bottom", 
                align: "center",
                hideOnSinglePage: true,
                showQuickJumper: true,
                // total: 50,
                current: currentPage,
                pageSize: numberPerPage,
                onChange: changePage
              }}
              dataSource={lineAttributeDataArray}
              renderItem={(item, index) => (
                // Object.keys(lineAttributeDataArray[0]).includes('_id') && isEditMode[0] === index && isEditMode[1] ? (
                // <List.Item>
                //     <List.Item.Meta
                //       avatar={
                //         <div className={style.lineAttributeTagDiv}>
                //           <Tag className={style.lineAttributeTagIcon} color="geekblue">{item.name}</Tag>
                //         </div>
                //       }
                //       title={
                //         <div className={style.lineAttributeDescriptionItem}>
                //           <span className={style.lineAttributeDescriptionID}>{"屬性ID："}</span><span className={style.lineAttributeDescriptionText}>{item._id}</span>
                //           <div className={style.lineAttributeEditAndDelIconDiv}>
                //             <PlusOutlined className={style.lineAttributeAddIcon} onClick={(e) => { addAttribute(e, item._id, item.name) }} />
                //             {/* <RollbackOutlined className={style.lineAttributeEditAndDelIcon} onClick={(e) => { setIsEditMode(false) }} />
                //             <DeleteOutlined className={style.lineAttributeEditAndDelIcon} onClick={(e) => { deleteAttribute(e, item._id, item.name) }} /> */}
                //             {/* <DeleteFilled className={style.lineAttributeAddIcon} /> */}
                //           </div>
                //         </div>
                //       }
                //       description={
                //         <div className={style.lineAttributeDescriptionDiv} style={{"justifyContent": "center"}}>
                //           <div className={`${style.lineAttributeDescriptionItem} ${style.lineAttributeDescriptionItemName}`}>
                //             <span className={`${style.lineAttributeDescriptionLabel} ${style.lineAttributeDescriptionLabelName}`}>{"屬性名稱："}</span>
                //             <Input className={`${style.lineAttributeDescriptionInput} ${style.lineAttributeDescriptionInputName}`} type="text" defaultValue={item.name} ref={lineAttributeNameRef} maxLength={10} onChange={updateFieldsOnChange}></Input>
                //           </div>
                //           <div className={`${style.lineAttributeDescriptionItem} ${style.lineAttributeDescriptionItemCategory}`}>
                //             <span className={`${style.lineAttributeDescriptionLabel} ${style.lineAttributeDescriptionLabelCategory}`}>{"屬性類別："}</span>
                //             <Input className={`${style.lineAttributeDescriptionInput} ${style.lineAttributeDescriptionInputCategory}`} type="text" defaultValue={item.category} ref={lineAttributeCategoryRef} maxLength={20} onChange={updateFieldsOnChange}></Input>
                //           </div>
                //           <div className={`${style.lineAttributeDescriptionItem} ${style.lineAttributeDescriptionItemDescription}`}>
                //             <span className={`${style.lineAttributeDescriptionLabel} ${style.lineAttributeDescriptionLabelDescription}`}>{"屬性描述："}</span>
                //             <Input className={`${style.lineAttributeDescriptionInput} ${style.lineAttributeDescriptionInputDescription}`} type="text" defaultValue={item.description} ref={lineAttributeDescriptionRef} maxLength={40} onChange={updateFieldsOnChange}></Input>
                //           </div>
                //           <div className={`${style.lineAttributeDescriptionItem} ${style.lineAttributeDescriptionItemPriority}`}>
                //             <span className={`${style.lineAttributeDescriptionLabel} ${style.lineAttributeDescriptionLabelPriority}`}>{"優先權："}</span>
                //             <Input className={`${style.lineAttributeDescriptionInput} ${style.lineAttributeDescriptionInputPriority}`} type="number" defaultValue={item.priority} ref={lineAttributePriorityRef} maxLength={2} onChange={updateFieldsOnChange}></Input>
                //           </div>
                //           <div className={`${style.lineAttributeDescriptionItem} ${style.lineAttributeDescriptionItemUpdateBtn}`}>
                //             <Button className={`${style.lineAttributeDescriptionInputUpdateBtn}`} type={"primary"} size={"small"} onClick={(e) => { updateAttribute( e, item._id ); }}>更新</Button>
                //           </div>
                //         </div>
                //       }
                //     />      
                //   </List.Item>                  
                // ) : 
                Object.keys(lineAttributeDataArray[0]).includes('_id') ? (
                  <List.Item>
                    <List.Item.Meta
                      className={style.lineAttributeListItemMeta}
                      avatar={
                        <Tag className={style.lineAttributeTagIcon} color="geekblue">{item.name}</Tag>
                      }
                      title={
                        <div className={`${style.lineAttributeDescriptionItem} ${style.lineAttributeDescriptionItemTitle}`}>
                          <span className={style.lineAttributeDescriptionID}>{"屬性ID："}</span><span className={style.lineAttributeDescriptionText}>{item._id}</span>
                          <div className={style.lineAttributeEditAndDelIconDiv}>
                            <PlusOutlined className={style.lineAttributeAddIcon} onClick={(e) => { addAttribute(e, item._id, item.name) }} />
                            {/* <EditOutlined className={style.lineAttributeEditAndDelIcon} onClick={(e) => { setIsEditMode([index, true]) }} /> */}
                            {/* <EditFilled className={style.lineAttributeAddIcon} /> */}
                            {/* <DeleteOutlined className={style.lineAttributeEditAndDelIcon} onClick={(e) => { deleteAttribute(e, item._id, item.name) }} /> */}
                            {/* <DeleteFilled className={style.lineAttributeAddIcon} /> */}
                          </div>
                        </div>
                      }
                      description={
                        <div className={style.lineAttributeDescriptionDiv}>
                          <div className={style.lineAttributeDescriptionItem}>
                            <span className={style.lineAttributeDescriptionLabel}>{"屬性名稱："}</span>
                            <span className={style.lineAttributeDescriptionText}>{item.name}</span>
                          </div>
                          <div className={style.lineAttributeDescriptionItem}>
                            <span className={style.lineAttributeDescriptionLabel}>{"屬性類別："}</span>
                            <span className={style.lineAttributeDescriptionText}>{item.category}</span>
                          </div>
                          <div className={style.lineAttributeDescriptionItem}>
                            <span className={style.lineAttributeDescriptionLabel}>{"屬性描述："}</span>
                            <span className={style.lineAttributeDescriptionText}>{item.description}</span>
                          </div>
                          <div className={style.lineAttributeDescriptionItem}>
                            <span className={style.lineAttributeDescriptionLabel}>{"優先權："}</span>
                            <span className={style.lineAttributeDescriptionText}>{item.priority}</span>
                          </div>
                          {/* <div className={style.lineAttributeDescriptionItem}>
                            <span className={style.lineAttributeDescriptionLabel}>{"其他資訊："}</span><span className={style.lineAttributeDescriptionText}>{item.__v}</span>
                          </div> */}
                        </div>
                      }
                    />
                  </List.Item>
                ) : (
                  <></>
                )
              )}
            />
          </section>
        </Content>
      </Content>
    </Layout>
  )
}

export default LineAttribute