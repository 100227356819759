import React from "react";
import style from "./styles/spinnerMedium.module.css";

export default function LoadingSpinnerMedium() {
  return (
    <div className={style.spinnerContainer}>
        <div className={style.loadingSpinner}/>
    </div>
      
  );
}