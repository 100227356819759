import React, { useState, useEffect } from 'react'
import style from './Emoji.module.css'
import { Button as AntButton, Modal as AntModal } from 'antd';
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'

function SocketCustomerSendMessageEmoji(props) {
  const {mountId, emojiClassName, emojiStyle, showEmojiModal, setShowEmojiModal, textMsg, setTextMsg, changeFields} = props;
  
  // let emojiInput = document;
  // const emojiMount = document?.getElementsByClassName("emoji")[0] || {};
  // const emojiInput = emojiMount?.getElementsByTagName("input")[0] || {};

  /*** emoji 選擇後 ***/
  const emojiOnChange = (emoji) => {
    const emojiText = emoji?.native || "";
    // setTextMsg({
    //   ...textMsg, 
    //   type: "text",
    //   text: emojiText,  
    // });
    // const mountId = "customerChatRoomSendSec"; // emoji要掛載的位置ID
    // const formDom = document.getElementById(mountId);
    // const inputDom = formDom?.getElementsByTagName("input[type=text]")[0];
    const inputDom = document.querySelector(`#${mountId} input[type=text]`);
    inputDom.value += emojiText;
    // console.log(formDom);
    console.log(inputDom);
    console.log(inputDom.value);
    changeFields(undefined, inputDom.value);
    setShowEmojiModal(false);
  }/*** END emoji 選擇後 ***/
  
  // useEffect(() => {
  //   const emojiMount = document?.getElementsByClassName("emoji")[0] || document;
  //   emojiInput = emojiMount?.getElementsByTagName("input")[0] || document;
  // }, []);

  return (
    <div className={`${style.emojiModalDiv}`}>
        <div className={style.showEmojiModalBtn} onClick={() => setShowEmojiModal(true)}></div>
        <AntModal
          getContainer={document.getElementById(mountId)}
          width="fit-content"
          centered={true}
          className={`${style.emojiModal} ${emojiClassName}`}
          style={emojiStyle}
          closable={false}
          onOk={() => setShowEmojiModal(false)}
          onCancel={() => setShowEmojiModal(false)}
          footer={null} 
          open={showEmojiModal}
        >
          <Picker data={data} onEmojiSelect={(emoji)=>{emojiOnChange(emoji)}} />
        </AntModal>
    </div>
  )
}

export default SocketCustomerSendMessageEmoji