import React, { useState, useEffect, useContext } from 'react';
import CryptoJS  from 'crypto-js';

/*** Import Socket Component ***/
import { GlobalSocket } from './SocketCustomerConnect';
// import SocketCustomerJoinRoom from './SocketCustomerJoinRoom'

function SocketCustomerListening(props) {
    const {globalSocket, textMsgArray, setTextMsgArray, msgExist, setMsgExist} = props;
    // const globalSocket = useContext(GlobalSocket);
    const isGlobalSocket = globalSocket?.connected || false;
    useEffect(()=>{ 
        console.log("isGlobalSocket", isGlobalSocket); 
        // console.log({globalAdminUserSocket,globalAdminPanelSocket,globalSocket});    
    },[globalSocket])

    /*** 取得加密的使用者資料 ***/
    let defaultUserData = null;
    // let rederCount = 0;
    const[userData, setUserData] = useState({});
    const lineAppId = process.env.REACT_APP_LINE_APP_ID;
    const cpo = window.localStorage.getItem("cpo") ? window.localStorage.getItem("cpo") : "";
    const getCipherUserData = () => {
        const accessToken = window.localStorage.getItem( "LIFF_STORE:" + lineAppId + ":accessToken" ) ? window.localStorage.getItem( "LIFF_STORE:" + lineAppId + ":accessToken" ) : "";
        if( !! accessToken && !! cpo ) {
            try {
                const userDataJson  = CryptoJS.AES.decrypt( cpo, `${accessToken}` );
                // console.log(userDataJson);
                const userDataUTF8 = userDataJson?.toString(CryptoJS.enc.Utf8);
                // const userDataUTF8 = CryptoJS.enc.Utf8.parse(userDataJson);
                const userDataDecode = JSON.parse( userDataUTF8 );
                // setUserData(userDataDecode);

                // const userDataJson  = CryptoJS.AES.decrypt(cpo, accessToken);
                // const userDataDecode = JSON.parse(userDataJson?.toString(CryptoJS.enc.Utf8));

                setUserData( userData => ({...userData, userDataDecode}));
                // console.log({userData});
                defaultUserData = userDataDecode;
                // console.log({defaultUserData});
            } catch (e) {
                console.log(e);
                return false;
            }   
        }   
    }
    useEffect(() => {
        getCipherUserData();
    }, [cpo])
    /*** END 取得加密的使用者資料 ***/        

    /*** 清除State中重複訊息 ***/
    const clearRedupulicMsg = () => {
        // console.log( 'textMsgArray', textMsgArray );
        // console.log("typeof textMsgArray", typeof textMsgArray);
        for( let [i, v] of Object.entries(textMsgArray) ) {
        // console.log({i, v});
        if( i > 0 ) {
            const curId = v?.getId || "";
            const preId = textMsgArray[i-1]?.getId || "";
            const lineId = v?.lineId || "";
            // console.log({curValue,preValue})
            if( curId === preId ) {
            textMsgArray.splice(i, 1);
            }
        }
        }
        // console.log('textMsgArray',textMsgArray);
    }
    useEffect(() => {
        clearRedupulicMsg();
    }, [textMsgArray]);
    /*** END 清除State中重複訊息 ***/

    /*** Socket監聽事件 ***/
    /* 使用者傳送訊息，將訊息存入State */
    const handleNewTextMsg = async(data) => {
        // window.localStorage.setItem('handleNewTextMsg', true);
        console.log({data});
        // const status = data?.status || "";
        // if( !! status ) {
        //   return;
        // }
        // let textMsgArrayLength = textMsgArray?.length || 0;
        // // console.log({rederCount,textMsgArrayLength});
        // if( true ) { // rederCount < textMsgArrayLength + 1
        clearRedupulicMsg();
        /*  預設資料
            const userDataDefault = {
                userId: profile.userId,
                userStatus: "【一般使用者】",
                firstName: "",
                lastName: profile.displayName,
                email: "",
                phone: "",
                lineId: profile.userId,
                isLineBind: false,
                avatar: profile.pictureUrl,
                statusMessage: profile.statusMessage
            };
            const data = {
                "id": "string",
                "userId": "string",
                "type": "text",
                "status": "waiting",
                "text": "string",
                "data": {
                    "type": "string",
                    "name": "string",
                    "uri": "string",
                    "buffer": {}
                }
            }
        */
        let userStatus, firstName, lastName, email, phone, lineId, isLineBind, avatar, statusMessage;
        userStatus = "【一般使用者】";
        avatar = "https://www.gravatar.com/avatar/?d=mp";
        firstName = lastName = email = phone = lineId = isLineBind = avatar = statusMessage = "";
        const getId = data?.id || "";
        const getUserType = data?.userType || "CUSTOMER";
        const getChannelId = data?.channelId || "";
        const getChannelType = data?.channelType || "CHAT";
        const position = getUserType ? ( getUserType === "CS" ? "left" : "right" ) : "right";
        const getType = data?.type || "";
        const getText = data?.text || "";
        const getDataType = data?.data?.type || "";
        const getDataName = data?.data?.name || "";
        const getDataUri = data?.data?.uri || "";
        const getDataBuffer = data?.data?.buffer || "";
        const getDate = data?.data?.date || new Date();

        if( !! getUserType && getUserType !== "CS" ) {
            userStatus = defaultUserData?.userStatus || "【一般使用者】";
            firstName = defaultUserData?.firstName || "";
            lastName = defaultUserData?.lastName || "";
            email = defaultUserData?.email || "";
            phone = defaultUserData?.phone || "";
            lineId = defaultUserData?.lineId || "";
            isLineBind = defaultUserData?.isLineBind || false;
            avatar = defaultUserData?.avatar || "https://www.gravatar.com/avatar/?d=mp";
            statusMessage = defaultUserData?.statusMessage || "";
        } else {
            userStatus = "【客服人員】";
            avatar ='/cs.png';
        }
        // console.log({lastName,getType,getText});
        // console.log('textMsgArray1', textMsgArray);
        // console.log('msgExist1', msgExist);
        if( !! getId ) {  
            setMsgExist(true);
            let newTextMsgArray = textMsgArray;
            const newTextMsg = {
                position,
                userStatus,
                firstName,
                lastName,
                email,
                phone,
                lineId,
                isLineBind,
                avatar,
                statusMessage,
                getId,
                getUserType,
                getChannelId,
                getChannelType,
                getType,
                getText,
                getDataType,
                getDataName,
                getDataUri,
                getDataBuffer,
                getDate
            }
            let lastEl = textMsgArray?.slice(-1)[0] || "";
            // console.log({lastEl});
            // console.log({newTextMsg});
            let lastElId = lastEl?.getId || "";
            // console.log({lastElText});
            // console.log({getText});
            // console.log("lastEl != newTextMsg",lastEl != newTextMsg);
            // console.log("lastElText != getText",lastEl != getText);
            // console.log({textMsgArray});
            // console.log({lastEl});
            // console.log({lastElText});
            // console.log("lastElText !== getText",lastElText !== getText);          
            if( lastElId != getId ) {
                console.log({textMsgArray, newTextMsgArray, newTextMsg});
                await setTextMsgArray(current => [...current, newTextMsg]); // 更新狀態
                // newTextMsgArray = newTextMsgArray?.push(newTextMsg) || textMsgArray;
                // newTextMsgArray = [...newTextMsgArray, newTextMsg];
                // await setTextMsgArray(newTextMsgArray); // 更新狀態
                // console.log({textMsgArray, newTextMsgArray, newTextMsg});
                window.localStorage.setItem('textMsgArray', JSON.stringify(textMsgArray));
            }
            // console.log({lastName, getType, getText});
            // console.log('textMsgArray2',textMsgArray);
            // console.log('msgExist2',msgExist);
        }
        // textArray.map((v,i) => {
        //   // console.log('v',v);
        //   return(
        //     <MessageList
        //       className='message-list'
        //       lockable={true}
        //       toBottomHeight={'100%'}
        //       dataSource={textArray}
        //     />
        //   )
        // });              
        if (data.type === 'photo') {
            const image = document.getElementById('messagePhoto');
            image.setAttribute('src', data.data.url);
            const image2 = document.getElementById('thumbnailPhoto');
            image2.setAttribute('src', data.data.thumbnailUrl);
        }
        // }
        // rederCount++;
    }
    /* 監聽Socket事件 */
    const listenSocketEvent = () => {
        if (isGlobalSocket) {
            globalSocket.on("joinRoom", (data) => {
                console.log(data);
                // const status = data?.status || "";
                // if( status === "normal" ) {
                //   return
                // }
            });
            globalSocket.on("message", (data) => {
                // window.localStorage.setItem('lsnMsg', true); 
                console.log({data});
                handleNewTextMsg(data);
            });
            globalSocket.on('unReadMessage', (data) => {
                console.log('unReadMessage', data);
                // const status = data?.status || "";
                // if( !! status ) {
                //   return;
                // }
            })
            globalSocket.on("error", (data) => {
                console.error(data);
                // return;
                // if(data.code === 'logout'){
                //     liff.logout();
                // }
            });

            // socketCSPannel.on('userInfo', (data) => {
            //     console.log( data, 'listening userInfo' );
            //     /*
            //         data = 
            //         {
            //             "status": "success",
            //             "code": "get_user_info_success",
            //             "userInfo": {
            //                 "id": "string",
            //                 "name": "string",
            //                 "avatar": "string",
            //                 "lineId": "string",
            //                 "statusMessage": "string"
            //             }
            //         }
            //     */
            // });

        }
    }     
    useEffect(() => {
        listenSocketEvent();
    }, []);
    // useEffect(() => {
    //     listenSocketEvent();
    //     // return () => {
    //     //   // 移除事件監聽器
    //     //   globalSocket.off("message", handleNewTextMsg);
    //     // };
    // }, [globalSocket]);
    // useEffect(() => {
    //     setTimeout(()=>{
    //         listenSocketEvent();
    //     }, "10000")
    // });
    /*** END Socket監聽事件 ***/ 
    // return (
    //     <SocketCustomerJoinRoom globalSocket={globalSocket}/>
    // );
}

export default SocketCustomerListening