import React from 'react'
import style from './styles/CustomerChatRoom.module.css'
// import Emoji from '../../../Component/Emoji/Emoji'

/*** Customer Chat Room Components ***/
import CustomerChatRoomContent from './CustomerChatRoomContent'
import CustomerChatRoomSend from './CustomerChatRoomSend'
import SocketCustomerJoinRoom from '../../../SocketIO/Customer/SocketCustomerJoinRoom'

function CustomerChatRoom(props) {
  const {globalSocket} = props;
  return (
    <>
      <SocketCustomerJoinRoom/>
      <section id={"customerChatRoomContentSec"} className={`${style.customerChatRoomContentSec}`}>
        <CustomerChatRoomContent globalSocket={globalSocket}></CustomerChatRoomContent>
      </section>
      <section id={"customerChatRoomSendSec"} className={`${style.customerChatRoomSendSec}`}>
        {/* <Emoji></Emoji> */}
        <CustomerChatRoomSend globalSocket={globalSocket}></CustomerChatRoomSend>
      </section>
      {/* <div id="customerLiffPopupModalBodyBottom" style={{"padding":"1px","backgroundColor": "#1C3852"}}></div> */}
    </>
  )
}

export default CustomerChatRoom