import React, { useState, useContext, useEffect, useRef } from 'react'
import { Input, Button, Dropdown } from 'react-chat-elements'
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';
import { Button as AntButton, Upload, Form, Modal } from 'antd';
import CryptoJS  from 'crypto-js';
import Compressor from "compressorjs";
import jwt_decode from "jwt-decode";
import Emoji from "../../../../../../Component/Emoji/Emoji"

/*** CSS ***/
import style from './styles/LineCastInterface.module.css'
import './styles/LineComponentsAnt.css'

/*** Import Context Parameters ***/
import { GlobalAdminSocket } from '../../../../../../SocketIO/Admin/SocketAdminConnect';

function LineCastInterface(props) {

  const [form] = Form.useForm();
  const [typingText, settypingText] =useState([]);

  const [globalAdminSocket , setGlobalAdminSocket ] = useContext(GlobalAdminSocket);

  const [globalAdminUserSocket, setGlobalAdminUserSocket] = useState(globalAdminSocket?.globalAdminUserSocket); // 初始值設定正確才能及時觸發主動連線
  const [globalAdminPanelSocket, setGlobalAdminPanelSocket] = useState(globalAdminSocket?.globalAdminPanelSocket);
  const [userData, setUserData] = useState({});
  const [textMsg, setTextMsg] =useState({});
  const [formStyle, setFormStyle] = useState({"marginTop": "-30px"});
  const [uploadBtnStyle, setUploadBtnStyle] = useState({"fontSize": "20px"});
  const [channelTypeState, setChannelTypeState] = useState("聊天室");
  const [adminData, setAdminData] = useState({});
  const [showEmojiModal, setShowEmojiModal] = useState(false);
  const mountId = "lineCastInterfaceSec"; // emoji要掛載的位置ID

  const uploadComponentRef = useRef(null);

  let defaultUserData = null;
  const loginToken = window.localStorage.getItem("loginToken");
  const lineAppId = process.env.REACT_APP_LINE_APP_ID;
  const cpo = window.localStorage.getItem("cpo") ? window.localStorage.getItem("cpo") : "";
  const accessToken = window.localStorage.getItem( "LIFF_STORE:" + lineAppId + ":accessToken" ) ? window.localStorage.getItem( "LIFF_STORE:" + lineAppId + ":accessToken" ) : "";
  const allowedPhotoTypes = ['image/png', 'image/jpeg', 'image/gif']; // 允許上傳的圖片格式


  /*** 初始化/重置 globalAdminUserSocket 及 globalAdminPanelSocket ***/
  const initializeAdminUserAndPanelSocket = async() => {
    let adminUserSocket = null;
    if ( typeof globalAdminSocket === 'object' && globalAdminSocket !== null && Object.keys(globalAdminSocket).length > 0 ) {
        adminUserSocket = globalAdminSocket?.globalAdminUserSocket || null;
        setGlobalAdminUserSocket(adminUserSocket); // 最後改動此處，讓GlobalAdminSocket連線後設定GlobalAdminUserSocket
    }
    let adminPanelSocket = null;
    if ( typeof globalAdminSocket === 'object' && globalAdminSocket !== null && Object.keys(globalAdminSocket).length > 0 ) {
        adminPanelSocket = globalAdminSocket?.globalAdminPanelSocket || null;
        setGlobalAdminPanelSocket(adminPanelSocket);
    }
  }
  useEffect(() => {
      initializeAdminUserAndPanelSocket();
  }, []);
  useEffect(() => {
      initializeAdminUserAndPanelSocket();
  }, [globalAdminSocket]);  
  /*** END 初始化/重置 globalAdminUserSocket 及 globalAdminPanelSocket ***/ 

  /*** 取得加密的使用者資料 ***/    
  const getCipherUserData = () => {
    if( !! accessToken && !! cpo ) {
      try {
        const userDataJson  = CryptoJS.AES.decrypt( cpo, `${accessToken}` );
        const userDataUTF8 = userDataJson?.toString(CryptoJS.enc.Utf8);
        const userDataDecode = JSON.parse( userDataUTF8 );
        setUserData( userData => ({...userData, userDataDecode}));
        defaultUserData = userDataDecode;
        return userDataDecode;
      } catch (e) {
        console.log(e);
        return false;
      }  
    }   
  }
  useEffect(() => {
      getCipherUserData();
  }, [cpo])
  /*** END 取得加密的使用者資料 ***/

  /*** 取得管理者資料 ***/
  const getAdminData = async() => {
    const decoded = jwt_decode(loginToken);
    console.log(decoded);
    const id = decoded?.id || "";
    const email = decoded?.email || "";
    const role = decoded?.role || "";
    console.log(role);
    const atIndex = email.indexOf('@');
    const name = atIndex !== -1 ? email.substring(0, atIndex) : "";
    const data = {id, name, email};
    setAdminData(data);
  }
  useEffect(() => {
    getAdminData();
  }, []);
  useEffect(() => {
    getAdminData();
  }, [loginToken]);
  /*** END 取得管理者資料 ***/

  /*** 使用者在訊息欄輸入訊息，將訊息存入State ***/
  const changeFields = (event, emojiText) => {
    event?.preventDefault();
    const val = event?.target?.value || "";
    console.log({ val });
    /* 取得的使用者資料預設格式
        {
            userId: profile.userId,
            userStatus: "【一般使用者】",
            firstName: "",
            lastName: profile.displayName,
            email: "",
            phone: "",
            lineId: profile.userId,
            isLineBind: false,
            avatar: profile.pictureUrl,
            statusMessage: profile.statusMessage
        }
    */
    if( typeof val === "string" ) {
      if( ! emojiText ) {
        setTextMsg({
          ...textMsg, 
          type: "text",
          text: val,  
        });
        console.log(textMsg);
      } else {
        setTextMsg({
          ...textMsg, 
          type: "text",
          text: emojiText,  
        });
        // console.log(textMsg);
      }

    }
  } /*** END 管理者在訊息欄輸入訊息，將訊息存入State ***/ 

  /*** emoji 選擇後 ***/
  const emojiOnChange = (emoji) => {
    const emojiText = emoji?.native || "";
    const formDom = document.getElementById("lineCastInterfaceForm");
    const inputDom = formDom?.getElementsByClassName("rce-input")[0];
    inputDom.value += emojiText;
    console.log(formDom);
    console.log(inputDom);
    console.log(inputDom.value);
    changeFields(undefined, inputDom.value);
    setShowEmojiModal(false);
  }/*** END emoji 選擇後 ***/

  useEffect(()=>{console.log({textMsg})},[textMsg]);

  /*** 管理者點選送出訊息，將訊息以Socket Emit傳出 ***/
  const adminSendMessage = async(e) => {
    e.preventDefault();
    const channelType = "LINE"; // 在ChatRoomAssign做設定
    const sendText = textMsg?.text || "";
    const type = textMsg?.type || "text";
    const dataType = textMsg?.dataType || "";
    const dataName = textMsg?.dataName || ""; 
    const dataUri = textMsg?.dataUri || "";
    const dataBuffer = textMsg?.dataBuffer || {};
    const data = {
        channelType: channelType,
        type: type,
        text: sendText,
        data: {
            type: dataType,
            name: dataName,
            uri: dataUri,
        }
    }  
    console.log(data); 
    const globalAdminUserSocketConnected = globalAdminUserSocket?.connected || false;
    if( globalAdminUserSocketConnected && type === "text" ) {
      globalAdminUserSocket.emit('message', data );
      globalAdminUserSocket.emit('roomList', {
        "cursorD": new Date(),
        "limit": 50
      });
    } else if( Object.keys(dataBuffer).length > 0 && !! channelType && !! type ) {
      syncCompressAndUpload( dataBuffer, channelType, type );
    }

    clearInput(); // 完成送出訊息後，清空輸入欄位，重設訊息欄樣式
  } 
  const clearInput = () => {
    setTextMsg({}); 
    document.getElementById("lineCastInterfaceForm").reset();
    // document.getElementById("lineCastInterfaceForm").getElementsByTagName("input")[0].value = "";
    // document.getElementById("lineCastInterfaceForm").getElementsByTagName("input")[1].value = "";
    // 完成送出訊息後，重設訊息欄樣式
    setFormStyle({"marginTop": "-30px"});
    setUploadBtnStyle({"fontSize": "20px"});
  } /*** END 管理者點選送出訊息 ***/


  /*** Normalizing File ***/
  const normFile = (e) => {
    // console.log(e);
    if (Array.isArray(e)) {
        return e;
    }
    return e && e.fileList;
  }  /*** END Normalizing File ***/


  /*** onChange時設定上傳檔案Data ***/
  const changeUploadFile = (fileItem) => {
    document.getElementById("lineCastInterfaceForm").getElementsByTagName("input")[1].value = "";
    console.log(fileItem);
    const fileList = fileItem?.fileList || [];
    const file = fileList[0]?.originFileObj || {};
    if( Object.keys(file).length > 0 ) {  
      const fileName = file?.name || "未設定檔名";
      const fileType = file?.type || "未知的檔案類型";
      let type = "";
      if( fileType.indexOf("image/") > -1 && allowedPhotoTypes.includes(fileType) ) {
        type = "photo";
        setTextMsg({
          ...textMsg, 
          type: type,
          text: fileName,
          dataType: fileType,
          dataName: fileName,
          dataBuffer: file  
        });
        setFormStyle({"marginTop": "-65px"});
        setUploadBtnStyle({"display": "none"});
      } else if( fileType.indexOf("image/") > -1 ) {
        clearInput();
        alert("不支援的圖片格式（僅支援jpg、png、gif格式）!");
        setTextMsg({});
        fileList.splice(fileList.indexOf(file), 1);
      } else if( fileType.indexOf("video/") > -1 ) {
        type = "video";
        setTextMsg({
          ...textMsg, 
          type: type,
          text: fileName,
          dataType: fileType,
          dataName: fileName,
          dataBuffer: file  
        });
        setFormStyle({"marginTop": "-65px"});
        setUploadBtnStyle({"display": "none"});
      } else if( fileType.indexOf("audio/") > -1 ) {
        type = "audio";
        setTextMsg({
          ...textMsg, 
          type: type,
          text: fileName,
          dataType: fileType,
          dataName: fileName,
          dataBuffer: file  
        });
        setFormStyle({"marginTop": "-65px"});
        setUploadBtnStyle({"display": "none"});
      } else {
        type = "file";
        setTextMsg({
          ...textMsg, 
          type: type,
          text: fileName,
          dataType: fileType,
          dataName: fileName,
          dataBuffer: file  
        });
        setFormStyle({"marginTop": "-65px"});
        setUploadBtnStyle({"display": "none"});
      }
    } 
  }/*** END onChange時設定上傳檔案Data ***/
  
  /*** 壓縮上傳檔案 ***/
  const compressImg = async (file) => {
    const fileType = file?.type || "未知的檔案類型";
    if( fileType.indexOf("image/") > -1 && allowedPhotoTypes.includes(fileType) ) {
      return new Promise((resolve, reject) => {
        new Compressor(file, {
          quality: 0.6,
          success: (compressedResult) => {
            resolve(compressedResult);
          },
          error: (err) => {
            alert("錯誤，檔案壓縮失敗！");
            setTextMsg({});
            console.log(err.message);
            reject(false);
          },
        });
      });
    }
    return false;
  };/*** END 壓縮上傳檔案 ***/

  /*** 檔案上傳 ***/
  const uploadFile = async (file, channelType, messageType) => {
    console.log(file);
    console.log(channelType);
    console.log(messageType);
    const fileName = file?.name || "未設定檔名";
    const fileType = file?.type || "";
    const fileSize = file?.size || 0;
    console.log(fileName);
    const presignedPolicy = await fetch(
      `https://messanger.kid-pro.com/api/message/v1/upload-chat/presigned-policy?` +
        new URLSearchParams({
          name: fileName,
          fileSize,
          fileType,
          channelType,
          messageType
        }),
      {
        headers: {
          Authorization: `Bearer ${loginToken}`,
        },
      }
    )
    .then((res) => res.json())
    .catch((err) => {
      clearInput();
      alert("不支援的檔案名稱或檔案無法於此頻道上傳！");
      setTextMsg({});
      console.log(err);
      throw new Error(err);
    });
    const formData = new FormData();
    const policyFormData = presignedPolicy?.formData || {};
    Object.entries(policyFormData).forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.append("file", file);
    console.log(formData);
    console.log(presignedPolicy);
    const policyPostURL = presignedPolicy?.postURL || "";
    console.log(policyPostURL);
    fetch(policyPostURL, {
      method: "post",
      body: formData,
    }).then((res) => {
      console.log(res);
      if( ! res.ok ) {
        setTextMsg({});
        clearInput();
        console.log( "不支援的檔案格式或大小超過限制（10MB）!", res );
        throw new Error(res);
      } else {
        clearInput(); // 訊息已送出，重置欄位樣式
      }
    })
    .catch((err) => {
      clearInput();
      alert("不支援的檔案格式或大小超過限制（10MB）!");
      setTextMsg({});
      console.log(err);
      throw new Error(err);
    });
  }; /*** END 檔案上傳 ***/

  /*** 同步壓縮並上傳 ***/
  const syncCompressAndUpload = async( file, channelType, msgType ) => {
    let fileTemp = file;
    const compImg = await compressImg(file);
    fileTemp = !! compImg ? compImg : file;
    uploadFile( fileTemp, channelType, msgType ).catch((err) => {
      clearInput();
      alert("不支援的檔案格式或大小超過限制（10MB）!");
      setTextMsg({});
      console.log(err);
      throw new Error(err);
    });
  } /*** END 同步壓縮並上傳 ***/  

  return (
    <section id="lineCastInterfaceSec" class={style.lineCastInterfaceSec}>
      <div className={style.lineCastInterfaceDiv}>
        <Form id="lineCastInterfaceForm" className={style.lineCastInterfaceForm} form={form} style={formStyle} onFinish={() => {}}>
          <Form.Item
            name="lineCastInterfaceFormUpload"
            valuePropName="list"
            getValueFromEvent={normFile}
          >
            <Upload
                beforeUpload={() => false}
                action={window.location.protocol + "//" + window.location.hostname + ":" + window.location.port + "/upload"}
                listType="picture"
                maxCount={1}
                onChange={(fileItem) => { changeUploadFile(fileItem); }}
                onRemove={()=>{
                  // 完成送出訊息後，清空輸入欄位
                  setTextMsg({}); 
                  document.getElementById("lineCastInterfaceForm").reset();
                  setFormStyle({"marginTop": "-30px"});
                  setUploadBtnStyle({"fontSize": "20px"});
                }}
              >
              <UploadOutlined style={uploadBtnStyle}/>
            </Upload> 
          </Form.Item>
          <Form.Item name="lineCastInterfaceFormInput">   
            <Input
              id="sendMsg"
              name="sendMsg"
              className={style.lineCastInterfaceInput}
              placeholder="請在此輸入訊息..."
              onChange={changeFields}
              value={textMsg.text}
              onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    console.log(textMsg?.dataBuffer|| "");
                    adminSendMessage(e);
                  }
              }}
              rightButtons={
                <div className={style.lineCastInterfaceButtonDiv}>
                  <Emoji emojiClassName={style.chatRoomEmojiModal} mountId={mountId} showEmojiModal={showEmojiModal} setShowEmojiModal={setShowEmojiModal} textMsg={textMsg} setTextMsg={setTextMsg} changeFields={changeFields}></Emoji>
                  <Button className={style.lineCastInterfaceButton} backgroundColor={"#1677FE"} text="送出訊息" title="送出訊息" htmlType="submit" onClick={(e) => {
                    adminSendMessage(e);
                  }} />
                </div>
              } 
            />
          </Form.Item>
        </Form>
      </div>
    </section>
  )
}

export default LineCastInterface