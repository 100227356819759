import React, {useState} from 'react'
import axios from 'axios';
import style from './styles/Tags.module.css'
import './styles/TagsAnt.css'
import { Layout, Menu, Input, Space, Button, InputNumber, List, Radio, Tag, Pagination } from 'antd';
import {
  EyeOutlined,
  PlusCircleOutlined,
  UpCircleOutlined,
  MinusCircleOutlined,
} from '@ant-design/icons';

/*** Import Tags Component ***/
import TagsRead from './components/TagsRead';
import TagsAdd from './components/TagsAdd';
// import TagsUpdate from './components/TagsUpdate';
// import TagsDelete from './components/TagsDelete';

function Tags() {
  const [tagsCRUD, setTagsCRUD] = useState('TagsRead');
  const [searchString, setSearchString] = useState(""); // 搜尋欄輸入的字串
  const [currentPage, setCurrentPage] = useState(1); // 當前頁面
  const [numberPerPage, setNumberPerPage] = useState(8); // 一頁有幾個Tag數
  const [tagsDataArray, setTagsDataArray] = useState([{}]); // 各標籤資料
  const apiURL = process.env.REACT_APP_APIURL; // API URL
  /*** 標籤頂部導覽項目 ***/
  // const menuItems = [
  //   getMenuItem('Option 1', '1', <PieChartOutlined />),
  //   getMenuItem('Option 2', '2', <DesktopOutlined />),
  //   getMenuItem('Option 3', '3', <ContainerOutlined />),
  
  //   getMenuItem('Navigation One', 'sub1', <MailOutlined />, [
  //     getMenuItem('Option 5', '5'),
  //     getMenuItem('Option 6', '6'),
  //     getMenuItem('Option 7', '7'),
  //     getMenuItem('Option 8', '8'),
  //   ]),
  
  //   getMenuItem('Navigation Two', 'sub2', <AppstoreOutlined />, [
  //     getMenuItem('Option 9', '9'),
  //     getMenuItem('Option 10', '10'),
  
  //     getMenuItem('Submenu', 'sub3', null, [getMenuItem('Option 11', '11'), getMenuItem('Option 12', '12')]),
  //   ]),
  // ];  
  const menuItems = [
    {
      label: '查詢／編輯標籤',
      key: 'TagsRead',
      icon: <EyeOutlined />,
    },
    {
      label: '新增標籤',
      key: 'TagsAdd',
      icon: <PlusCircleOutlined />,
      // disabled: true,
    },
    // {
    //   label: '更新標籤',
    //   key: 'TagsUpdate',
    //   icon: <UpCircleOutlined />,
    //   children: [
    //     {
    //       type: 'group',
    //       label: 'Item 1',
    //       children: [
    //         {
    //           label: 'Option 1',
    //           key: 'setting:1',
    //         },
    //         {
    //           label: 'Option 2',
    //           key: 'setting:2',
    //         },
    //       ],
    //     },
    //     {
    //       type: 'divider',
    //       label: 'Item 2',
    //       children: [
    //         {
    //           label: 'Option 3',
    //           key: 'setting:3',
    //         },
    //         {
    //           label: 'Option 4',
    //           key: 'setting:4',
    //         },
    //       ],
    //     },
    //   ],
    // },
    // {
    //   label: '刪除標籤',
    //   key: 'TagsDelete',
    //   icon: <MinusCircleOutlined />,
    //   // disabled: true,
    // }
  ];
  // const getMenuItem = ( label, key, icon, children, type ) => {
  //   return {
  //     key,
  //     icon,
  //     children,
  //     label,
  //     type,
  //   }
  // }

  /*** ANT排版 ***/
  const { Header, Content, Sider } = Layout;

  /*** 搜尋 ***/
  const { Search } = Input;
  const searchOnChange = (event) => {
    const val = event?.target?.value || "";
    setSearchString(val);
  }
  const onSearch = () => {
    console.log(currentPage);
    requestTags();
  }

  /*** 查詢Tags ***/
  const requestTags = async() => {
    const url = apiURL + "/api/message/v1/chat-tags?" + new URLSearchParams({
      filter: searchString,
      limit: numberPerPage,
      offset: currentPage-1
    });
    console.log(url);
    console.log(searchString);
    console.log(numberPerPage);
    console.log(currentPage);
    const response = await fetch(
      url,
      {
        headers: {
          Accept: "application/json",
        },
      }
    )
    .then((res) => res.json())
    .catch((err) => {
      throw new Error("RequestTags failed");
    });
    console.log(response);
    setTagsDataArray(response);
    // try {
    //   const response = await axios.get({
    //     method: 'get',
    //     url: url,
    //     responseType: 'json',
    //   });
    //   console.log(response);
    // } catch (error) {
    //   console.error(error);
    // }
  }

  /*** 分頁切換 ***/
  const changePage = (pageNumber) => {
    console.log('Page: ', pageNumber);
    setCurrentPage(pageNumber);
  };

  /*** 點選Menu Item ***/
  const clickTagsMenuItem = (e) => {
    console.log('click ', e);
    const key = e?.key || "";
    if (key.includes("TagsRead")) {
      setTagsCRUD("TagsRead");
    } else if (key.includes("TagsAdd")) {
      setTagsCRUD("TagsAdd");
    } else if (key.includes("TagsUpdate")) {
      setTagsCRUD("TagsUpdate");
    } else if (key.includes("TagsDelete")) {
      setTagsCRUD("TagsDelete");
    } else {
      setTagsCRUD("");
    }
  };    

  /*** 限制搜尋數量 ***/
  const [value, setValue] = useState(50);

  /*** 分頁顯示設定 ***/
  const data = [ // 樣板資料
    {
      _id: "001",
      name: "測試標籤1",
      category: "標籤類別",
      description: "標籤描述",
      priority: 0,
      __v: 0
    },
    {
      _id: "002",
      name: "測試標籤2",
      category: "標籤類別",
      description: "標籤描述",
      priority: 0,
      __v: 0
    },
    {
      _id: "003",
      name: "測試標籤3",
      category: "標籤類別",
      description: "標籤描述",
      priority: 0,
      __v: 0
    },
    {
      _id: "004",
      name: "測試標籤4",
      category: "標籤類別",
      description: "標籤描述",
      priority: 0,
      __v: 0
    },
    {
      _id: "004",
      name: "測試標籤4",
      category: "標籤類別",
      description: "標籤描述",
      priority: 0,
      __v: 0
    },
    {
      _id: "004",
      name: "測試標籤4",
      category: "標籤類別",
      description: "標籤描述",
      priority: 0,
      __v: 0
    },
    {
      _id: "004",
      name: "測試標籤4",
      category: "標籤類別",
      description: "標籤描述",
      priority: 0,
      __v: 0
    },
    {
      _id: "004",
      name: "測試標籤4",
      category: "標籤類別",
      description: "標籤描述",
      priority: 0,
      __v: 0
    },
    {
      _id: "004",
      name: "測試標籤4",
      category: "標籤類別",
      description: "標籤描述",
      priority: 0,
      __v: 0
    },
    {
      _id: "004",
      name: "測試標籤4",
      category: "標籤類別",
      description: "標籤描述",
      priority: 0,
      __v: 0
    },
    {
      _id: "004",
      name: "測試標籤4",
      category: "標籤類別",
      description: "標籤描述",
      priority: 0,
      __v: 0
    },
    {
      _id: "004",
      name: "測試標籤4",
      category: "標籤類別",
      description: "標籤描述",
      priority: 0,
      __v: 0
    },
    {
      _id: "004",
      name: "測試標籤4",
      category: "標籤類別",
      description: "標籤描述",
      priority: 0,
      __v: 0
    }
  ];
  // <Tag color="magenta">magenta</Tag>
  // <Tag color="red">red</Tag>
  // <Tag color="volcano">volcano</Tag>
  // <Tag color="orange">orange</Tag>
  // <Tag color="gold">gold</Tag>
  // <Tag color="lime">lime</Tag>
  // <Tag color="green">green</Tag>
  // <Tag color="cyan">cyan</Tag>
  // <Tag color="blue">blue</Tag>
  // <Tag color="geekblue">geekblue</Tag>
  // <Tag color="purple">purple</Tag>


  return (
    <Layout className={style.tagsLayout}>
      <Menu style={{'borderLeft':'1px solid white'}} theme="dark" onClick={clickTagsMenuItem} selectedKeys={[tagsCRUD]} mode="horizontal" items={menuItems} />
      <Content className={style.tagsContent}>
        { tagsCRUD === "TagsRead" ? (
          <Content id="TagsRead">
            <TagsRead></TagsRead>
          </Content>
        ) : tagsCRUD === "TagsAdd" ? (
          <Content id="TagsAdd">
            <TagsAdd id="TagsAdd"></TagsAdd>
          </Content>
        // ) : tagsCRUD === "TagsUpdate" ? (
        //   <TagsUpdate></TagsUpdate>
        // ) : tagsCRUD === "TagsDelete" ? (
        //   <TagsDelete></TagsDelete>
        ) : (
          <></>
        )}
      </Content>
    </Layout>
  )
}

export default Tags