import React, { useContext, useState, useEffect, useCallback, useReducer } from 'react';
import io from 'socket.io-client';
import { Dropdown, Button } from "react-chat-elements";
import './styles/ChatRoomRceComponent.css';
import style from './styles/ChatRoomAssign.module.css';

/*** ANT UI ***/
import { DisconnectOutlined, LinkOutlined } from '@ant-design/icons';

/*** Import Socket Component ***/
import SocketAdminListening from '../../../../../SocketIO/Admin/SocketAdminListening';

// /*** Chat Room Component ***/
// import UserListContent from './UserListContent';

/*** Import Context Parameter ***/
import { GlobalAdminSocket } from '../../../../../SocketIO/Admin/SocketAdminConnect';
import { GlobalRoomId } from '../ChatRoom';
import { GlobalJoinRoom } from '../ChatRoom';
import { GlobalHasJoinedRoom } from '../ChatRoom';
import { GlobalChannelType } from '../ChatRoom';

/*
 * 1. Reset Local Admin User and Panel Socket
 * 2. Reset Global Admin Socket
 * 3. Initialize Global Admin User and Panel Socket 
 * 4. Check Global Admin User and Panel Socket has Connected or not
 */
function ChatRoomAssign(props) {
  /* 取得SocketAdminConnect的連線狀態 */
  // let globalAdminSocket = null;
  // let globalAdminUserSocket = null;
  // let globalAdminPanelSocket = null;

  const [globalAdminSocket , setGlobalAdminSocket ] = useContext(GlobalAdminSocket);
  const [globalRoomId , setGlobalRoomId ] = useContext(GlobalRoomId);
  const [globalJoinRoom, setGlobalJoinRoom] = useContext(GlobalJoinRoom);
  const [globalHasJoinedRoom , setGlobalHasJoinedRoom] = useContext(GlobalHasJoinedRoom);
  const [globalChannelType, setGlobalChannelType] = useContext(GlobalChannelType);
  // const [channelTypeState, setChannelTypeState] = useState("聊天室");
  // useEffect(() => {
  //   switch(globalChannelType) {
  //     case "LINE":
  //       setChannelTypeState("LINE");
  //       break;
  //     case "FACEBOOK":
  //       setChannelTypeState("Facebook");
  //       break;                  
  //     case "EMAIL":
  //       setChannelTypeState("Email");
  //       break;    
  //     default:
  //       setChannelTypeState("聊天室");
  //       break;                
  //   }
  // }, []);  
  // useEffect(() => {
  //   switch(globalChannelType) {
  //     case "LINE":
  //       setChannelTypeState("LINE");
  //       break;
  //     case "FACEBOOK":
  //       setChannelTypeState("Facebook");
  //       break;                  
  //     case "EMAIL":
  //       setChannelTypeState("Email");
  //       break;    
  //     default:
  //       setChannelTypeState("聊天室");
  //       break;                
  //   }
  // }, [globalChannelType]);
  
  const [globalAdminUserSocket, setGlobalAdminUserSocket] = useState( globalAdminSocket?.globalAdminUserSocket || {} ); // 初始值設定正確才能及時觸發主動連線
  const [globalAdminPanelSocket, setGlobalAdminPanelSocket] = useState( globalAdminSocket?.globalAdminPanelSocket || {} );
  const [globalAdminUserSocketConnected, setGlobalAdminUserSocketConnected] = useState( globalAdminSocket?.globalAdminUserSocket?.connected || false );
  const [globalAdminPanelSocketConnected, setGlobalAdminPanelSocketConnected] = useState( globalAdminSocket?.globalAdminPanelSocket?.connected || false );

  // const [hasResetAdminUserAndPanelSocket, setHasResetAdminUserAndPanelSocket] = useState(false);
  // const [runningUseEffect, setRunningUseEffect] = useState(true);
  // const [joinRoom, setJoinRoom] = useState(false);

  const loginToken = window.localStorage.getItem('loginToken') ? window.localStorage.getItem('loginToken') : "";
  const host = process.env.REACT_APP_HOST;
  const isWss = process.env.REACT_APP_ISWSS;

  /* 傳遞 Child Component 預設值 */
  // const [textMsgArray, setTextMsgArray] = useState([]);
  // const [msgExist, setMsgExist] = useState(false);

  // const stopRunningUseEffect = useCallback((e) => {
  //     e.preventDefault();
  //     setRunningUseEffect(false);
  //   }, []);

  // const startRunningUseEffect = useCallback((e) => {
  //     e.preventDefault();
  //     setRunningUseEffect(true);
  //   }, []);
  
  // /*** 若管理者已取得Room ID而未加入房間，則自動加入房間 ***/
  // const adminJoinRoomPassive = async(roomId) => {
  //   if( ! joinRoom && !! roomId ) {
  //       console.log(globalAdminUserSocket);
  //       const globalAdminUserSocketConnected = globalAdminUserSocket?.connected || false;
  //       console.log(globalAdminUserSocketConnected);
  //       console.log(roomId);
  //       if( globalAdminUserSocketConnected && !! roomId ) {
  //           globalAdminUserSocket.emit('joinRoom', {'roomId': roomId});
  //           console.log('Administer join room!');
  //       }
  //   }
  // } 
  // useEffect(() => {
  //     adminJoinRoomPassive(roomId);
  // }, []);
  // /*** END 若管理者已取得Room ID而未加入房間，則自動加入房間 ***/

  /*** 登入後才能主動重新連線 User & Panel Socket ***/
  /* Admin User Socket Connection */
  const adminUserSocketConnectActive = async () => {
    // e?.preventDefault();
    if( !! loginToken ) {
      const adminUserSocket = await io.connect(`${isWss? 'wss': 'ws'}://${host}/ws/line-chat`, {
        transports: ['websocket'],
        auth: {
          Authorization: `Bearer ${loginToken}`,
        },
      }); 
      // globalAdminUserSocket = adminUserSocket;

      console.log(adminUserSocket);
      setGlobalAdminUserSocket(adminUserSocket);
      return adminUserSocket;
    } else {
      alert("您的連線已中斷，請重新登入");
      window.location.href = "/AdminLogin?ref=Admin";
    }

    // await adminUserSocket.on('error', (data) => {
    //   console.error(data, 'adminUserSocket connected failed!');
    //   setGlobalAdminUserSocketConnected(false);
    //   console.log(globalAdminUserSocketConnected);
    // });
  }
  /* Admin Panel Socket Connection */
  const adminPanelSocketConnectActive = async () => {
    // e?.preventDefault();
    if( !! loginToken ) {
      const adminPanelSocket = await io.connect(`${isWss? 'wss': 'ws'}://${host}/ws/cs-panel`, {
        transports: ['websocket'],
        auth: {
          Authorization: `Bearer ${loginToken}`,
        },
      }); 
      // globalAdminPanelSocket = adminPanelSocket;
      console.log(adminPanelSocket);
      setGlobalAdminPanelSocket(adminPanelSocket);
      return adminPanelSocket;
    } else {
      alert("您的連線已中斷，請重新登入");
      window.location.href = "/AdminLogin?ref=Admin";
    }
    // console.log(globalAdminPanelSocket);
    // console.log(await socket);
    // console.log(await socket.connected);
    // let globalAdminPanelSocketConnectedTemp = await socket?.connected || false;
    // setGlobalAdminPanelSocketConnected(globalAdminPanelSocketConnectedTemp);

    // await adminPanelSocket.on('error', (data) => {
    //   console.error(data, 'adminPanelSocket connected failed!');
    //   setGlobalAdminPanelSocketConnected(false);
    //   console.log(globalAdminUserSocketConnected);
    // });
  } /*** END 登入後才能主動重新連線 User & Panel Socket ***/  


  /*** 主動重設 globalAdminSocket ***/
  // let resetGlobalAdminSocketConnectActiveTimeoutId = null;
  const resetGlobalAdminSocketConnectActive = async(e) => {
    e?.preventDefault();
    setGlobalAdminUserSocket({});
    setGlobalAdminPanelSocket({});
    // globalAdminSocket = globalAdminSocketContext;
    // let globalAdminUserSocketDefault = globalAdminSocket?.adminUserSocket || null;
    // let globalAdminPanelSocketDefault = globalAdminSocket?.adminPanelSocket || null;
    // setGlobalAdminUserSocket(globalAdminUserSocketDefault);
    // setGlobalAdminPanelSocket(globalAdminPanelSocketDefault);
    // console.log({globalAdminSocketContext});

    // let globalAdminUserSocketConnectedTemp = globalAdminUserSocket?.connected || false;
    // let globalAdminPanelSocketConnectedTemp = globalAdminPanelSocket?.connected || false;
    // setGlobalAdminUserSocketConnected(globalAdminUserSocketConnectedTemp);
    // setGlobalAdminPanelSocketConnected(globalAdminPanelSocketConnectedTemp);
    // console.log({globalAdminUserSocketConnected,globalAdminPanelSocketConnected});

    // if( !! loginToken ) {
    //   globalAdminUserSocketDefault.on("connect", () => {
    //       setGlobalAdminUserSocketConnected(true);
    //       console.log({globalAdminUserSocketConnected});
    //     });  
    // }
    // if( !! loginToken ) {
    //   globalAdminPanelSocketDefault.on("connect", () => {
    //       setGlobalAdminPanelSocketConnected(true);
    //       console.log({globalAdminPanelSocketConnected});
    //     });  
    //  }

    // if( ! globalAdminUserSocketConnected || ! globalAdminPanelSocketConnected ) {
    const adminUserSocket = await adminUserSocketConnectActive();
    const adminPanelSocket = await adminPanelSocketConnectActive();
    console.log(await adminUserSocket, await adminPanelSocket);
    const globalAdminSocket = {globalAdminUserSocket:await adminUserSocket, globalAdminPanelSocket:await adminPanelSocket};
    await setGlobalAdminSocket(globalAdminSocket);
    console.log(globalAdminSocket);
    // await adminGetRoomList();
    // await adminJoinRoom();
    // }

    // let resetGlobalAdminSocketConnectActiveInterval = "";
    // if( ! globalAdminUserSocketConnectedTemp || ! globalAdminPanelSocketConnectedTemp ) {
    //   resetGlobalAdminSocketConnectActiveInterval = setInterval(() => {
    //     resetGlobalAdminSocketConnectActive();
    //   }, 1000);
    // } else if( !! resetGlobalAdminSocketConnectActiveInterval ) {
    //   clearInterval(resetGlobalAdminSocketConnectActiveInterval);
    // }
    // if( !! resetGlobalAdminSocketConnectActiveTimeoutId ) {
    //   clearTimeout(resetGlobalAdminSocketConnectActiveTimeoutId);
    // }
  } 
  // useEffect(() => { // 讓連線成功後，使用者資料可自動載入
  //   resetGlobalAdminSocketConnectActiveTimeoutId = setTimeout(() => {
  //     resetGlobalAdminSocketConnectActive();
  //   }, 5000);
  // }, [globalAdminUserSocketConnected, globalAdminPanelSocketConnected]);
  /*** END 主動重設 globalAdminSocket ***/

  /*** 初始化 globalAdminUserSocket 及 globalAdminPanelSocket ***/
  const initializeAdminUserAndPanelSocket = async() => {
    console.log(globalAdminSocket);
    // const adminUserSocket = typeof globalAdminSocket === 'object' && globalAdminSocket !== null && Object.keys(globalAdminSocket).length > 0 ? globalAdminSocket?.globalAdminUserSocket || null : null;
    // const adminPanelSocket = typeof globalAdminSocket === 'object' && globalAdminSocket !== null && Object.keys(globalAdminSocket).length > 0 ? globalAdminSocket?.globalAdminPanelSocket || null : null;
    // setGlobalAdminUserSocket(adminUserSocket);
    // setGlobalAdminPanelSocket(adminPanelSocket);

    let adminUserSocket = null;
    if ( typeof globalAdminSocket === 'object' && globalAdminSocket !== null && Object.keys(globalAdminSocket).length > 0 ) {
      adminUserSocket = globalAdminSocket?.globalAdminUserSocket || null;
      console.log(adminUserSocket);
      setGlobalAdminUserSocket(adminUserSocket); // 最後改動此處，讓GlobalAdminSocket連線後設定GlobalAdminUserSocket
    }
    let adminPanelSocket = null;
    if ( typeof globalAdminSocket === 'object' && globalAdminSocket !== null && Object.keys(globalAdminSocket).length > 0 ) {
      adminPanelSocket = globalAdminSocket?.globalAdminPanelSocket || null;
      console.log(adminPanelSocket);
      setGlobalAdminPanelSocket(adminPanelSocket);
    }

    // setHasResetAdminUserAndPanelSocket(true);
    await checkAdminUserAndPanelSocketIsConnected(globalAdminUserSocket, globalAdminPanelSocket);
  }
  useEffect(() => {
    initializeAdminUserAndPanelSocket();
  }, []);
  // useEffect(() => {
  //   initializeAdminUserAndPanelSocket();
  // }, [globalAdminSocket]);  
  // useEffect(() => {
  //   console.log(globalAdminUserSocket)
  //   console.log(globalAdminPanelSocket)
  // }, [globalAdminUserSocket, globalAdminPanelSocket]);
  /*** END 初始化 globalAdminUserSocket 及 globalAdminPanelSocket ***/

  /*** 確認 User 及 Panel Socket連線狀態 ***/
  const checkAdminUserAndPanelSocketIsConnected = async() => {
    setInterval(() => {

    // const globalAdminUserSocket = typeof globalAdminSocket === 'object' && globalAdminSocket !== null && Object.keys(globalAdminSocket).length > 0 ? globalAdminSocket.adminUserSocket || null : null;
    // const globalAdminPanelSocket = typeof globalAdminSocket === 'object' && globalAdminSocket !== null && Object.keys(globalAdminSocket).length > 0 ? globalAdminSocket.adminPanelSocket || null : null;
    // console.log('globalAdminUserSocket',globalAdminUserSocket);
    // console.log('globalAdminPanelSocket',globalAdminPanelSocket);

    // let globalAdminUserSocketDefault = Object.keys(globalAdminSocketContext).length > 0 ? globalAdminSocketContext ?. adminUserSocket || null : null;
    // let globalAdminPanelSocketDefault = Object.keys(globalAdminSocketContext).length > 0 ? globalAdminSocketContext ?. adminPanelSocket || null : null;
    // setGlobalAdminUserSocket(globalAdminUserSocketDefault);
    // setGlobalAdminPanelSocket(globalAdminPanelSocketDefault);
    // console.log({globalAdminUserSocketDefault,globalAdminPanelSocketDefault});

    // let globalAdminUserSocketConnectedTemp = globalAdminUserSocket?.connected || false;
    // let globalAdminPanelSocketConnectedTemp = globalAdminPanelSocket?.connected || false;
    // setGlobalAdminUserSocketConnected(globalAdminUserSocketConnectedTemp);
    // setGlobalAdminPanelSocketConnected(globalAdminPanelSocketConnectedTemp);

    // let globalAdminUserSocketConnectedTemp = false;
    // let globalAdminPanelSocketConnectedTemp = false;
    // if( Object.keys(globalAdminUserSocket).length > 0 && Object.keys(globalAdminPanelSocket).length > 0 ) {
    //   globalAdminUserSocketConnectedTemp = globalAdminUserSocket?.connected || false;
    //   globalAdminPanelSocketConnectedTemp = globalAdminPanelSocket?.connected || false;
    //   setGlobalAdminUserSocketConnected(globalAdminUserSocketConnectedTemp);
    //   setGlobalAdminPanelSocketConnected(globalAdminPanelSocketConnectedTemp);
    // }
    // console.log(!! globalAdminUserSocket);
    // console.log(!! globalAdminPanelSocket);
    // console.log(globalAdminUserSocket);
    // console.log(globalAdminPanelSocket);
      if( typeof globalAdminUserSocket === 'object' && globalAdminUserSocket !== null && Object.keys(globalAdminUserSocket).length > 0 ) {
        console.log("GlobalAdminUserSocket Connect");
        const globalAdminUserSocketConnectedTemp = globalAdminUserSocket?.connected || false;
        setGlobalAdminUserSocketConnected(globalAdminUserSocketConnectedTemp);
        globalAdminUserSocket.on("connect", () => {
          setGlobalAdminUserSocketConnected(true);
          console.log(globalAdminUserSocketConnected);
        });
        // let adminUserSocketConnected = false;
        // if( typeof globalAdminUserSocket === 'object' && globalAdminUserSocket !== null && Object.keys(globalAdminUserSocket).length > 0 ) {
        //   adminUserSocketConnected = globalAdminUserSocket ?. connected || false;
        //   setGlobalAdminUserSocketConnected(adminUserSocketConnected);
        // }
        // let adminUserSocketConnected = typeof globalAdminUserSocket === 'object' && globalAdminUserSocket !== null && Object.keys(globalAdminUserSocket).length > 0 ? globalAdminUserSocket ?. connected || false : false;
        // setGlobalAdminUserSocketConnected(adminUserSocketConnected);
        console.log(typeof globalAdminUserSocket === 'object' && globalAdminUserSocket !== null && Object.keys(globalAdminUserSocket).length > 0);
        console.log('globalAdminUserSocket', globalAdminUserSocket);
        // console.log('adminUserSocketConnected', adminUserSocketConnected);
        console.log('globalAdminUserSocketConnected', globalAdminUserSocketConnected);
        // globalAdminUserSocket.emit('joinRoom', {'roomId': '643e17870d7ae5078453088a'});
      }
      if( typeof globalAdminPanelSocket === 'object' && globalAdminPanelSocket !== null && Object.keys(globalAdminPanelSocket).length > 0 ) {
        console.log("GlobalAdminPanelSocket Connect");
        const globalAdminPanelSocketConnectedTemp = globalAdminPanelSocket?.connected || false;
        setGlobalAdminPanelSocketConnected(globalAdminPanelSocketConnectedTemp);
        globalAdminPanelSocket.on("connect", () => {
          setGlobalAdminPanelSocketConnected(true);
          console.log(globalAdminPanelSocketConnected);
        }); 
        // let adminPanelSocketConnected = false; 
        // if( typeof globalAdminPanelSocket === 'object' && globalAdminPanelSocket !== null && Object.keys(globalAdminPanelSocket).length > 0 ) {
        //   adminPanelSocketConnected = globalAdminPanelSocket ?. connected || false;
        //   setGlobalAdminPanelSocketConnected(adminPanelSocketConnected);
        // }
        // let adminPanelSocketConnected = typeof globalAdminPanelSocket === 'object' && globalAdminPanelSocket !== null && Object.keys(globalAdminPanelSocket).length > 0 ? globalAdminPanelSocket ?. connected || false : false;
        // setGlobalAdminPanelSocketConnected(adminPanelSocketConnected);
        console.log('globalAdminPanelSocket', globalAdminPanelSocket);
        // console.log('adminPanelSocketConnected', adminPanelSocketConnected);
        console.log('globalAdminPanelSocketConnected', globalAdminPanelSocketConnected);
      }
    }, 100000);
  }
  // const checkAdminUserAndPanelSocketIsConnected = async (globalAdminUserSocket, globalAdminPanelSocket) => {
  //   console.log({ globalAdminUserSocket, globalAdminPanelSocket });
  //   console.log(!!globalAdminUserSocket);
  //   console.log(!!globalAdminPanelSocket);
  //   if ( !! globalAdminUserSocket ) {
  //     console.log("GlobalAdminUserSocket Connect");
  //     console.log(typeof globalAdminUserSocket === 'object');
  //     console.log(globalAdminUserSocket !== null);
  //     console.log(Object.keys(globalAdminUserSocket).length > 0);
  //     let adminUserSocketConnected = null;
  //     if ( typeof globalAdminUserSocket === 'object' && globalAdminUserSocket !== null && Object.keys(globalAdminUserSocket).length > 0 ) {
  //       adminUserSocketConnected = globalAdminUserSocket?.connected || null;
  //       console.log(adminUserSocketConnected);
  //     }
  //     console.log(adminUserSocketConnected);
  //     setGlobalAdminUserSocketConnected(adminUserSocketConnected);
  //     console.log(globalAdminUserSocketConnected);
  //   }
  //   if ( !! globalAdminPanelSocket ) {
  //     console.log("GlobalAdminPanelSocket Connect");
  //     console.log(typeof globalAdminPanelSocket === 'object');
  //     console.log(globalAdminPanelSocket !== null);
  //     console.log(Object.keys(globalAdminPanelSocket).length > 0);
  //     let adminPanelSocketConnected = null;
  //     if ( typeof globalAdminPanelSocket === 'object' && globalAdminPanelSocket !== null && Object.keys(globalAdminPanelSocket).length > 0 ) {
  //       adminPanelSocketConnected = globalAdminPanelSocket?.connected || null;
  //       console.log(adminPanelSocketConnected);
  //     }
  //     console.log(adminPanelSocketConnected);
  //     console.log(globalAdminPanelSocketConnected);
  //     setGlobalAdminPanelSocketConnected(adminPanelSocketConnected);
  //     console.log(globalAdminPanelSocketConnected);
  //   }
  //   console.log({ globalAdminUserSocketConnected, globalAdminPanelSocketConnected });
  // };  
  // useEffect(() => {
  //   // if( ! runningUseEffect ) {
  //   //   return;
  //   // }
  //   checkAdminUserAndPanelSocketIsConnected();
  // }, [globalAdminUserSocket, globalAdminPanelSocket]);

  useEffect(() => {
    console.log({globalAdminUserSocketConnected,globalAdminPanelSocketConnected});
  }, [globalAdminUserSocketConnected, globalAdminPanelSocketConnected]);
  /*** END 確認 User 及 Panel Socket連線狀態 ***/

  // /*** 確認管理者是否已加入房間 ***/
  // const checkAdminJoinRoom = () => {
  //   // const globalAdminUserSocketConnected = globalAdminUserSocket?.connected || false;
  //   if( !! globalAdminUserSocket && globalAdminUserSocketConnected ) {
  //     globalAdminUserSocket.on("joinRoom", (data) => {
  //       console.log(data, 'cs');
  //       setGlobalJoinRoom(true);
  //     });
  //   }
  // }
  // useEffect(() => {
  //   checkAdminJoinRoom();
  // }, [globalAdminUserSocketConnected, globalRoomId]);
  // /*** END 確認管理者是否已加入房間 ***/

  // /*** 管理者取得使用者Room List ***/
  // const adminGetRoomList = async() => {
  //   // e?.preventDefault();
  //   console.log({globalAdminUserSocket, globalAdminPanelSocket})
  //   if( !! globalAdminUserSocket ) {
  //     await globalAdminUserSocket.on("connect", () => {
  //       globalAdminUserSocket.emit('roomList');
  //       console.log('Admin Assign Emit: Administer Request RoomList !');
  //     })
  //     await globalAdminUserSocket.on("error", (data) => {
  //       console.error(data, 'Admin Assign Emit: Administer Request RoomList Failed !');
  //     });
  //   }
  //   if( !! globalAdminPanelSocket ) {
  //     await globalAdminPanelSocket.on("connect", () => {
  //       globalAdminPanelSocket.on("roomList", (data) => {
  //         console.log(data, 'Admin Assign On: Get CS RoomList !');
  //       });
  //     });
  //     await globalAdminPanelSocket.on("error", (data) => {
  //       console.error(data, 'Admin Assign On: Get CS RoomList Failed !');
  //     });
  //   }
  // }/*** END 管理者取得使用者Room List ***/

  /*** 管理者加入房間 ***/
  const adminJoinRoom = async() => {
    // adminLeaveRoomTOID = setTimeout(() => {
      console.log(globalAdminUserSocket);
      const globalAdminUserSocketConnected = globalAdminUserSocket?.connected || false;
      console.log(globalAdminUserSocketConnected);
      console.log(globalRoomId);
      if( globalAdminUserSocketConnected && !! globalRoomId ) {
        globalAdminUserSocket.emit('joinRoom', {roomId: globalRoomId});
        setGlobalJoinRoom(true);
        console.log('Emit: Administer Join Room Again !');
      } else if( ! globalAdminUserSocketConnected ) {
        console.log("GlobalAdminUserSocket not Connected !");
      } else {
        console.log("GlobalRoomId not Exist!");
      }
    //   clearTimeout(adminLeaveRoomTOID);
    // }, 8000);
  } /*** END 管理者加入房間 ***/

  /*** 管理者離開房間 ***/
  // let adminLeaveRoomTOID = "";
  const adminLeaveRoom = async() => {
    // adminLeaveRoomTOID = setTimeout(() => {
      console.log(globalAdminUserSocket);
      const globalAdminUserSocketConnected = globalAdminUserSocket?.connected || false;
      console.log(globalAdminUserSocketConnected);
      console.log(globalRoomId);
      if( globalAdminUserSocketConnected && !! globalRoomId ) {
        globalAdminUserSocket.emit('leaveRoom', {roomId: globalRoomId});
        setGlobalJoinRoom(false);
        console.log('Emit: Administer Leave Room Again !');
      } else if( ! globalAdminUserSocketConnected ) {
        console.log("GlobalAdminUserSocket not Connected !");
      } else {
        console.log("GlobalRoomId not Exist!");
      }
    // }, 5000);
  } /*** END 管理者離開房間 ***/  

  // /*** Socket事件監聽 ***/
  // const socketEventListening = async() =>{
  //   await globalAdminUserSocket.on("connect", () => {
  //     console.log("GlobalAdminUserSocket has connected!");
  //     // console.log(socket);
  //     // console.log(socket.connected);
  //     // let globalAdminUserSocketConnectedTemp = adminUserSocket?.connected || false;
  //     // setGlobalAdminUserSocketConnected(globalAdminUserSocketConnectedTemp);
  //     // console.log({globalAdminUserSocketConnectedTemp});
  //     setGlobalAdminUserSocketConnected(true);
  //     console.log(globalAdminUserSocketConnected);
  //   });
  //   // console.log('adminUserSocket connected failed!');
  //   // setGlobalAdminUserSocketConnected(false);
  //   // console.log(globalAdminUserSocketConnected);

  //   await adminPanelSocket.on("connect", () => {
  //     console.log("adminPanelSocket has connected!");
  //     setGlobalAdminPanelSocketConnected(true);
  //     console.log(globalAdminUserSocketConnected);
  //   });
  //   // console.error('adminPanelSocket connected failed!');
  //   // setGlobalAdminPanelSocketConnected(false);  
  // } /*** END Socket事件監聽 ***/

  return (
    <>
      {/* <UserListContent assignGlobalAdminUserSocketConnected={globalAdminUserSocketConnected} assignGlobalAdminPanelSocketConnected={globalAdminUserSocketConnected}></UserListContent> */}
      {/* <SocketAdminListening 
        globalAdminUserSocket={globalAdminUserSocket} 
        setGlobalAdminUserSocket={setGlobalAdminUserSocket}
        globalAdminPanelSocket={globalAdminPanelSocket}
        setGlobalAdminPanelSocket={setGlobalAdminPanelSocket}
        roomId={roomId} 
      /> */}
      {/* <SocketAdminListening  
        globalAdminUserSocket={globalAdminUserSocket} 
        setGlobalAdminUserSocket={setGlobalAdminUserSocket}
        globalAdminPanelSocket={globalAdminPanelSocket}
        setGlobalAdminPanelSocket={setGlobalAdminPanelSocket}     
        msgExist={msgExist} 
        setMsgExist={setMsgExist} 
        textMsgArray={textMsgArray} 
        setTextMsgArray={setTextMsgArray}  
        roomId={roomId} 
        joinRoom={joinRoom}         
      /> */}
      <SocketAdminListening />
      <div className={style.chatRoomAssign}>
        {/* <Dropdown
          // title="Dropdown Title"
          className={style.chatRoomAssignDropdown}
          animationType="default"
          animationPosition="nortwest"
          buttonProps={{
            text: "傳送至【" + channelTypeState + "】",
          }}
          onSelect={(e) => {
            switch (e) {
              case 1:
                setGlobalChannelType('LINE');
                break;
              case 2:
                setGlobalChannelType('FACEBOOK');
                break;
              case 3:
                setGlobalChannelType('EMAIL');
                break;
              default:
                setGlobalChannelType('CHAT');
                break;
            }
          }}
          items={[
            {
              text: "聊天室",
            },
            {
              text: "LINE",
            },
            {
              text: "FACEBOOK",
            },
            {
              text: "Email",
            },
          ]}
        /> */}
        {/* <div style={{'display':'none'}}> */}
        {/* <div style={{'color':'#FFF'}}> */}
          {/* {JSON.stringify(globalAdminUserSocketConnected)} {JSON.stringify(globalAdminPanelSocketConnected)} {JSON.stringify(globalHasJoinedRoom)}  */}
          {/* {JSON.stringify( globalJoinRoom )} {JSON.stringify(globalAdminUserSocketConnected && globalAdminPanelSocketConnected && globalHasJoinedRoom )} */}
        {/* </div> */}
        <h6 className={style.chatRoomAssignH6}>聊天室
          {/* <span className={style.chatRoomAssignSpan}>（#{globalRoomId}）</span> */}
        </h6>
        { globalAdminUserSocketConnected && globalAdminPanelSocketConnected && globalHasJoinedRoom ? (
          <Button 
            text = {"已連線"} 
            onClick = { async(e) => {
              if( !! loginToken && !! host && !! isWss ) {
                // stopRunningUseEffect(e);

                // adminLeaveRoomAndClearSocket();
                // await resetGlobalAdminSocketConnectActive(e);
                await adminLeaveRoom();
                await adminJoinRoom();
                // await setGlobalJoinRoom(null);
                // await adminJoinRoomPassive();
                // await setGlobalRoomId(''); // 重置roomId可重新加入房間
                // await setGlobalRoomId(globalRoomId);
                // switchRenderState();

                // setGlobalAdminUserSocketConnected(false);
                // setGlobalAdminPanelSocketConnected(false);
              } else {
                alert("連線參數錯誤，請確認是否已先登入！");
              }
            }}
            className={style.chatRoomAssignButton}
            backgroundColor = {'rgb(55 200 55)'} 
            title ="重新連線" 
            icon = {{
              float:'left',
              size:15,
              component:<LinkOutlined />
            }}
          />
        ) : (
          <Button 
            text = {"未連線"} 
            onClick = { async(e) => {
              if( !! loginToken && !! host && !! isWss ) {
                // startRunningUseEffect(e);

                // await resetGlobalAdminSocketConnectActive(e);
                // await adminJoinRoomPassive();
                await adminLeaveRoom();
                await adminJoinRoom();
                // await setGlobalJoinRoom(null);
                // await setGlobalRoomId('');
                // await setGlobalRoomId(globalRoomId);
                // switchRenderState();

                // adminUserSocketConnectActive(e);
                // adminPanelSocketConnectActive(e);
              } else {
                alert("連線參數錯誤，請確認是否已先登入！");
              }
            }}
            backgroundColor = {'rgb(250 50 50)'} 
            title ="重新連線" 
            icon = {{
              float:'left',
              size:15,
              component:<DisconnectOutlined />
            }}
          />
        )}
      </div>

    </>
  )
}

export default ChatRoomAssign