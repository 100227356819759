import React, { useState, useContext,useEffect } from 'react'
import style from './styles/LoginIndex.module.css'
import RegisterStyle from './styles/Register.module.css'
import { Container,Row,Col } from 'react-bootstrap'
import popupStyle from '../../Components/styles/popup.module.css'
import Modal from 'react-bootstrap/Modal'
import lineIcon from './imgs/LINE.png'
import bannerForPhone from './imgs/RegisterPhoneBanner.png'
import liff from '@line/liff' // liff SDK
import PopupBanner from './imgs/PopupBanner.png'

function Register(props) {

  const {setSheet, setLineRegister}= props;
  const {setsmsveristatus}=props;  // set-檢查使用者是否有通過簡訊驗證，以啟用下一步按鈕(上層傳入)
  const {smsveristatus}=props;  // status-檢查使用者是否有通過簡訊驗證，以啟用下一步按鈕(上層傳入)

  const [myform, setMyform] = useState({}); // 設定表單資料

  const clearMyformRegisterData = (event) => {
    event.preventDefault();
    setMyform({ ...myform, registerRepassword: '' });
    setMyform({ ...myform, registerEmail: '' });
  }  

  //設定Modal 顯示與否
  const [show, setShow] = useState(false);
  //設定line登入成功狀態
  const [LineResponse, setLineResponse] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const Popup = (props) => {
  
    return (
      <>
          <>
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
                <Col className={popupStyle.modal}>
                <div className={popupStyle.modalClose} onClick={(event)=>{handleClose();clearStorage(event);}}>x</div>
                  <div className={popupStyle.modalHeader} >
                  <Modal.Title className={popupStyle.modalTitle}></Modal.Title>
                  <div className={popupStyle.PopupBanner}>
                    <img src={PopupBanner}></img>
                  </div>
                 </div>
                <Modal.Body className={popupStyle.modalBody} >
             
                </Modal.Body>
              </Col>
          </Modal>
        </>
      </>
    );
  }

  /* 案X清除Local Storage並登出LINE、FB */
  const clearStorage = (event) => {
    event.preventDefault();
    // 如果LINE有登入，執行LINE登出
    const isLoggedIn = liff.isLoggedIn();
    if(isLoggedIn){
      liff.logout();
    }
    // 如果FB物件存在，且為登入中，將FB登出
    if( !! window.FB ) {
      window.FB.getLoginStatus(function (response) {
        if(response.status==='connected') {
          window.FB.logout(function (response) {
            // console.log('handleFBLogout', response);
          });
        }
      });
    }
    localStorage.clear();
  }

  const lineid = process.env.REACT_APP_LINE_APP_ID 
  const BACKENDURL = process.env.REACT_APP_BACKENDURL;
  const linelogin = async() => {
    const init = await liff.init({
        liffId: lineid, // Use own liffId
        // withLoginOnExternalBrowser: true, // Enable automatic login processFBStatus
       
    })
    .catch((err) => {
        // Todo: retry init.
        console.log('err',err);
    });

  }
  /* 進行LINE登入 */
  const LineAuth = () => {
      const isLoggedIn = liff.isLoggedIn();
      // if(!isLoggedIn){
      liff.login({ redirectUri: "https://"+ BACKENDURL +"/LoginEncode?page=register" });
      // liff.login();
      setLineResponse(isLoggedIn);
      // }
  }

  
  useEffect(() => {
    linelogin();
  }, []);

  return (
    <>
      <Popup></Popup>
      <div className='p-4'>
      {/* <div style={{width:'100%'}}>
      // 要改POPUP HEADER層
         <img style={{width:'100%'}} src={bannerForPhone}></img>
     </div> */}
      <Col className="mb-2">
            <div className={RegisterStyle.KidProLoginTitleBox}>
              <span className={RegisterStyle.KidProLoginTitle}>註冊 新會員</span>
            </div>
      </Col>
      <Col>
        <p className={RegisterStyle.subTitle}>透過LINE綁定／註冊</p>
      </Col>
      <Col>
       <div className={RegisterStyle.LINE} onClick={LineAuth}>
          <img className={RegisterStyle.LINEimg} src={lineIcon}></img>
       </div>
      </Col>
      <Col>
        <div className={RegisterStyle.directSec}>
          <div  onClick={() => { setSheet('registerPhone'); window.localStorage.setItem('lineRegister', ''); }}>
              <div className={RegisterStyle.directtoNoline}>
                <div>沒有LINE帳號</div>
                <div>(手機註冊)</div>
              </div>
          </div>
          <div  onClick={()=>{ setSheet('LoginIndex'); }}>
              {/* <span className={RegisterStyle.directtoLogin}>手機／Email登入</span> */}
              <div className={RegisterStyle.directtoNoline}>
                <div>手機／</div>
                <div>Email登入</div>
              </div>
          </div>
        </div>
      </Col>
      </div>
    </>
    
  )
}

export default Register