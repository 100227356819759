import React, { useState, useEffect, useReducer } from 'react'
import style from './styles/ChatRoomContainer.module.css'

/*** Import Chat Room Components ***/
import ChatRoomAssign from './ChatRoomAssign'
import ChatRoomContent from './ChatRoomContent'
import ChatRoomSend from './ChatRoomSend'

function ChatRoomContainer(props) {
  //聊天區塊
  return (
    <>
      <section className={style.chatRoomContainerAssignSec}>
        <ChatRoomAssign></ChatRoomAssign>
      </section>
      <section className={style.chatRoomContainerContentSec}>
        <ChatRoomContent></ChatRoomContent>
      </section>
      <section id="chatRoomContainerSendSec" className={style.chatRoomContainerSendSec}>
        <ChatRoomSend></ChatRoomSend>
      </section>
    </>
  )
}

export default ChatRoomContainer