import React, {useEffect} from 'react'
import liff from '@line/liff';

function CustomerLiffLogin() {
  const lineid = process.env.REACT_APP_LINE_APP_ID; // 官網LINE Developer ID
  const frontEndURL = process.env.REACT_APP_FRONTENDURL; // 首頁URL
  const fromChatRoomLogin = window.localStorage.getItem( "fromChatRoomLogin" ) ?? "false"; // 判斷是否由CustomerLiff過來

  /* 登入LINE */
  const lineAuth = () => {
    liff.init({
      liffId: lineid, // Use own liffId
      withLoginOnExternalBrowser: true, // Enable automatic login process
    })
    .then(() => {
      const isLoggedIn = liff.isLoggedIn();
      let liffLoginRedir = "";
      if( fromChatRoomLogin === "true" ) {
        liffLoginRedir = "https://" + frontEndURL + "/ChatRoomLogin";
      } else {
        liffLoginRedir = "https://" + frontEndURL + "/CustomerLiffRedir?page=homepage"; 
      }
      if( ! isLoggedIn ) {
        liff.login({ redirectUri: liffLoginRedir });
      } else {
        if( fromChatRoomLogin === "true" ) {
          window.location.href = "/ChatRoomLogin?line_login=true";
        } else {
          window.location.href = "/?line_login=true";
        }
      }
    })
    .catch((err) => {
      console.log(err);
    });
  }

  useEffect(()=>{
    lineAuth();
  },[]);
  
}

export default CustomerLiffLogin