import React from 'react'
import Customer from './Customer'

function CustomerDemo() {
  return (
    <section>
        <Customer></Customer>
    </section>
  )
}

export default CustomerDemo