import React, { useState, useContext, useEffect, useCallback, useRef } from 'react';
import { MessageList, MessageBox } from "react-chat-elements";
import CryptoJS  from 'crypto-js';
import Axios from 'axios';
import fileDownload from 'js-file-download';

/*** CSS ***/
import style from './styles/CustomerChatRoomContent.module.css';
import './styles/CustomerChatRoomRce.css';
import './styles/CustomerChatRoomAnt.css';

/*** Import Socket Component ***/
import { GlobalSocket } from '../../../SocketIO/Customer/SocketCustomerConnect';
import SocketCustomerJoinRoom from '../../../SocketIO/Customer/SocketCustomerJoinRoom';
import SocketCustomerListening from '../../../SocketIO/Customer/SocketCustomerListening';

/*** Import Chat Room Content Components ***/
import AntDatePicker from './MessageComponents/AntDatePicker';
import RceMessage from './MessageComponents/RceMessage';
import RceText from './MessageComponents/RceText';
import RcePhoto from './MessageComponents/RcePhoto';
import RceVideo from './MessageComponents/RceVideo';

function CustomerChatRoomContent(props) {
  const {globalSocket} = props;
  // const globalSocket = useContext(GlobalSocket);
  // const isGlobalSocket = globalSocket?.connected || false;
  const [textMsgArray, setTextMsgArray] =useState([]);
  const [msgExist, setMsgExist] = useState(false);
  const [socketListeningReady, setSocketListeningReady] = useState(false);
  const [hasGotPreMsg, setHasGotPreMsg] = useState(false); // 是否已取得前次訊息

  /*** 取得加密的使用者資料 ***/
  let defaultUserData = null;
  // let rederCount = 0;
  const[userData, setUserData] = useState({});
  const lineAppId = process.env.REACT_APP_LINE_APP_ID;
  const cpo = window.localStorage.getItem("cpo") ? window.localStorage.getItem("cpo") : "";
  const accessToken = window.localStorage.getItem( "LIFF_STORE:" + lineAppId + ":accessToken" ) ? window.localStorage.getItem( "LIFF_STORE:" + lineAppId + ":accessToken" ) : "";
  const getCipherUserData = () => {
      if( !! accessToken && !! cpo ) {
          try {
              const userDataJson  = CryptoJS.AES.decrypt( cpo, `${accessToken}` );
              // console.log(userDataJson);
              const userDataUTF8 = userDataJson?.toString(CryptoJS.enc.Utf8);
              // const userDataUTF8 = CryptoJS.enc.Utf8.parse(userDataJson);
              const userDataDecode = JSON.parse( userDataUTF8 );
              // setUserData(userDataDecode);

              // const userDataJson  = CryptoJS.AES.decrypt(cpo, accessToken);
              // const userDataDecode = JSON.parse(userDataJson?.toString(CryptoJS.enc.Utf8));

              setUserData( userData => ({...userData, userDataDecode}));
              // console.log({userData});
              defaultUserData = userDataDecode;
              // console.log({defaultUserData});
              return defaultUserData;
          } catch (e) {
              console.log(e);
              return false;
          }   
      }   
  }
  useEffect(() => {
      getCipherUserData();
  }, [cpo])
  /*** END 取得加密的使用者資料 ***/          

  /*** 文字訊息預設格式 ***/
  let textArray = [
    {
      position: "right",
      type: "text",
      title: "【LINE登入使用者】",
      text: "",
    }
  ]; 
  // let textMsgObjDefault = {
  //   position: "right",
  //   type: "text",
  //   title: "【LINE登入使用者】",
  //   text: "",
  // }
  // useEffect(()=>{
  //   setTextMsgArray( current => [...current, textMsgObjDefault] ); // 設定預設訊息Array
  // }, [])
  /*** END 文字訊息預設格式 ***/ 

  // /*** 預載localStorge中的訊息 ***/
  // const reloadingMsg = () => {
  //   const preMsg = window.localStorage.getItem('textMsgArray') ? JSON.parse(window.localStorage.getItem('textMsgArray')) : "";
  //   setTextMsgArray(preMsg);
  // }
  // useEffect(()=>{
  //   reloadingMsg();
  // }, []);
  // /*** END 預載localStorge中的訊息 ***/

  /*** 預載舊訊息***/
  const loadingPrevMessage = (data) => {
    let textMsgArrayTemp = [];
    const messages = data?.messages || "";
    messages.map((v, i) => {
      let userStatus, firstName, lastName, email, phone, lineId, isLineBind, avatar, statusMessage;
      userStatus = "【一般使用者】";
      avatar = "https://www.gravatar.com/avatar/?d=mp";
      firstName = lastName = email = phone = lineId = isLineBind = avatar = statusMessage = "";
      const getId = v?.id || "";
      const getUserType = v?.userType || "CS";
      const getChannelId = v?.channelId || "";
      const getChannelType = v?.channelType || "CHAT";
      const position = getUserType ? ( getUserType === "CS" ? "left" : "right" ) : "left";
      const getType = v?.type || "";
      const getText = v?.text || "";
      const getDataType = v?.data?.type || "";
      const getDataName = v?.data?.name || "";
      const getDataUri = v?.data?.uri || "";
      const getDataUrl = v?.data?.url || "";
      const getDataBuffer = v?.data?.buffer || "";
      const getDate = v?.date || new Date();
      if( !! getUserType && getUserType !== "CS" ) { // 身分為顧客，登入時才會將資料存入localStorage
        defaultUserData = getCipherUserData();
        userStatus = defaultUserData?.userStatus || "【一般使用者】";
        firstName = defaultUserData?.firstName || "";
        lastName = defaultUserData?.lastName || "";
        email = defaultUserData?.email || "";
        phone = defaultUserData?.phone || "";
        lineId = defaultUserData?.lineId || "";
        isLineBind = defaultUserData?.isLineBind || false;
        avatar = defaultUserData?.avatar || "https://www.gravatar.com/avatar/?d=mp";
        statusMessage = defaultUserData?.statusMessage || "";
      } else {
        userStatus = "【客服人員】";
        avatar ='/cs.png';
      }
      if( !! getId ) {  
        setMsgExist(true);
        const textMsgEl = {
            position,
            userStatus,
            firstName,
            lastName,
            email,
            phone,
            lineId,
            isLineBind,
            avatar,
            statusMessage,
            getId,
            getUserType,
            getChannelId,
            getChannelType,
            getType,
            getText,
            getDataType,
            getDataName,
            getDataUri,
            getDataUrl,
            getDataBuffer,
            getDate
        }
        textMsgArrayTemp.push(textMsgEl);
      }
    });
    setTextMsgArray(textMsgArrayTemp);
    setHasGotPreMsg(true);
    // console.log(textMsgArrayTemp);
    // console.log(textMsgArray);
  } /*** END 預載舊訊息***/
  

  /*** 清除State中重複訊息 ***/
  const clearRedupulicMsg = () => {
    // console.log( 'textMsgArray', textMsgArray );
    // console.log("typeof textMsgArray", typeof textMsgArray);
    for( let [i, v] of Object.entries(textMsgArray) ) {
    // console.log({i, v});
    if( i > 0 ) {
        const curId = v?.getId || "";
        const preId = textMsgArray[i-1]?.getId || "";
        // const lineId = v?.lineId || "";
        // console.log({curValue,preValue});
        if( curId === preId ) {
          textMsgArray.splice(i, 1);
        }
        const textMsgArrayLength = textMsgArray?.length || 0;
        const oriTextMsgArray = JSON.parse(window.localStorage.getItem('textMsgArray'));
        const oriTextMsgArrayLength = oriTextMsgArray?.length || 0;
        // console.log({textMsgArrayLength,oriTextMsgArrayLength});
        if( textMsgArrayLength > oriTextMsgArrayLength ) {
          // window.localStorage.setItem('textMsgArray',JSON.stringify(textMsgArray));
        }
        // console.log('textMsgArrayClear',textMsgArray);
    }
    }
    // console.log('textMsgArray',textMsgArray);
  }
  useEffect(() => {
      clearRedupulicMsg();
  }, [textMsgArray]);
  /*** END 清除State中重複訊息 ***/

  /*** 使用者傳送訊息，將訊息存入State ***/
  const handleNewTextMsg = async(data) => {
    console.log({data});
    clearRedupulicMsg();
    /*  預設資料
      const userDataDefault = {
        userId: profile.userId,
        userStatus: "【一般使用者】",
        firstName: "",
        lastName: profile.displayName,
        email: "",
        phone: "",
        lineId: profile.userId,
        isLineBind: false,
        avatar: profile.pictureUrl,
        statusMessage: profile.statusMessage
      };
      const data = {
        "id": "string",
        "userId": "string",
        "type": "text",
        "status": "waiting",
        "text": "string",
        "data": {
            "type": "string",
            "name": "string",
            "uri": "string",
            "buffer": {}
        }
      }
    */
    let userStatus, firstName, lastName, email, phone, lineId, isLineBind, avatar, statusMessage;
    userStatus = "【一般使用者】";
    avatar = "https://www.gravatar.com/avatar/?d=mp";
    firstName = lastName = email = phone = lineId = isLineBind = avatar = statusMessage = "";
    const getId = data?.id || "";
    const getUserType = data?.userType || "CUSTOMER";
    const getChannelId = data?.channelId || "";
    const getChannelType = data?.channelType || "CHAT";
    const position = getUserType ? ( getUserType === "CS" ? "left" : "right" ) : "right";
    const getType = data?.type || "";
    const getText = data?.text || "";
    const getDataType = data?.data?.type || "";
    const getDataName = data?.data?.name || "";
    const getDataUri = data?.data?.uri || "";
    const getDataUrl = data?.data?.url || "";
    const getDataBuffer = data?.data?.buffer || "";
    const getDate = data?.date || new Date();
    if( !! getUserType && getUserType !== "CS" ) {
      defaultUserData = getCipherUserData();
      userStatus = defaultUserData?.userStatus || "【一般使用者】";
      firstName = defaultUserData?.firstName || "";
      lastName = defaultUserData?.lastName || "";
      email = defaultUserData?.email || "";
      phone = defaultUserData?.phone || "";
      lineId = defaultUserData?.lineId || "";
      isLineBind = defaultUserData?.isLineBind || false;
      avatar = defaultUserData?.avatar || "https://www.gravatar.com/avatar/?d=mp";
      statusMessage = defaultUserData?.statusMessage || "";
    } else {
      userStatus = "【客服人員】";
      avatar ='/cs.png';
    }
    if( !! getId ) {  
      setMsgExist(true);
      let newTextMsgArray = textMsgArray;
      const newTextMsg = {
        position,
        userStatus,
        firstName,
        lastName,
        email,
        phone,
        lineId,
        isLineBind,
        avatar,
        statusMessage,
        getId,
        getUserType,
        getChannelId,
        getChannelType,
        getType,
        getText,
        getDataType,
        getDataName,
        getDataUri,
        getDataUrl,
        getDataBuffer,
        getDate
      }
      let lastEl = textMsgArray?.slice(-1)[0] || "";
      let lastElId = lastEl?.getId || "";     
      if( lastElId != getId ) {
          // console.log({textMsgArray, newTextMsgArray, newTextMsg});
          console.log(newTextMsg);
          setTextMsgArray(current => [...current, newTextMsg]); // 更新狀態
          window.localStorage.setItem('textMsgArray', JSON.stringify(textMsgArray));
      }
    }
  } /*** END 使用者傳送訊息，將訊息存入State ***/
  useEffect(()=>{
    console.log(textMsgArray);
  },[textMsgArray])

  /*** 監聽Socket事件 ***/
  const listenSocketEvent = async() => {
    const globalSocketConnected = globalSocket?.connected || false;
    console.log('listenSocketEvent', globalSocketConnected);
    if (globalSocketConnected) {
      globalSocket.on("joinRoom", (data) => {
        console.log(data);
      });
      globalSocket.on("message", (data) => {
        handleNewTextMsg(data);
      });
      globalSocket.on("getMessages", (data) => {
        console.log('getMessages',data);
        loadingPrevMessage(data);
      });
      globalSocket.on('unReadMessage', (data) => {
        console.log('unReadMessage', data);
      })
      globalSocket.on("error", (data) => {
        console.error(data);
      });
      setSocketListeningReady(true);
      // return socketListeningReady;
      // await emitPrevMessages();
    }
  }     
  // useEffect(() => {
  //   listenSocketEvent();
  // }, []);
  useEffect(() => {
    listenSocketEvent();
  }, []);
  // useEffect(() => {
  //     listenSocketEvent();
  // }, [globalSocket]);
  // useEffect(() => {
  //     setTimeout(()=>{
  //         listenSocketEvent();
  //     }, "10000")
  // });
  /*** END Socket監聽事件 ***/   
  
  /*** 請求舊訊息 ***/
  const emitPrevMessages = async() => {
    setTimeout(() => {
      console.log("listenUserSocketMessageEvent"); 
      const globalSocketConnected = globalSocket?.connected || false;
      if( socketListeningReady && globalSocketConnected ) {
        console.log("globalSocketConnected is true", globalSocketConnected); 
        globalSocket.emit("getMessages", {
          "cursorD": new Date(),
          "limit": 50
        });
        console.log('Customer Chat Room Content Emit: getMessages');
      }
    }, 2000);
  } 
  const ayncListeningAndEmit = async() => {
    await listenSocketEvent();
    await emitPrevMessages();
  }
  useEffect(() => {
    ayncListeningAndEmit();
  }, [globalSocket, socketListeningReady])
  /*** END 請求舊訊息 ***/  

  /*** 下載檔案 ***/
  const downloadFile = (dataUrl, dataName) => {
    // window.open(
    //   dataUrl
    // );
    Axios.get(dataUrl, {
      responseType: 'blob',
    }).then(res => {
      fileDownload(res.data, dataName);
    });
  } /*** END 下載檔案 ***/  

  /*** Scroll to Last Message ***/
  const scrollToLastMsg = () => {
    // if( hasGotPreMsg ) {
      const rceContainerMboxArr = document.getElementsByClassName('rce-container-mbox');
      const rceContainerMboxArrLength = rceContainerMboxArr?.length || 0;
      if( rceContainerMboxArrLength > 0 ) {
        const lastRceContainerMbox = rceContainerMboxArr[rceContainerMboxArr.length-1];
        lastRceContainerMbox.scrollIntoView({ behavior: "smooth" });
      }
    // }
  }
  useEffect(() => {
      scrollToLastMsg();
  }, [hasGotPreMsg, JSON.stringify(textMsgArray)]);
  /*** END Scroll to Last Message ***/
  
  return (
      <>
        <SocketCustomerJoinRoom globalSocket={globalSocket} />
        {/* <SocketCustomerListening globalSocket={globalSocket} msgExist={msgExist} setMsgExist={setMsgExist} textMsgArray={textMsgArray} setTextMsgArray={setTextMsgArray}/> */}
        {/* {textArray.map((v,i) => {
          // console.log('v',v);
          return(
            <MessageList
              className='message-list'
              lockable={true}
              toBottomHeight={'100%'}
              dataSource={textArray}
            />
          )
        })} */}
        { Object.entries(textMsgArray).length > 0 || msgExist ? (
          textMsgArray.map(( v, i ) => {
            // console.log('textMsgArray',textMsgArray);
            /*
              const textMsgArray = {
                  position,
                  userStatus,
                  firstName,
                  lastName,
                  email,
                  phone,
                  lineId,
                  isLineBind,
                  avatar,
                  statusMessage,
                  getId,
                  getUserType,
                  getChannelId,
                  getChannelType,
                  getType,
                  getText,
                  getDataType,
                  getDataName,
                  getDataUri,
                  getDataUrl,
                  getDataBuffer
              }              
            */
            const position = v?.position || "right";
            const pathd = position === "right" ? "M0 0v20L20 0" : "M20 0v20L0 0";
            const id = v?.getId || 0;
            const channelType = v?.getChannelType || "CHAT"; 
            const type = v?.getType || "text";
            const title = v?.lastName || v?.userStatus;
            const avatar = v?.avatar || "https://www.gravatar.com/avatar/?d=mp";
            const text = v?.getText || "";
            const statusTitle = v?.statusMessage || "";
            const status = statusTitle === "waiting" ? "waiting" :
               statusTitle === "succeeded" ? "sent" :
               statusTitle === "received" ? "received" :
               statusTitle === "read" ? "read" :
               "sent";
            const date = v?.getDate || new Date();  
            const interval = new Date() - new Date(date);
            const dateString = new Date(date).toString().replace(/^\w+\s+/, '').replace(/\s*GMT\+\d{4}.*$/, "");
            const sendTimeText = !! interval ? dateString : "just now";    
            /* 圖片訊息 */
            let dataType = v?.getDataType || "";
            let dataName = v?.getDataName || "";
            let dataUri = v?.getDataUri || "";   
            let dataUrl = v?.getDataUrl || ""; 
            // 設定網址格式訊息
            const uriRegex = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/; // Photo URL Regex
            const uriRegexGI = /https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b((?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*))/i;  // Photo URL Regex GI
            const nameRegexGI = /[^\/]+(?=\/?$)/i;  // Photo Name Regex GI (最後一個斜線後的字串)
            const uriExtractArray = text?.match(uriRegexGI) || "";  // uriExtractArray[0]: 完整比對字串(URL); uriExtractArray[1]: 群組一比對字串(Name)
            const uriExtract = uriExtractArray[0] ?? "";
            const nameMatch = uriExtractArray[1] ?. match(nameRegexGI);
            const longName = nameMatch ? nameMatch[0] : "photo-" + new Date();
            const name = longName?.slice(0, 20) || "photo-" + new Date();
            if( !! type && type === "text" ) {
              if( uriRegex.test(text) || !! uriExtract ) { // 若為URI格式
                if( uriExtract.indexOf('youtu.be') > -1 || uriExtract.indexOf('youtube.com') > -1 ) { // Youtube影片格式
                  dataType = "video";
                  // console.log(uriExtract);
                  const videoUrl = new URL(uriExtract);
                  const videoSearch = videoUrl?.search || "";
                  const urlParams = new URLSearchParams(videoSearch);
                  dataName = !! videoSearch ? urlParams.get('v') : longName;
                  dataUrl = "https://www.youtube.com/embed/" + dataName;       
                } else if( uriExtract.indexOf('vimeo.com') > -1 ) { // Vimeo影片格式
                  dataType = "video";
                  dataName = longName;
                  dataUrl = "https://player.vimeo.com/video/" + dataName + "?h=fa5c48d950&title=0&byline=0&portrait=0";             
                } else { // 圖片格式
                  dataType = "photo";
                  dataName = name;
                  dataUrl = uriExtract;
                }
              }           
            }
            const lastElId = textMsgArray[i-1]?.getId || "";
            // console.log('text',text);
            // console.log('lastElText',lastElText);
            // console.log('text === lastElText?',text === lastElText);
            if( id !== lastElId ) {
              // console.log('textMsgArrayClear',textMsgArray);
              clearRedupulicMsg();
              // window.localStorage.setItem('textMsgArray',JSON.stringify(textMsgArray));
              // console.log('window.localStorage.textMsgArrayClear',window.localStorage.getItem('textMsgArray'));
              return (
                <>
                  { !! type && type === "text" ? (
                    !! dataType && dataType === "photo" ? (
                      <div id={id} className={`${channelType} ${position}`}>
                        <MessageBox
                          position = {position}
                          id = {id}
                          type = {dataType}
                          avatar = {avatar}
                          title = {title}
                          data={{
                              uri: dataUrl,
                              width: 240,
                              height: 180
                          }}
                          statusTitle = {'test'}
                          status = {status}
                          date={date}
                        />
                      </div>
                    ) : !! dataType && dataType === "video" && text.indexOf("youtu.be") > -1 || text.indexOf("youtube.com") > -1 ? (
                      // () => {
                      // console.log('youtube');
                      // let urlParams = new URLSearchParams(dataUrl);
                      // const videoName = urlParams.get('v');
                      // const src = "https://www.youtube.com/embed/" + videoName;
                      // return(
                      <div id={id} className={`${channelType} ${position}`}>
                        <div className="rce-container-mbox">
                          <div className={`rce-mbox rce-mbox-${position}`}>
                            <div className="rce-mbox-body">
                              <div className="rce-mbox-title"><span>{title}</span>
                              </div>
                              <div className="rce-mbox-video padding-time">
                                <div className="rce-mbox-video--img" style={{'width': '240px', 'height': '180px'}}>
                                  <iframe 
                                    width="240" 
                                    height="180" 
                                    src = {dataUrl}
                                    title="YouTube video player" 
                                    frameBorder={0}
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                    allowFullScreen>
                                  </iframe>
                                </div>
                              </div>
                              <div class="rce-mbox-time non-copiable" data-text={sendTimeText}><span class="rce-mbox-status"><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path></svg></span></div>
                            </div>
                            <div>
                              <svg className={`rce-mbox-${position}-notch`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <defs>
                                  <filter id="filter1" x="0" y="0">
                                    <feOffset result="offOut" in="SourceAlpha" dx="-2" dy="-5">
                                    </feOffset>
                                    <feGaussianBlur result="blurOut" in="offOut" stdDeviation="3"></feGaussianBlur>
                                    <feBlend in="SourceGraphic" in2="blurOut" mode="normal"></feBlend>
                                  </filter>
                                </defs>
                                <path d={pathd} filter="url(#filter1)"></path>
                              </svg>
                            </div>
                          </div>
                        </div>          
                      {/* <iframe 
                      width="240" 
                      height="180" 
                      src = "https://www.youtube.com/embed/MlTgVR0sXz4"
                      title="YouTube video player" 
                      frameborder="0" 
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                      allowfullscreen></iframe> */}
                      </div>
                    //    );
                    // } 
                    ) : !! dataType && dataType === "video" && uriExtract.indexOf('vimeo.com') > -1 ? (
                      <div id={id} className={`${channelType} ${position}`}>
                        <div className="rce-container-mbox">
                          <div className={`rce-mbox rce-mbox-${position}`}>
                            <div className="rce-mbox-body">
                              <div className="rce-mbox-title"><span>{title}</span>
                              </div>
                              <div className="rce-mbox-video rce-mbox-video--video padding-time">
                                <div className="rce-mbox-video--img" style={{'width': '240px', 'height': '180px'}}>
                                  <iframe 
                                    src={dataUrl} 
                                    width="240" 
                                    height="180" 
                                    frameborder="0" 
                                    allow="autoplay; fullscreen; picture-in-picture" 
                                    allowFullScreen>
                                  </iframe>
                                  {/* <p style={{'display': 'none'}}>
                                    <a href="https://vimeo.com/783453584">The Vandal (Interactive)</a> from 
                                    <a href="https://vimeo.com/eddiealcazar">Eddie Alcazar</a> on 
                                    <a href="https://vimeo.com">Vimeo</a>.
                                  </p> */}
                                </div>
                              </div>
                              <div class="rce-mbox-time non-copiable" data-text={sendTimeText}><span class="rce-mbox-status"><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path></svg></span></div>
                            </div>
                            <div>
                              <svg className={`rce-mbox-${position}-notch`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <defs>
                                  <filter id="filter1" x="0" y="0">
                                    <feOffset result="offOut" in="SourceAlpha" dx="-2" dy="-5">
                                    </feOffset>
                                    <feGaussianBlur result="blurOut" in="offOut" stdDeviation="3"></feGaussianBlur>
                                    <feBlend in="SourceGraphic" in2="blurOut" mode="normal"></feBlend>
                                  </filter>
                                </defs>
                                <path d={pathd} filter="url(#filter1)"></path>
                              </svg>
                            </div>
                          </div>
                        </div>            
                      </div>
                    ) : (
                      <div id={id} className={`${channelType} ${position}`}>
                        <MessageBox
                          position = {position}
                          id = {id}
                          type = {type}
                          title = {title}
                          avatar = {avatar}
                          text= {text}
                          statusTitle = {statusTitle}
                          status = {status}
                          date={date}
                        />
                      </div>
                    )
                  ) : !! type && type === "photo" ? (
                    <div id={id} className={`${channelType} ${position}`}>
                      <MessageBox
                        position = {position}
                        id = {id}
                        type = {type}
                        avatar = {avatar}
                        title = {title}
                        data={{
                            uri: dataUrl,
                            width: 240,
                            height: 180
                        }}
                        statusTitle = {statusTitle}
                        status = {status}
                        date={date}
                      />
                    </div>
                  ) : !! type && type === "video" && (
                    dataType.indexOf('video/') > -1 ||
                    dataName.indexOf('.mp4') > -1 || 
                    dataName.indexOf('.mov') > -1 ||
                    dataName.indexOf('.wmv') > -1 ||
                    dataName.indexOf('.webm') > -1 ||
                    dataName.indexOf('.avi') > -1 ||
                    dataName.indexOf('.flv') > -1 ||
                    dataName.indexOf('.mkv') > -1 ||
                    dataName.indexOf('.mts') > -1
                  ) ? (
                    <div id={id} className={`${channelType} ${position}`}>
                      <MessageBox
                        position={position}
                        id={id}
                        type={"video"}
                        avatar={avatar}
                        title={title}
                        data={{
                            videoURL: dataUrl,
                            width: 240,
                            height: 180,
                            status: {
                              click: true,
                              loading: 0.5,
                              download: true,
                            },
                        }}
                        statusTitle={statusTitle}
                        status={status}
                        date={date}
                      />
                    </div>
                  ) : !! type && type === "audio" && ( 
                    dataType.indexOf('audio/') > -1 ||
                    dataName.indexOf('.wav') > -1 || 
                    dataName.indexOf('.mp3') > -1 || 
                    dataName.indexOf('.m4a') > -1 ||
                    dataName.indexOf('.flac') > -1 ||
                    dataName.indexOf('.wma') > -1 ||
                    dataName.indexOf('.aac') > -1
                  ) ? (
                    <div id={id} className={`${channelType} ${position}`}>
                      <MessageBox
                        className={style.chatRoomContentMessageBoxVideo}
                        position={position}
                        id={id}
                        type={"audio"}
                        avatar={avatar}
                        title={title}
                        data={{
                          audioURL: dataUrl,
                          controlsList: "nofullscreen"
                        }}
                        statusTitle={statusTitle}
                        status={status}
                        date={date}
                      />
                    </div>
                  ) : !! type && type === "file" ? (
                    <div id={id} className={`${channelType} ${position}`}>
                      <MessageBox
                        position={position}
                        id={id}
                        type={type}
                        avatar={avatar}
                        title={title}
                        text={dataName}
                        // text={dataUrl}
                        data={{
                            uri: dataUrl,
                            id: dataName,
                            name: dataName,
                            status: {
                              click: false,
                              loading: 0,
                            },
                        }}
                        onDownload={(e)=>{ 
                          downloadFile(dataUrl, dataName);
                        }}
                        statusTitle={statusTitle}
                        status={status}
                        date={date}
                      />
                    </div>
                  ) : (
                    <div id={id} className={`${channelType} ${position}`}>
                    </div>
                  )}
                </>
              )
            }
          })
          // <MessageList
          //   className='message-list'
          //   lockable={true}
          //   toBottomHeight={'100%'}
          //   dataSource={textMsgArray}
          // />
        ) : (
          <></>
        )}
        {/* <RceText></RceText>
        <RcePhoto></RcePhoto>
        <RceVideo></RceVideo> */}
        {/* <MessageBox
          position="right"
          title="Emre"
          type='text'
          text="Click to join the meeting"
          date={new Date()}
        /> */}
        {/* <MessageList
          className='message-list'
          lockable={true}
          toBottomHeight={'100%'}
          dataSource={textArray}
        /> */}
        {/* <div>
          <p>{textArray.getText}</p>
        </div> */}
      </>
  )
}

export default CustomerChatRoomContent