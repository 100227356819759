import React, { useEffect, useState } from 'react';
import { Layout, Input, InputNumber, Radio, Checkbox, Space, DatePicker, TimePicker, Button, Dropdown, Form, List } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import style from './styles/LineBroadcast.module.css';
import '../styles/LineAnt.css';
import dayjs from 'dayjs';

/*** Import Line Component ***/
import LineCastInterface from "./LineComponents/LineCastInterface"

function LineBroadcast() {
    const { Content } = Layout;
    const [btnLoadings, setBtnLoadings] = useState([]);
    const [sendingTime, setSendingTime] = useState(0);
    const [datePickerOpen, setDatePickerOpen] = useState(false);
    const [showInputNumber, setShowInputNumber] = useState(false);
    const [checkList, setCheckList] = useState([{}]);

    const items = [
        {
            label: '草稿1',
            key: '1',
        },
    ];

    const enterLoading = (index) => {
        setBtnLoadings((prevLoadings) => {
          const newLoadings = [...prevLoadings];
          newLoadings[index] = true;
          return newLoadings;
        });
    
        setTimeout(() => {
            setBtnLoadings((prevLoadings) => {
            const newLoadings = [...prevLoadings];
            newLoadings[index] = false;
            return newLoadings;
          });
        }, 6000);
      };

    const radioOnChange = (e) => {
      console.log('radio checked', e.target.value);
      setSendingTime(e.target.value);
    };
    
    const datePickerOnChange = (time, timeString) => {
        console.log(time, timeString);
        setSendingTime(1);
    };

    const timePickerOnChange = (time, timeString) => {
        console.log(time, timeString);
        setSendingTime(1);
    };

    const checkboxOnChange = (event) => {
        const id = event?.target?.id || "";
        const checked = event?.target?.checked || false;
        const checkElement = { id, checked };
        // console.log(checkElement);
        // console.log(checkList);
        // console.log(checkList[0]);
        // console.log(Object.values(checkList[0]).length > 0);
        if( Object.values(checkList[0]).length > 0 ) { // CheckList 有值，取代或新增check資料
            let checkListArray = new Array();
            let hasSameId = false;
            checkListArray = checkList.map((obj) => {
                // console.log(obj);
                const objId = obj?.id || "";
                // const objChecked = obj?.checked || false;
                // console.log(objId);
                // console.log(objChecked);
                if( objId === id ) {
                    obj.checked = checked; // 取代check資料
                    // console.log(obj.checked);
                    hasSameId = true;
                }
                // console.log(obj);
                return obj;
            });
            // console.log(hasSameId);
            if( ! hasSameId ) { // 未比對到同ID的物件，新增check資料
                setCheckList((current) => [...current, checkElement]);
            } else {  // 有比對到同ID的物件，取代原CheckList
                // console.log(checkListArray);
                setCheckList(checkListArray);
            }
        } else { // 無值則新增check資料
            let checkListArray = new Array;
            checkListArray.push(checkElement);
            setCheckList(checkListArray);
        }
        // const santiCheckList = [...new Set(checkList.map(itm => JSON.stringify(itm)))].map(i => JSON.parse(i));
        // console.log(santiCheckList);
        let msgLimitIsChecked = false;
        for( let el of checkList ) { // msgLimit前次設定的值
            if( Object.values(el).includes("msgLimit") ) {
                msgLimitIsChecked = el?.checked || false;
            }
        }
        if( ( id === "msgLimit" && checked ) || msgLimitIsChecked ) { // msgLimit事件觸發設定的值或前次設定的值
            setShowInputNumber(true);
        } else {
            setShowInputNumber(false);
        }
    };
    useEffect(() => {
        console.log(checkList);
    }, [checkList]);

    const msgLimitOnChange = (value) => {
        console.log(value);
    }
  
    return (
        <Form id={"LineBroadcast"} className={style.lineBroadcastForm} onFinish={()=>{}}>
            <h4 className={style.lineBroadcastH4}>廣播模式（Broadcast）</h4>
            <Form.Item className={style.lineBroadcastFormItem} name="button">
                <Space className={style.lineBroadcastSpaceSendBtn}>
                    <Dropdown.Button
                        className={style.lineBroadcastSendBtn}
                        type="primary"
                        icon={<DownOutlined/>}
                        // loading={btnLoadings[0]}
                        menu={{ items }}
                        placement="bottom"
                        onClick={() => enterLoading(0)}
                    >
                        儲存草稿
                    </Dropdown.Button>                    
                    <Button className={style.lineBroadcastSendBtn} type="primary" 
                        // loading={btnLoadings[1]} 
                        onClick={() => enterLoading(1)}>
                        傳送測試訊息
                    </Button>
                </Space>
            </Form.Item>
            <Form.Item className={style.lineBroadcastFormItem} name="sendingTime" rules={[{ required: true, message: `【傳送時間】為必填欄位！` }]}>
                <Radio.Group className={style.lineBroadcastRadioGroup} onChange={radioOnChange} value={sendingTime} defaultValue={0}>
                    <h6 className={style.lineBroadcastH6}>傳送時間</h6>
                    <div className={style.lineBroadcastDivTime}>
                        <Space className={style.lineBroadcastSpaceTime} direction="vertical">
                            <Radio className={style.lineBroadcastRadio} value={0}><span className={style.lineBroadcastRadioText}>立即傳送</span></Radio>
                            <Radio className={`${style.lineBroadcastRadio} ${style.lineBroadcastRadioSchedule}`} value={1}>
                                <span className={style.lineBroadcastRadioText}>預約傳送</span>
                                <div className={`${style.lineBroadcastRadioPicker}`}>
                                    <DatePicker 
                                        className={`${style.lineBroadcastRadioDatePicker}`} 
                                        placeholder={"選擇傳送日期"}
                                        defaultValue={dayjs(new Date())}
                                        onChange={datePickerOnChange}
                                        suffixIcon={null}
                                    />
                                    <TimePicker 
                                        className={`${style.lineBroadcastRadioTimePicker}`} 
                                        placeholder={"選擇傳送時間"}
                                        defaultValue={dayjs(new Date()).add(5, "minute")}
                                        onChange={timePickerOnChange}
                                        suffixIcon={null}
                                    />
                                </div>
                            </Radio>
                        </Space>
                    </div>
                </Radio.Group>
            </Form.Item>
            <Form.Item className={`${style.lineBroadcastFormItem} ${style.lineBroadcastFormItemAdvanceSetting}`} name="advance">
                <h6 className={style.lineBroadcastH6}>進階設定</h6>
                <Space className={style.lineBroadcastSpaceAdvanceSetting} direction="vertical">
                    <Checkbox className={style.lineBroadcastSpaceCheckBox} id={"lineVoom"} onChange={checkboxOnChange}>張貼至LINE VOOM</Checkbox>
                    <Checkbox className={style.lineBroadcastSpaceCheckBox} id={"msgLimit"} onChange={checkboxOnChange}>指定群發訊息則數的上限</Checkbox>
                        { showInputNumber ? (
                            <InputNumber className={style.lineBroadcastSpaceCheckBoxInputNumber} min={1} max={500000} defaultValue={50000} onChange={msgLimitOnChange} />
                        ) : (
                            <></>
                        )}
                    <Checkbox className={style.lineBroadcastSpaceCheckBox} id={"ABTesting"} onChange={checkboxOnChange}>建立A/B測試</Checkbox>
                    <Checkbox className={style.lineBroadcastSpaceCheckBox} id={"Advertise"} onChange={checkboxOnChange}>設定廣告活動</Checkbox>
                </Space>
            </Form.Item>
            <Form.Item className={`${style.lineBroadcastFormItem} ${style.lineBroadcastFormItemLineCastInterface}`} name="interface" rules={[{ required: true, message: `【訊息輸入欄位】為必填欄位！` }]}>
                <Space className={style.lineBroadcastSpaceLineCastInterface} >
                    <LineCastInterface></LineCastInterface>
                </Space>
            </Form.Item>
        </Form>
    );
}

export default LineBroadcast