import React, {useState, useContext, useEffect, useRef} from 'react'
import style from './styles/UserDetail.module.css';
import './styles/UserDetail.css';
import axios from 'axios';

import { Button, ChatList } from "react-chat-elements"

import { Col, Row, Tag, Input, InputRef, theme, Space, Table, Button as AntButton } from 'antd';
import { DeleteOutlined, DeleteFilled, PlusOutlined } from '@ant-design/icons';
import { TweenOneGroup } from 'rc-tween-one';

/*** Import Context Parameters ***/
import { GlobalAdminSocket } from '../../../../../SocketIO/Admin/SocketAdminConnect';
import { GlobalRoomId } from '../ChatRoom';
import { GlobalJoinRoom } from '../ChatRoom';
import { GlobalHasJoinedRoom } from '../ChatRoom';
import { GlobalUserInfo } from '../ChatRoom';
import { indexOf } from 'lodash';

function UserDetail() {
  const { CheckableTag } = Tag;
  const [globalAdminSocket , setGlobalAdminSocket ] = useContext(GlobalAdminSocket);
  const [globalRoomId , setGlobalRoomId] = useContext(GlobalRoomId);
  const [globalJoinRoom , setGlobalJoinRoom] = useContext(GlobalJoinRoom);  
  const [globalHasJoinedRoom , setGlobalHasJoinedRoom] = useContext(GlobalHasJoinedRoom);  
  const [globalUserInfo, setGlobalUserInfo] = useContext(GlobalUserInfo);

  const [globalAdminUserSocket, setGlobalAdminUserSocket] = useState( globalAdminSocket?.globalAdminUserSocket || {} ); // 初始值設定正確才能及時觸發主動連線
  const [globalAdminPanelSocket, setGlobalAdminPanelSocket] = useState( globalAdminSocket?.globalAdminPanelSocket || {} );
  // const [userInfo, setUserInfo] = useState({}); // 使用者資訊
  const [userInfoLoading, setUserInfoLoading] = useState({}); // 使用者資訊載入完畢與否
  const [userInfoChannels, setUserInfoChannels] = useState([]); // 使用者資訊
  const [userInfoLines, setUserInfoLines] = useState([]); // 使用者LINE資訊
  const [userInfoFbs, setUserInfoFbs] = useState([]); // 使用者FB資訊
  const [userInfoEmails, setUserInfoEmails] = useState([]); // 使用者FB資訊
  // const [userInfoTags, setUserInfoTags] = useState([{_id: "001", name:"testTag1"}, {_id: "002", name: "testTag2"}, {_id: "003", name: "testTag3"}]); // 使用者Tag資訊
  const [userInfoTags, setUserInfoTags] = useState([]); // 使用者Tag資訊
  const [userInfoTagsIdDeleted, setUserInfoTagsIdDeleted] = useState(""); // 若state包含物件，則useEffect會失效，必須取得obj內的value另外設一個State
  const [settingTags, setSettingTags] = useState([]); // 可設定Tag資訊
  const [selectedTagsId, setSelectedTagsId] = useState([]); // 選中列表or使用者列表
  const [recentSelectedTagsId, setRecentSelectedTagsId] = useState([]); // 最近選中列表or可刪除的使用者列表
  const [hasShowRecentRemoveTags, setHasShowRecentRemoveTags] = useState(false); // 是否點擊"最近移除的標籤"
  const [searchString, setSearchString] = useState(""); // 搜尋欄輸入的字串
  const [tagsDataArray, setTagsDataArray] = useState([{}]); // 各標籤資料
  const apiURL = process.env.REACT_APP_APIURL; // API URL
  useEffect(()=>{console.log(hasShowRecentRemoveTags)},[hasShowRecentRemoveTags]);
  /*** 初始化/重置 globalAdminUserSocket 及 globalAdminPanelSocket ***/
  const initializeAdminUserAndPanelSocket = async() => {
    let adminUserSocket = null;
    if ( typeof globalAdminSocket === 'object' && globalAdminSocket !== null && Object.keys(globalAdminSocket).length > 0 ) {
        adminUserSocket = globalAdminSocket?.globalAdminUserSocket || null;
        setGlobalAdminUserSocket(adminUserSocket); // 最後改動此處，讓GlobalAdminSocket連線後設定GlobalAdminUserSocket
    }
    let adminPanelSocket = null;
    if ( typeof globalAdminSocket === 'object' && globalAdminSocket !== null && Object.keys(globalAdminSocket).length > 0 ) {
        adminPanelSocket = globalAdminSocket?.globalAdminPanelSocket || null;
        setGlobalAdminPanelSocket(adminPanelSocket);
    }
  }
  useEffect(() => {
      initializeAdminUserAndPanelSocket();
  }, []);
  useEffect(() => {
      initializeAdminUserAndPanelSocket();
  }, [globalAdminSocket, globalRoomId]);  
  useEffect(() => {
      console.log('globalAdminUserSocket', globalAdminUserSocket)
      console.log('globalAdminPanelSocket', globalAdminPanelSocket)
  }, [globalAdminUserSocket, globalAdminPanelSocket]);
  /*** END 初始化/重置 globalAdminUserSocket 及 globalAdminPanelSocket ***/  

  /*** 處理使用者列表資料 ***/
  const handleUserInfoData = async(data) => {
    console.log({data});
    /* userInfoData = {
        "roomId": "string",
        "userId": "string",
        "userName": "string",
        "channelIds": {
          "line": "string",
          "chat": "string",
          "fb": "string",
          "mail": "string"
        },
        "line": {
          "id": "string",
          "name": "string",
          "pictureUrl": "string",
          "language": "string",
          "statusMessage": "string"
        },
        "fb": {
          "id": "string"
        },
        "tags": [
          {
            "_id": "string",
            "name": "string",
            "category": "string",
            "description": "string",
            "priority": 0
          }
        ]
      }   
    */
    const roomId = data?.roomId || "";
    const userId = data?.userId || "";
    const userName = data?.userName || "";
    const channels = data?.channelIds || {};
    const channelEntries = Object.entries(channels).length > 0 ? Object.entries(channels) : [];
    const channelChatId = channels?.CHAT || "";
    const channelLineId = channels?.LINE || "";
    const channelFbId = channels?.FB || "";
    const channelEmailId = channels?.EMAIL || "";
    let lineName, linePictureUrl, lineLanguage, lineStatusMessage;
    lineName = lineLanguage = lineStatusMessage = "";
    linePictureUrl = "https://www.gravatar.com/avatar/?d=mp";
    let lineEntries, fbEntries, emailEntries;
    lineEntries = fbEntries = emailEntries = "";
    if( channelEntries.length > 0 ) {
      console.log(channelEntries.length);
      console.log(channelEntries);
      for( let el of channelEntries ) {
        console.log(el);
        switch( el[0] ) {
          case "LINE":
            console.log(el[0]);
            lineName = data?.line?.name || "";
            linePictureUrl = data?.line?.pictureUrl || "https://www.gravatar.com/avatar/?d=mp";
            lineLanguage = data?.line?.language || "";
            lineStatusMessage = data?.line?.statusMessage || "";
            const lineObj = {channelLineId, lineName, linePictureUrl, lineLanguage, lineStatusMessage};
            console.log(lineObj);
            lineEntries = Object.entries(lineObj).length > 0 ? Object.entries(lineObj) : [];
            setUserInfoLines(lineEntries);
            break;
          case "FB":
            console.log(el[0]);
            const fbObj = {channelFbId};
            fbEntries = Object.entries(fbObj).length > 0 ? Object.entries(fbObj) : [];
            setUserInfoFbs(fbEntries);
            break;
          case "EMAIL":
            console.log(el[0]);
            const emailObj = {channelEmailId};
            emailEntries = Object.entries(emailObj).length > 0 ? Object.entries(emailObj) : [];
            setUserInfoEmails(emailEntries);
            break;
          default:
            console.log(el[0]);
            break;
        }
      }
    }
    const tags = data?.tags || [];
    // const tagEntries = Object.entries(tags).length > 0 ? Object.entries(tags) : [];
    // const tagId = tags?._id || "";
    // const tagName = tags?.name || "";
    // const tagCategory = tags?.category || "";
    // const tagDescription = tags?.description || "";
    // const tagPriority = tags?.priority || 0;
    const userInfoData = {
      roomId,
      userId,
      userName,
      channels,
      channelEntries,
      channelChatId,
      channelLineId,
      channelFbId,
      channelEmailId,
      lineEntries,
      lineName,
      linePictureUrl,
      lineLanguage,
      lineStatusMessage,
      fbEntries,
      emailEntries,
      tags,
      // tagEntries,
      // tagId,
      // tagName,
      // tagCategory,
      // tagDescription,
      // tagPriority
    }
    setGlobalUserInfo(userInfoData);
    setUserInfoChannels(channelEntries);
    setUserInfoTags(tags);
    setUserInfoLoading(false);
    console.log('userInfoData', userInfoData);
  } /*** END 處理使用者列表資料 ***/
  useEffect(() => {
    console.log('globalUserInfo', globalUserInfo);
  }, [globalUserInfo]);
  useEffect(() => {
    console.log('userInfoChannels', userInfoChannels);
  }, [userInfoChannels]);
  useEffect(() => {
    console.log('userInfoTags', userInfoTags);
  }, [JSON.stringify(userInfoTags)]);

  // /*** 請求使用者資料 ***/
  // const emitUserInfo = async() => {
  //   const globalAdminPanelSocketConnected = globalAdminPanelSocket?.connected || false;
  //   console.log(globalAdminPanelSocketConnected);
  //   console.log(globalRoomId);
  //   if( globalAdminPanelSocketConnected && !! globalRoomId ) {
  //     globalAdminPanelSocket.emit('userInfo', {roomId: globalRoomId});
  //     console.log('User Detail Emit: Administer Get User Info !');
  //   } else if( ! globalAdminPanelSocketConnected ) {
  //     console.log("User Detail: GlobalAdminPanelSocket not Connected !");
  //   } else {
  //     console.log("User Detail: GlobalRoomId not Exist!");
  //   } 
  // } 
  // useEffect(() =>{
  //   emitUserInfo();
  // }, [globalRoomId]);
  // /*** END 請求使用者資料 ***/

  /*** Socket監聽Panel Socket訊息事件 ***/
  const listenPanelSocketMessageEvent = async() => {
    console.log("User Detail: listenPanelSocketMessageEvent"); 
    console.log("User Detail: Has Room ID ?", globalRoomId);
    console.log("User Detail: Has Joined Room ?", globalHasJoinedRoom);
    const globalAdminPanelSocketConnected = globalAdminPanelSocket?.connected || false;
    if( globalAdminPanelSocketConnected ) {
        console.log("User Detail: globalAdminPanelSocketConnected is true", globalAdminPanelSocketConnected); 
        console.log(globalAdminPanelSocket);
        globalAdminPanelSocket.on("userInfo", (data) => {
          console.log(data);
          handleUserInfoData(data);
        });
        globalAdminPanelSocket.on("error", (data) => {
            console.log(data, 'User Detail : get userInfo Error !');
        });
    } else {
      console.log('User Detail : GlobalAdmin【Panel】SocketConnected not Connected !');
    }
  }
  useEffect(() => {
    listenPanelSocketMessageEvent();
  }, []);
  // useEffect(() => {
  //   listenPanelSocketMessageEvent();
  // }, [globalRoomId]);  
  /*** END Socket監聽Panel Socket訊息事件 ***/

  // useEffect(()=>{
  //   console.log(globalHasJoinedRoom);
  // }, [globalHasJoinedRoom]);

  /*** 取得標籤設定列表 ***/
  const getTagsSettingList = (e, urlArg) => {
    if( !! e ) {
      e.preventDefault();
    } 
    console.log('getTagsSettingList');
    console.log(urlArg);
    const apiUrl = process.env.REACT_APP_APIURL;
    const url = !! urlArg ? urlArg : apiUrl + "/api/message/v1/chat-tags" + '?limit=50&offset=0';
    console.log(url);
    // const urlSearchParams = new URLSearchParams({
    //   filter: searchString,
    //   // limit: numberPerPage,
    //   // offset: currentPage-1
    // });
    // const url = urlSearchParams.has("filter") ? apiUrl + "/api/message/v1/chat-tags?" + urlSearchParams : apiUrl + "/api/message/v1/chat-tags" + '?limit=50&offset=0';
    // const params = {
    //   limit: 50,
    //   offset: 0,
    // };
    // console.log({apiUrl, url, params});
    // console.log(axios);
    // axios({
    //   method: 'get',
    //   url,
    //   headers: {
    //     'Accept': 'application/json'
    //   },
    //   params
    // })
    // .then((res) => {
    //   console.log(res);
    // })
    // .catch((err) => {
    //   console.log("GetTagsSettingList error: ", err);
    // });
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
    })
    .then((r) => r.json())
    .then((result) => {
      console.log(result);
      /*
        [
          {
            "_id": "64547fd77af19c5afde1c548",
            "name": "string",
            "category": "default",
            "description": " ",
            "priority": 0,
            "__v": 0
          }
        ]
      */
      if( Array.isArray(result) && result.length > 0 ) {
        let resultExist = false;
        for( let v of result ) {
          const settingTagId = v?._id || "";
          if( !!settingTagId ) {
            resultExist = true;
            break;
          }
        }
        console.log(resultExist);
        if( resultExist ) {
          setSettingTags(result);
          setHasShowRecentRemoveTags(false);
        }
      }
    })
    .catch((error) => {
      console.log('error',error);
    });                             
  }
  useEffect(() => {
    getTagsSettingList();
  }, []); 
  useEffect(() => {
    console.log(settingTags);
  }, [settingTags]); 
  /*** END 取得標籤設定列表 ***/

  /*** 監聽新增及移除Tag event ***/
  const listeningAddRemoveUserTagsEvent = () => {
    const globalAdminPanelSocketConnected = globalAdminPanelSocket?.connected || false;
    if( globalAdminPanelSocketConnected ) {
      globalAdminPanelSocket.on('addUserTags', (data) => {
        console.log(data, 'cs addUserTags ');
        // handleAddTag(triggerTagId, checked); // 目前無法判斷是否真的新增成功(無回傳值)
      });
      globalAdminPanelSocket.on('removeUserTags', (data) => {
        console.log(data, 'cs addUserTags ');
        // handleCloseTag(triggerTagId); // 目前無法判斷是否真的刪成功(無回傳值)
      });
      globalAdminPanelSocket.on('error', (data) => {
        console.error(data, 'UserDetail addUserTags or removeUserTags error');
      });
    } else {
      console.log('User Detail : GlobalAdmin【Panel】SocketConnected not Connected !');
    }
  }
  useEffect(() => {listeningAddRemoveUserTagsEvent()}, []);
  /*** END 監聽新增及移除Tag event ***/

  /*** 新增Tag ***/
  const addUserTags = (triggerTagId, checked) => {
    const globalAdminPanelSocketConnected = globalAdminPanelSocket?.connected || false;
    if( globalAdminPanelSocketConnected ) {
      globalAdminPanelSocket.emit('addUserTags', {
          tagIds: [triggerTagId],
      });
      handleAddTag(triggerTagId, checked); // 目前無法判斷是否真的新增成功(無回傳值)
      console.log('User Detail : AddUserTags Emit !');
    } else {
      console.log('User Detail : GlobalAdmin【Panel】SocketConnected not Connected !');
    }
  }/*** END 新增Tag ***/

  /*** 控制新增標籤時選中標籤列表 ***/
  const handleAddTag = (tagId, checked) => {
    let userInfoTagsId = []; // 已設定之使用者tag列表
    if( Object.values(userInfoTags).length > 0 ) {
      for( let el of userInfoTags ) {
        const userInfoTagId = el?._id || "";
        console.log(userInfoTagId);
        if( !! userInfoTagId ) {
          userInfoTagsId.push(userInfoTagId);
        }
      }
    }
    console.log(userInfoTags);
    console.log(userInfoTagsId);
    let nextSelectedTagsId = []; // 選中tag列表
    if( !! tagId && typeof(checked) === typeof(true) ) {
      nextSelectedTagsId = checked // 選擇或取消選擇
      ? [...selectedTagsId, tagId] // 選中的tagId加入列表
      : selectedTagsId.filter((tagIdEl) => tagIdEl !== tagId); // 把列表中非tagId的項目篩選出來
    }
    console.log(selectedTagsId);
    const unionArray = userInfoTagsId.concat(nextSelectedTagsId.filter((el) => { // 取userInfoTagsId與nextSelectedTagsId聯集
      return userInfoTagsId.indexOf(el) === -1
    }));
    console.log(unionArray);
    console.log('You are interested in: ', nextSelectedTagsId);
    setSelectedTagsId(unionArray); // 改變tags選中列表
  };
  useEffect(() => {
    handleAddTag();
    console.log( selectedTagsId );
  }, [userInfoTags]);
  useEffect(() => {
    console.log( selectedTagsId );
  }, [selectedTagsId]);
  /*** END 控制新增標籤時選中標籤列表 ***/

  /*** 刪除Tag ***/
  const removeUserTag = (triggerTagId) => {
    const globalAdminPanelSocketConnected = globalAdminPanelSocket?.connected || false;
    if( globalAdminPanelSocketConnected ) {
      globalAdminPanelSocket.emit('removeUserTag', {
          tagId: triggerTagId,
      });
      console.log('User Detail : removeUserTag Emit !');
      handleCloseTag(triggerTagId); // 目前無法判斷是否真的刪成功(無回傳值)
    } else {
      console.log('User Detail : GlobalAdmin【Panel】SocketConnected not Connected !');
      alert("刪除失敗（與伺服器連線中斷）！")
    }
  }
  /*** END 刪除Tag ***/

  /*** 清除使用者標籤，並重新顯示於可設定標籤列表 ***/
  const handleCloseTag = (tagId) => {
    let userInfoTagsTemp = userInfoTags;
    let recentSelectedTagsIdStr = window.localStorage.getItem("rstia") ?? "";
    let recentSelectedTagsIdArr = !! recentSelectedTagsIdStr ? JSON.parse(recentSelectedTagsIdStr) : [];
    // console.log(userInfoTagsTemp);
    if( Object.values(userInfoTags).length > 0 ) {
      for( let el of userInfoTags ) {
        console.log(el);
        if( Object.values(el).includes(tagId) ) {
          // if( userInfoTags.indexOf(el) > -1 ) {
          setUserInfoTagsIdDeleted(tagId);
          userInfoTagsTemp.splice(userInfoTags.indexOf(el), 1); // 清除user info tags array 中的一個tag物件
          setUserInfoTags(userInfoTagsTemp);
          let nextSelectedTagsId = []; // 選中tag列表
          // if( !! tagId ) {
          recentSelectedTagsIdArr.push(el); // 最新刪除的標籤加入新刪除標籤列表
          recentSelectedTagsIdStr = JSON.stringify(recentSelectedTagsIdArr);
          window.localStorage.setItem("rstia", recentSelectedTagsIdStr);
          nextSelectedTagsId = selectedTagsId.filter((tagIdEl) => tagIdEl !== tagId); // 把選中列表中非tagId的項目篩選出來(非刪除的項目放入選中列表，刪除的項目由選中列表中移除，移除的項目會顯示在設定標籤列表)
          // }
          setSelectedTagsId(nextSelectedTagsId); // 改變已選中tags列表(在設定標籤列表中隱藏)
          // console.log(userInfoTagsTemp);
          // }
        }
      }
    }
  };
  // useEffect(() => {
  //   console.log(userInfoTags)
  // }, [userInfoTagsIdDeleted]);
  // useEffect(() => {
  //   let recentSelectedTagsIdStr = window.localStorage.getItem("rstia") ?? "";
  //   console.log(recentSelectedTagsIdStr);
  //   let recentSelectedTagsIdArr = !! recentSelectedTagsIdStr ? JSON.parse(recentSelectedTagsIdStr) : [];
  //   console.log(recentSelectedTagsIdArr);
  // }, [window.localStorage.getItem("rstia")]);
  /*** END 清除使用者標籤，並重新顯示於可設定標籤列表 ***/

  /*** 顯示最近移除的標籤 ***/
  const showRecentRemoveTags = () => {
    let recentSelectedTagsIdStr = window.localStorage.getItem("rstia") ?? "";
    let recentSelectedTagsIdArr = !! recentSelectedTagsIdStr ? JSON.parse(recentSelectedTagsIdStr) : [];
    let newSettingTags = [];
    for( let el1 of settingTags ) {
      const Id1 = el1?._id || "";
      for( let el2 of recentSelectedTagsIdArr ) {
        const Id2 = el2?._id || "";
        if( Id1 === Id2 ) {
          newSettingTags.push(el1);
        }
      }
    }
    const newSettingTagsSanti = [...new Set(newSettingTags.map(itm => JSON.stringify(itm)))].map(i => JSON.parse(i));
    setSettingTags(newSettingTagsSanti);
    setHasShowRecentRemoveTags(true);
  }
  /*** END 顯示最近移除的標籤 ***/

  const tagsForMap = (tagObj) => {
    const tagId = tagObj?._id || "";
    const tagName = tagObj?.name || "";
    const tagElem = (
      <Tag
        className={style.userDetailTags}
        color="geekblue"
        closable
        onClose={(e) => {
          e.preventDefault();
          handleCloseTag(tagId);
        }}
      >
        {tagName}
      </Tag>
    );
    return (
      <span key={tagId} style={{ display: 'inline-block' }}>
        {tagElem}
      </span>    
    );
  };

  // const columns = [
  //   {
  //     title: '可設定標籤',
  //     dataIndex: 'name',
  //     filters: [
  //       {
  //         text: 'test2',
  //         value: 'test2',
  //       },
  //       {
  //         text: '測試標籤1',
  //         value: '測試標籤1',
  //       },
  //       {
  //         text: '測試標籤2',
  //         value: '測試標籤2',
  //       },
  //     ],
  //     // filteredValue: filteredInfo.name || null,
  //     filterSearch: true,
  //     onFilter: (value, record) => record.name.startsWith(value),
  //     width: '40%',
  //   }];

  /*** 搜尋 ***/
  const { Search } = Input;
  const searchOnChange = (event) => {
    const val = event?.target?.value || "";
    setSearchString(val);
  }
  const onSearch = () => {
    // console.log(currentPage);
    console.log(new URLSearchParams({
      filter: searchString,
    }));
    const url = apiURL + "/api/message/v1/chat-tags?filter=" + searchString;
    const e = undefined;
    getTagsSettingList(e, url);
    // requestTags();
    // setIsEditMode([-1, false]); // 返回非編輯模式
  }

  // /*** 查詢Tags ***/
  // const requestTags = async() => {
  //   const url = apiURL + "/api/message/v1/chat-tags?" + new URLSearchParams({
  //     filter: searchString,
  //     // limit: numberPerPage,
  //     // offset: currentPage-1
  //   });
  //   // console.log(url);
  //   // console.log(searchString);
  //   // console.log(numberPerPage);
  //   // console.log(currentPage);
  //   const response = await fetch(
  //     url,
  //     {
  //       headers: {
  //         Accept: "application/json",
  //       },
  //     }
  //   )
  //   .then((res) => res.json())
  //   .catch((err) => {
  //     alert(`標籤查詢失敗！\n${err}`);
  //     throw new Error("RequestTags failed");
  //   });
  //   console.log(response);
  //   setTagsDataArray(response); 
  //   // setUpdateFieldsOnChangeState(response); 
  //   // try {
  //   //   const response = await axios.get({
  //   //     method: 'get',
  //   //     url: url,
  //   //     responseType: 'json',
  //   //   });
  //   //   console.log(response);
  //   // } catch (error) {
  //   //   console.error(error);
  //   // }
  // }
  // useEffect(() => {
  //   requestTags();
  // }, []);
  // useEffect(() => {
  //   console.log(tagsDataArray);
  // }, [tagsDataArray]);  

  return (
    <>
      <Row>
        <Col>
          <section className={`${style.userDetailSec} ${style.userDetailTitleSec}`}>
            <h6 className={style.userDetailH6}>使用者資訊</h6>
          </section>
          <section className={style.userDetailSec}>
            <div className={`${style.userDetailDiv} ${style.userDetailUserNumberDiv}`}>
              <div className={style.userDetailTitle}><h6>　使用者編號：</h6><span>{ globalUserInfo?.roomId || "" }</span></div>
            </div>
            <div className={`${style.userDetailDiv} ${style.userDetailDivAvatar}`}>
                <img className={style.userDetailAvatarImg} src={globalUserInfo?.linePictureUrl || "https://www.gravatar.com/avatar/?d=mp"}></img>
            </div>
            <div className={style.userDetailDiv}>
              <div className={style.userDetailTitle}><h6>{ globalUserInfo?.userName || "" }</h6></div>
              <div className={`${style.userDetailSubTitle} ${style.userDetailwpId}`}><span>（Wordpress ID：</span><span>{globalUserInfo?.userId||""}</span><span>）</span></div>
            </div>
            <div className={`${style.userDetailDiv} ${style.userDetailDivChannel}`}>
              <div className={style.userDetailTitle}><h6>　使用頻道：</h6></div>
              <div className={`${style.userDetailChannelContainer}`}>
                { userInfoChannels.length > 0 ? (
                  userInfoChannels.map((v, i) => {
                    let channelName = "";
                    let channelStyle = "";
                    switch(v[0]) {
                      case "CHAT":
                        channelName = "聊天室";
                        channelStyle = "userDetailChannelChat";
                        break;
                      case "LINE":
                        channelName = "LINE";
                        channelStyle = "userDetailChannelLine";
                        break;
                      case "FB":
                        channelName = "Facebook";
                        channelStyle = "userDetailChannelFb";
                        break;
                      case "EMAIL":
                        channelName = "Email";
                        channelStyle = "userDetailChannelEmail";
                        break;
                    }
                    return (
                      <div className={`${style.userDetailChannel} ${channelStyle}`}>
                        <div className={`${style.userDetailSubTitle} ${style.userDetailChannelName}`}>{channelName}</div>
                        {/* <div><span className={style.userDetailChannelId} id={`CHANNEL-ID-${v[0]}`}>{v[1]}</span></div> */}
                      </div>
                    ) 
                  })
                ) : (
                  <></>
                )}
              </div>
            </div>
            { userInfoChannels.length > 0 ? (
              <div className={`${style.userDetailDiv} ${style.userDetailDivChannel}`}>
                <div className={`${style.userDetailTitle} ${style.userDetailTitleChannel}`}><h6>　頻道資訊：</h6></div>
                <div className={`${style.userDetailChannelDetailContainer}`}>
                  {userInfoChannels.map((v, i) => {
                    let channelName = "";
                    let channelStyle = "";
                    switch(v[0]) {
                      case "CHAT":
                        channelName = "聊天室";
                        channelStyle = "userDetailChannelDetailChat";
                        break;
                      case "LINE":
                        channelName = "LINE";
                        channelStyle = "userDetailChannelDetailLine";
                        break;
                      case "FB":
                        channelName = "Facebook";
                        channelStyle = "userDetailChannelDetailFb";
                        break;
                      case "EMAIL":
                        channelName = "Email";
                        channelStyle = "userDetailChannelDetailEmail";
                        break;
                    }
                    return (
                      v[0].indexOf('LINE') > -1 ? (
                        <div className={`${style.userDetailChannelDetail} ${style.userDetailDivLine} ${channelStyle}`}>
                          <div className={`${style.userDetailSubTitle} ${style.userDetailSubTitleName}`}><span>{channelName}</span></div>
                          {/* <div className={`${style.userDetailSubTitle}`}>頻道ID：<span className={style.userDetailChannelId} id={`CHANNEL-ID-${v[0]}`}>{v[1]}</span></div> */}
                          <div className={`${style.userDetailSubTitle}`}>LINE ID：<span>{globalUserInfo?.channelLineId || ""}</span></div>
                          <div className={`${style.userDetailSubTitle}`}>LINE顯示名稱：<span>{globalUserInfo?.lineName || ""}</span></div>
                          <div className={`${style.userDetailSubTitle}`}>LINE狀態資訊：<span>{globalUserInfo?.lineStatusMessage || ""}</span></div>
                          <div className={`${style.userDetailSubTitle}`}>LINE預設語言：<span>{globalUserInfo?.lineLanguage || ""}</span></div>
                        </div>
                      ) : v[0].indexOf('FB') > -1 ? (
                        <div className={`${style.userDetailChannelDetail} ${style.userDetailDivFb} ${channelStyle}`}>
                          <div className={`${style.userDetailSubTitle} ${style.userDetailSubTitleName}`}><span>{channelName}</span></div>
                          {/* <div className={`${style.userDetailSubTitle}`}>頻道ID：<span className={style.userDetailChannelId} id={`CHANNEL-ID-${v[0]}`}>{v[1]}</span></div> */}
                          <div className={`${style.userDetailSubTitle}`}>Facebook ID：<span>{globalUserInfo?.channelFbId || ""}</span></div>
                        </div>
                      ) : v[0].indexOf('EMAIL') > -1 ? (
                        <div className={`${style.userDetailChannelDetail} ${style.userDetailDivEmail} ${channelStyle}`}>
                          <div className={`${style.userDetailSubTitle} ${style.userDetailSubTitleName}`}><span>{channelName}</span></div>
                          {/* <div className={`${style.userDetailSubTitle}`}>頻道ID：<span className={style.userDetailChannelId} id={`CHANNEL-ID-${v[0]}`}>{v[1]}</span></div> */}
                          <div className={`${style.userDetailSubTitle}`}>Email：<span>{globalUserInfo?.channelEmailId || ""}</span></div>
                        </div>
                      ) : (
                        // <div className={`${style.userDetailChannelDetail} ${style.userDetailDivChat} ${channelStyle}`}>
                        //   <div className={`${style.userDetailSubTitle} ${style.userDetailSubTitleName}`}><span>{channelName}</span></div>
                        //   <div className={`${style.userDetailSubTitle}`}>頻道ID：<span className={style.userDetailChannelId} id={`CHANNEL-ID-${v[0]}`}>{v[1]}</span></div>
                        // </div>
                        <></>
                      )
                    )
                  })}        
                </div>
              </div>
            ) : (
              <></>
            )}
            <div className={`${style.userDetailDiv} ${style.userDetailDivTag}`}>
              <div className={`${style.userDetailTitle} ${style.userDetailTitleTag}`}><h6>　使用者標籤：</h6></div>
              <div className={`${style.userDetailTagContainer}`}>
                { userInfoTags.length > 0 ? (
                  userInfoTags.map((v, i) => {
                    let tagId = v?._id || "";
                    let tagName = v?.name || "";
                    // if ( !! v?.name && v?.name !== "string" ) {
                    //   tagName = v.name;
                    // }
                    return (
                      <>
                        {/* <div>{JSON.stringify(userInfoTags)}</div>
                        <div className={style.userDetailTag}>
                          <div className={`${style.userDetailSubTitle}`}> 
                            <span>{tagName}</span> 
                            <DeleteOutlined className={style.userDetailDeleteIcon} onClick={() => { removeUserTag(tagId) }}/> 
                            <DeleteFilled className={style.userDetailDeleteIcon} onClick={() => { removeUserTag(tagId) }}/> 
                          </div> 
                        </div> */}
                        <TweenOneGroup
                          enter={{
                            scale: 0.8,
                            opacity: 0,
                            type: 'from',
                            duration: 100,
                          }}
                          onEnd={(e) => {
                            if (e.type === 'appear' || e.type === 'enter') {
                              e.target.style = 'display: inline-block';
                            }
                          }}
                          leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
                          appear={false}
                        >
                          {/* {userInfoTags.map(tagsForMap)} */}
                          <span  style={{ display: 'inline-block' }}>
                            <Tag
                              className={style.userDetailTags}
                              color="geekblue"
                              closable
                              onClose={(e) => {
                                e.preventDefault();
                                removeUserTag(tagId);
                              }}
                            >
                              {tagName}
                            </Tag>
                          </span>  
                          {/* {tagName} */}
                        </TweenOneGroup>

                        {/* <div className={`${style.userDetailSubTitle}`}><span>ID：</span><span>{tagId}</span></div> */}
                        {/* <div className={`${style.userDetailSubTitle}`}><span>類別：</span><span>{v?.category || "此標籤無標籤類別"}</span></div>
                        <div className={`${style.userDetailSubTitle}`}><span>描述：</span><span>{v?.description || "此標籤無標籤描述"}</span></div>
                        <div className={`${style.userDetailSubTitle}`}><span>優先度：</span><span>{v?.priority || 0}</span></div> */}
                        {/* <Button 
                          icon={{
                            float:'left',
                            size:15,
                            component:<DeleteOutlined onClick={() => { removeUserTag(tagId) }}/>
                          }}
                          className={style.userDetailTagButton} 
                          backgroundColor="#33658A" 
                          text={tagName}
                          title={tagName}  
                        /> */}
                      </>
                    )
                  })
                ) : (
                  <div className={`${style.userDetailTag} ${style.userDetailNoTags}`}>
                    <div className={`${style.userDetailSubTitle}`}><span>此使用者未設定任何標籤</span></div>
                  </div>
                )}
              </div>
            </div>
            { globalHasJoinedRoom ? (
              <div className={`${style.userDetailDiv} ${style.userDetailDivTag}`}>
                <div className={`${style.userDetailTitle} ${style.userDetailTitleTag}`}>
                  <h6 className={`${style.userDetailTitleTagH6}`}>　可設定標籤：</h6>
                  <Search placeholder="標籤查詢" style={{ width: 120 }} size="small" onChange={(e) => {searchOnChange(e)}} onSearch={onSearch} allowClear enterButton/>
                  { hasShowRecentRemoveTags ? (
                    <AntButton className={`${style.userDetailTitleTagsRecentRemove}`} type="primary" size="small" onClick={(e) => { getTagsSettingList(e); }}>重置</AntButton>
                  ) : (
                    <AntButton className={`${style.userDetailTitleTagsRecentRemove}`} type="primary" size="small" onClick={showRecentRemoveTags}>最近移除的標籤</AntButton>
                  )}
                </div>
                {/* <div className={style.tagsReadSearchDiv}>
                  <Search className={`${style.userDetailTagsSearch}`} addonBefore={<span className={style.userDetailSearchTitle}>標籤查詢</span>} placeholder="請輸入欲查詢的標籤名稱" allowClear size="large" onChange={(e) => {searchOnChange(e)}} onSearch={onSearch} enterButton />
                </div> */}
                {/* onSearch={userTagsOnSearch} */}
                {/* <Table columns={columns} dataSource={settingTags} /> */}
                <div className={`${style.userDetailTagDetailContainer}`}>
                  { settingTags.length > 0 ? (
                    settingTags.map((v, i) => {
                      let tagId = v?._id || "";
                      let tagName = v?.name || "";
                      // if ( !! v?.name && v?.name !== "string" ) {
                      //   tagName = v.name;
                      // }
                      return (
                        <div className={style.userDetailTagIconDiv}>
                          {/* <Tag className={style.userDetailTagIcon} color="geekblue">{tagName}</Tag> */}
                          <CheckableTag
                            className={style.userDetailCheckableTagIcon}
                            key={tagId}
                            checked={selectedTagsId.includes(tagId)}
                            onChange={(checked) => addUserTags(tagId, checked)}
                          >
                            {tagName}
                          </CheckableTag>
                        </div>
                        // <div className={style.userDetailSettingTag}>
                        //   <div className={`${style.userDetailSubTitle} ${style.userDetailSubTitleName}`}><span>{tagName}</span></div>
                        //   <div className={style.userDetailTagIconDiv}><Tag className={style.userDetailTagIcon} color="geekblue">{tagName}</Tag></div>
                        //   <div className={`${style.userDetailSubTitle}`}>標籤ID：<span>{tagId}</span></div>
                        //   <div className={`${style.userDetailSubTitle}`}>類別：<span>{v?.category || "此標籤無標籤類別"}</span></div>
                        //   <div className={`${style.userDetailSubTitle}`}>描述：<span>{v?.description || "此標籤無標籤描述"}</span></div>
                        //   <div className={`${style.userDetailSubTitle}`}>優先度：<span>{v?.priority || 0}</span></div>
                        //   <div className={style.userDetailTagButtonDiv}>
                        //     <Button className={style.userDetailTagButton} backgroundColor="#33658A" text={"新增"} onClick={() => { addUserTags(tagId) }} title="add" />
                        //     <Button className={style.userDetailTagButton} backgroundColor="#F26419" text={"刪除此標籤"} title="delete" onClick={() => { removeUserTag(tagId) }} />
                        //   </div>
                        // </div>
                      );
                    })
                  ) : hasShowRecentRemoveTags && Object.values(settingTags).length < 1 ? (
                    <div className={style.userDetailSettingTag}>
                      <div className={`${style.userDetailSubTitle}`}><span>最近未移除任何標籤</span></div>
                    </div>
                  ) : (
                    <div className={style.userDetailSettingTag}>
                      <div className={`${style.userDetailSubTitle}`}><span>目前無任何可設定標籤</span></div>
                    </div>
                  )}
                </div>
              </div>   
            ) : (
              <></>
            )}
          </section>
        </Col>
      </Row>
    </>
  )
}

export default UserDetail;