import React, { useState, useEffect, useRef } from 'react';
import { Layout, Menu, Input, Space, Button, InputNumber, List, Radio, Tag, Pagination } from 'antd';
import style from './styles/UsersAdd.module.css';
import '../styles/UsersRce.css';
import './styles/UsersComponentsRce.css';

function UsersAdd() {
  const { Header, Content, Sider } = Layout; // Ant UI 排版
  const [usersDataArray, setUsersDataArray] = useState([{}]); // 各使用者資料
  const [addFieldsOnChangeState, setAddFieldsOnChangeState] = useState({ name: "", category: "", description: "", priority: 0 }); // 新增使用者欄位onChange狀態
  const [afterAddShow, setAfterAddShow] = useState({"display": "none"});
  const apiURL = process.env.REACT_APP_APIURL; // API URL
  
  /*** 新增欄位onChange ***/
  const addFieldsOnChange = (e) => {
    e.preventDefault();
    const fieldId = e?.target?.id || "";
    const value = e?.target?.value || "";
    if( !! fieldId ) {
      console.log(fieldId);
      console.log(value);
      switch(fieldId) {
        case "UserName":
          setAddFieldsOnChangeState({...addFieldsOnChangeState, ['name']: value});
          break;
        case "UserCategory":
          setAddFieldsOnChangeState({...addFieldsOnChangeState, ['category']: value});
          break;
        case "UserDescription":
          setAddFieldsOnChangeState({...addFieldsOnChangeState, ['description']: value});
          break;
        case "UserPriority":
          const intValue = parseInt(value);
          setAddFieldsOnChangeState({...addFieldsOnChangeState, ['priority']: intValue});
          break;
      }
    }
  }
  useEffect(() => {
    console.log(addFieldsOnChangeState);
  }, [addFieldsOnChangeState]);

  /*** 新增使用者 ***/
  const addUser = async(e) => {
    e.preventDefault();
    const url = apiURL + "/api/message/v1/chat-users/";
    console.log(url);
    console.log(addFieldsOnChangeState);
    const response = await fetch(
      url,
      {
        method: 'POST',
        body: JSON.stringify(addFieldsOnChangeState),
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
      }
    )
    .then((res) => res.json())
    .catch((err) => {
      alert(`使用者新增失敗！\n${err}`);
      throw new Error("Add User failed");
    });
    console.log(JSON.stringify(response));
    const responseId = response?._id || "";
    const responseStatus = response?.statusCode || "";
    if( ! responseId ) {
      const responseErrorMsg = response?.message || "";
      if( responseStatus === 500 ) {
        alert(`使用者新增失敗！\n( 使用者名稱已存在或使用者內容未輸入完整 )`);
      } else if( responseStatus === 400 ) {
        alert(`使用者新增失敗！\n( 使用者名稱已存在或使用者內容未輸入完整 )`);
      } else {
        alert(`使用者新增失敗！\n${responseErrorMsg}`);
      }
      return false;
    } else {
      const name = await response?.name || "";
      const category = await response?.category || "";
      const description = await response?.description || "";
      const priority = await response?.priority || 0;
      const data = { _id: responseId, name, category, description, priority };
      usersDataArray.push(data);
      alert(`使用者【${name}】已新增！\n( ID：${responseId} )`);
      setAddFieldsOnChangeState({}); // 清空欄位
      requestUsers(name); // 查詢已新增的使用者
      setAfterAddShow({"display":"block"});  // 顯示已新增的使用者
    }
  }  
  
  /*** 查詢Users ***/
  const requestUsers = async(searchString) => {
    const url = apiURL + "/api/message/v1/chat-users?" + new URLSearchParams({
      filter: searchString,
    });
    console.log(url);
    console.log(searchString);
    const response = await fetch(
      url,
      {
        headers: {
          Accept: "application/json",
        },
      }
    )
    .then((res) => res.json())
    .catch((err) => {
      alert(`使用者查詢失敗！\n${err}`);
      throw new Error("RequestUsers failed");
    });
    console.log(response);
    setUsersDataArray(response); 
  }
  // useEffect(() => {
  //   requestUsers();
  // }, []);
  // useEffect(() => {
  //   console.log(usersDataArray);
  // }, [usersDataArray]);

  return (
    <Layout id="usersAddLayout" className={style.usersAddLayout}>
      <Content className={style.usersAddOuterContent}>
        <Content className={style.usersAddContent}>
          <div className={style.usersAddTitle}>
            <h4>請輸入新使用者的內容</h4>
          </div>
          <div className={style.usersAddUserDescriptionDiv} style={{"justifyContent": "center"}}>
            <div className={`${style.usersAddUserDescriptionItem} ${style.usersAddUserDescriptionItemName}`}>
              <span className={`${style.usersAddUserDescriptionLabel} ${style.usersAddUserDescriptionLabelName}`}>{"使用者名稱："}</span>
              <Input id="UserName" className={`${style.usersAddUserDescriptionInput} ${style.usersAddUserDescriptionInputName}`} type="text" value={addFieldsOnChangeState.name} maxLength={10} onChange={addFieldsOnChange}></Input>
            </div>
            <div className={`${style.usersAddUserDescriptionItem} ${style.usersAddUserDescriptionItemCategory}`}>
              <span className={`${style.usersAddUserDescriptionLabel} ${style.usersAddUserDescriptionLabelCategory}`}>{"使用者類別："}</span>
              <Input id="UserCategory" className={`${style.usersAddUserDescriptionInput} ${style.usersAddUserDescriptionInputCategory}`} type="text" value={addFieldsOnChangeState.category} maxLength={20} onChange={addFieldsOnChange}></Input>
            </div>
            <div className={`${style.usersAddUserDescriptionItem} ${style.usersAddUserDescriptionItemDescription}`}>
              <span className={`${style.usersAddUserDescriptionLabel} ${style.usersAddUserDescriptionLabelDescription}`}>{"使用者描述："}</span>
              <Input id="UserDescription" className={`${style.usersAddUserDescriptionInput} ${style.usersAddUserDescriptionInputDescription}`} type="text" value={addFieldsOnChangeState.description} maxLength={40} onChange={addFieldsOnChange}></Input>
            </div>
            <div className={`${style.usersAddUserDescriptionItem} ${style.usersAddUserDescriptionItemPriority}`}>
              <span className={`${style.usersAddUserDescriptionLabel} ${style.usersAddUserDescriptionLabelPriority}`}>{"優先權："}</span>
              <Input id="UserPriority" className={`${style.usersAddUserDescriptionInput} ${style.usersAddUserDescriptionInputPriority}`} type="number" min={0} max={10} value={addFieldsOnChangeState.priority} maxLength={2} onChange={addFieldsOnChange}></Input>
            </div>
            <div className={`${style.usersAddUserDescriptionItem} ${style.usersAddUserDescriptionItemUpdateBtn}`}>
              <Button className={`${style.usersAddUserDescriptionInputAddBtn}`} type={"primary"} size={"middle"} onClick={(e) => { addUser(e); }}>新增</Button>
            </div>
          </div>
        </Content>
        <Content className={`${style.usersAddContent} ${style.usersAddContentQuery}`} style={afterAddShow}>
          <div className={style.usersAddDivider}></div>
          <section id={"usersSearchSec"} className={style.usersAddSearchSec}>
            <div className={style.usersAddTitle}>
                <h5>最近新增的使用者：</h5>
            </div>
            <List
              className={style.usersAddList}
              locale={{}}
              dataSource={usersDataArray}
              renderItem={(item, index) => (         
                <List.Item>
                  <List.Item.Meta
                    className={style.usersAddListItemMeta}
                    avatar={
                      <Tag className={style.usersAddUserIcon} color="geekblue">{item.name}</Tag>
                    }
                    title={
                      <div className={`${style.usersAddUserDescriptionItem} ${style.usersAddUserDescriptionItemTitle}`}>
                        <span className={style.usersAddUserDescriptionID}>{"使用者ID："}</span><span className={style.usersAddUserDescriptionText}>{item._id}</span>
                      </div>
                    }
                    description={
                      <div className={`${style.usersAddUserDescriptionDiv} ${style.usersAddUserDescriptionQueryDiv}`}>
                        <div className={style.usersAddUserDescriptionItem}>
                          <span className={style.usersAddUserDescriptionLabel}>{"使用者名稱："}</span>
                          <span className={style.usersAddUserDescriptionText}>{item.name}</span>
                        </div>
                        <div className={style.usersAddUserDescriptionItem}>
                          <span className={style.usersAddUserDescriptionLabel}>{"使用者類別："}</span>
                          <span className={style.usersAddUserDescriptionText}>{item.category}</span>
                        </div>
                        <div className={style.usersAddUserDescriptionItem}>
                          <span className={style.usersAddUserDescriptionLabel}>{"使用者描述："}</span>
                          <span className={style.usersAddUserDescriptionText}>{item.description}</span>
                        </div>
                        <div className={style.usersAddUserDescriptionItem}>
                          <span className={style.usersAddUserDescriptionLabel}>{"優先權："}</span>
                          <span className={style.usersAddUserDescriptionText}>{item.priority}</span>
                        </div>
                        {/* <div className={style.usersAddUserDescriptionItem}>
                          <span className={style.usersAddUserDescriptionLabel}>{"其他資訊："}</span><span className={style.usersAddUserDescriptionText}>{item.__v}</span>
                        </div> */}
                      </div>
                    }
                  />
                </List.Item>
              )}
            />
          </section>
        </Content>        
      </Content>
    </Layout>
  )
}

export default UsersAdd