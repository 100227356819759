import React, { useState, useEffect, useRef } from 'react';
import { Layout, Menu, Input, Space, Button, InputNumber, List, Radio, Tag, Pagination } from 'antd';
import style from './styles/TagsAdd.module.css';
import '../styles/TagsAnt.css';
import './styles/TagsComponentsRce.css';
import './styles/TagsComponentsAnt.css';

function TagsAdd() {
  const { Header, Content, Sider } = Layout; // Ant UI 排版
  const [tagsDataArray, setTagsDataArray] = useState([{}]); // 各標籤資料
  const [addFieldsOnChangeState, setAddFieldsOnChangeState] = useState({ name: "", category: "", description: "", priority: 0 }); // 新增標籤欄位onChange狀態
  const [afterAddShow, setAfterAddShow] = useState({"display": "none"});
  const apiURL = process.env.REACT_APP_APIURL; // API URL
  
  /*** 新增欄位onChange ***/
  const addFieldsOnChange = (e) => {
    e.preventDefault();
    const fieldId = e?.target?.id || "";
    const value = e?.target?.value || "";
    if( !! fieldId ) {
      console.log(fieldId);
      console.log(value);
      switch(fieldId) {
        case "TagName":
          setAddFieldsOnChangeState({...addFieldsOnChangeState, ['name']: value});
          break;
        case "TagCategory":
          setAddFieldsOnChangeState({...addFieldsOnChangeState, ['category']: value});
          break;
        case "TagDescription":
          setAddFieldsOnChangeState({...addFieldsOnChangeState, ['description']: value});
          break;
        case "TagPriority":
          const intValue = parseInt(value);
          setAddFieldsOnChangeState({...addFieldsOnChangeState, ['priority']: intValue});
          break;
      }
    }
  }
  useEffect(() => {
    console.log(addFieldsOnChangeState);
  }, [addFieldsOnChangeState]);

  /*** 新增標籤 ***/
  const addTag = async(e) => {
    e.preventDefault();
    const url = apiURL + "/api/message/v1/chat-tags/";
    console.log(url);
    console.log(addFieldsOnChangeState);
    const response = await fetch(
      url,
      {
        method: 'POST',
        body: JSON.stringify(addFieldsOnChangeState),
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
      }
    )
    .then((res) => res.json())
    .catch((err) => {
      alert(`標籤新增失敗！\n${err}`);
      throw new Error("Add Tag failed");
    });
    console.log(JSON.stringify(response));
    const responseId = response?._id || "";
    const responseStatus = response?.statusCode || "";
    if( ! responseId ) {
      const responseErrorMsg = response?.message || "";
      if( responseStatus === 500 ) {
        alert(`標籤新增失敗！\n( 標籤名稱已存在或標籤內容未輸入完整 )`);
      } else if( responseStatus === 400 ) {
        alert(`標籤新增失敗！\n( 標籤名稱已存在或標籤內容未輸入完整 )`);
      } else {
        alert(`標籤新增失敗！\n${responseErrorMsg}`);
      }
      return false;
    } else {
      const name = await response?.name || "";
      const category = await response?.category || "";
      const description = await response?.description || "";
      const priority = await response?.priority || 0;
      const data = { _id: responseId, name, category, description, priority };
      tagsDataArray.push(data);
      alert(`【${name}】標籤已新增！\n( ID：${responseId} )`);
      setAddFieldsOnChangeState({}); // 清空欄位
      requestTags(name); // 查詢已新增的標籤
      setAfterAddShow({"display":"block"});  // 顯示已新增的標籤
    }
  }  
  
  /*** 查詢Tags ***/
  const requestTags = async(searchString) => {
    const url = apiURL + "/api/message/v1/chat-tags?" + new URLSearchParams({
      filter: searchString,
    });
    console.log(url);
    console.log(searchString);
    const response = await fetch(
      url,
      {
        headers: {
          Accept: "application/json",
        },
      }
    )
    .then((res) => res.json())
    .catch((err) => {
      alert(`標籤查詢失敗！\n${err}`);
      throw new Error("RequestTags failed");
    });
    console.log(response);
    setTagsDataArray(response); 
  }
  // useEffect(() => {
  //   requestTags();
  // }, []);
  // useEffect(() => {
  //   console.log(tagsDataArray);
  // }, [tagsDataArray]);

  return (
    <Layout id="tagsAddLayout" className={style.tagsAddLayout}>
      <Content className={style.tagsAddOuterContent}>
        <Content className={style.tagsAddContent}>
          <div className={style.tagsAddTitle}>
            <h4>請輸入新標籤的內容</h4>
          </div>
          <div className={style.tagsAddTagDescriptionDiv} style={{"justifyContent": "center"}}>
            <div className={`${style.tagsAddTagDescriptionItem} ${style.tagsAddTagDescriptionItemName}`}>
              <span className={`${style.tagsAddTagDescriptionLabel} ${style.tagsAddTagDescriptionLabelName}`}>{"標籤名稱："}</span>
              <Input id="TagName" className={`${style.tagsAddTagDescriptionInput} ${style.tagsAddTagDescriptionInputName}`} type="text" value={addFieldsOnChangeState.name} maxLength={10} onChange={addFieldsOnChange}></Input>
            </div>
            <div className={`${style.tagsAddTagDescriptionItem} ${style.tagsAddTagDescriptionItemCategory}`}>
              <span className={`${style.tagsAddTagDescriptionLabel} ${style.tagsAddTagDescriptionLabelCategory}`}>{"標籤類別："}</span>
              <Input id="TagCategory" className={`${style.tagsAddTagDescriptionInput} ${style.tagsAddTagDescriptionInputCategory}`} type="text" value={addFieldsOnChangeState.category} maxLength={20} onChange={addFieldsOnChange}></Input>
            </div>
            <div className={`${style.tagsAddTagDescriptionItem} ${style.tagsAddTagDescriptionItemDescription}`}>
              <span className={`${style.tagsAddTagDescriptionLabel} ${style.tagsAddTagDescriptionLabelDescription}`}>{"標籤描述："}</span>
              <Input id="TagDescription" className={`${style.tagsAddTagDescriptionInput} ${style.tagsAddTagDescriptionInputDescription}`} type="text" value={addFieldsOnChangeState.description} maxLength={40} onChange={addFieldsOnChange}></Input>
            </div>
            <div className={`${style.tagsAddTagDescriptionItem} ${style.tagsAddTagDescriptionItemPriority}`}>
              <span className={`${style.tagsAddTagDescriptionLabel} ${style.tagsAddTagDescriptionLabelPriority}`}>{"優先權："}</span>
              <Input id="TagPriority" className={`${style.tagsAddTagDescriptionInput} ${style.tagsAddTagDescriptionInputPriority}`} type="number" min={0} max={10} value={addFieldsOnChangeState.priority} maxLength={2} onChange={addFieldsOnChange}></Input>
            </div>
            <div className={`${style.tagsAddTagDescriptionItem} ${style.tagsAddTagDescriptionItemUpdateBtn}`}>
              <Button className={`${style.tagsAddTagDescriptionInputAddBtn}`} type={"primary"} size={"middle"} onClick={(e) => { addTag(e); }}>新增</Button>
            </div>
          </div>
        </Content>
        <Content className={`${style.tagsAddContent} ${style.tagsAddContentQuery}`} style={afterAddShow}>
          {/* <div className={style.tagsAddDivider}></div> */}
          <section id={"tagsSearchSec"} className={style.tagsAddSearchSec}>
            <div className={style.tagsAddTitle}>
                <h5>最近新增的標籤：</h5>
            </div>
            <List
              className={style.tagsAddList}
              locale={{}}
              dataSource={tagsDataArray}
              renderItem={(item, index) => (         
                <List.Item>
                  <List.Item.Meta
                    className={style.tagsAddListItemMeta}
                    avatar={
                      <Tag className={style.tagsAddTagIcon} color="geekblue">{item.name}</Tag>
                    }
                    title={
                      <div className={`${style.tagsAddTagDescriptionItem} ${style.tagsAddTagDescriptionItemTitle}`}>
                        <span className={style.tagsAddTagDescriptionID}>{"標籤ID："}</span><span className={style.tagsAddTagDescriptionText}>{item._id}</span>
                      </div>
                    }
                    description={
                      <div className={`${style.tagsAddTagDescriptionDiv} ${style.tagsAddTagDescriptionQueryDiv}`}>
                        <div className={style.tagsAddTagDescriptionItem}>
                          <span className={style.tagsAddTagDescriptionLabel}>{"標籤名稱："}</span>
                          <span className={style.tagsAddTagDescriptionText}>{item.name}</span>
                        </div>
                        <div className={style.tagsAddTagDescriptionItem}>
                          <span className={style.tagsAddTagDescriptionLabel}>{"標籤類別："}</span>
                          <span className={style.tagsAddTagDescriptionText}>{item.category}</span>
                        </div>
                        <div className={style.tagsAddTagDescriptionItem}>
                          <span className={style.tagsAddTagDescriptionLabel}>{"標籤描述："}</span>
                          <span className={style.tagsAddTagDescriptionText}>{item.description}</span>
                        </div>
                        <div className={style.tagsAddTagDescriptionItem}>
                          <span className={style.tagsAddTagDescriptionLabel}>{"優先權："}</span>
                          <span className={style.tagsAddTagDescriptionText}>{item.priority}</span>
                        </div>
                        {/* <div className={style.tagsAddTagDescriptionItem}>
                          <span className={style.tagsAddTagDescriptionLabel}>{"其他資訊："}</span><span className={style.tagsAddTagDescriptionText}>{item.__v}</span>
                        </div> */}
                      </div>
                    }
                  />
                </List.Item>
              )}
            />
          </section>
        </Content>        
      </Content>
    </Layout>
  )
}

export default TagsAdd