import React from 'react'
import { useState, useEffect, useRef } from 'react';
import { Col, Container, Form, Row } from "react-bootstrap";
import style from './styles/LoginIndex.module.css'
import KidProLoginMark from './imgs/KidProLoginMark.svg'
import divLine from './imgs/divLine.svg'
import FBIcon from './imgs/FBIcon.svg'
import LINEIcon from './imgs/LINEIcon.svg'
import GoogleIcon from './imgs/GoogleIcon.svg'
import Dropdown from 'react-bootstrap/Dropdown';
import CountryData from './coutryCode.json';
// import { sendRegisterVeriLink } from '../components/ModuleSendEmail'
import { sha256 } from 'js-sha256';
import axios from 'axios';
//liff SDK
import liff from '@line/liff';
//引入loading 轉圈圈元件
import LoadingSpinner from '../../Components/LoadingSpinner';
// google 登入模組
import { GoogleOAuthProvider, GoogleLogin, useGoogleLogin, useGoogleOneTapLogin } from '@react-oauth/google';
// import GoogleLogin2 from '../../Components/GoogleLogin';
import {phone} from 'phone'; // 手機驗證模組
import Timer from '../../Components/Timer'; // SMS計時器
import nodeRSA from "node-rsa"; // RSA加密元件
import { useCookies } from 'react-cookie'; // 引入useCookies hook
import ReactGA from "react-ga4"; // GA4
import UaaLogin from '../../UaaLogin/UaaLogin';
import ReactPixel from 'react-facebook-pixel'; // FB Pixel

function LoginIndex(props) {
  const { isFromCustomer, setSheet }=props; 
  // // console.log('Sheet',Sheet);
  // 自動填入參照欄位
  const autoEmail = useRef(null);
  const autoPhone = useRef(null);
  // 簡訊驗證碼參照欄位
  const smscode = useRef(null);
  // 發送驗證碼按鈕文字
  const sendSMSText = useRef(null);

  // 設定loading 轉圈圈顯示與否
  const [isLoading, setIsLoading] = useState(false);
  // 設定表單資料
  const [myform, setMyform] = useState({});
  // 設定進入電子郵件表單or 電話登入表單
  const [LoginWay, setLoginWay] = useState(true);
  // 顯示錯誤訊息
  const [RegxpError, setRegxpError] = useState('');
  // 使用者要註冊或登入
  const [userStatus, setUserStatus] = useState('login');

  /* social login */
  // FB Login
  const [FBResponse, setFBResponse] = useState({});
   //設定line登入成功狀態
  const [LineResponse, setLineResponse] = useState(false);

  /***** 註冊功能State *****/
  // 檢查使用者是否有通過簡訊驗證，以啟用下一步按鈕
  const [smsVeriStatus, setSmsVeriStatus] = useState(false);
  // 點擊下一步觸發設定密碼表單，前往設定密碼
  const [gotoSetPassword, setGotoSetPassword] = useState(false);
  // 檢查使用者輸入的密碼與確認密碼，以啟用下一步按鈕
  const [passwordVeriStatus, setPasswordVeriStatus] = useState(false);
  // 點擊下一步觸發設定Email表單，前往設定註冊Email
  const [gotoSetEmail, setGotoSetEmail] = useState(false);
  // 檢查是否已發送驗證碼
  const [hasSendSMS, setHasSendSMS] = useState(false); 
  // 驗證簡訊按鈕生效
  const [verifySMSBtn, setVerifySMSBtn] = useState(true); 
  // 確認密碼是否顯示
  const [showRepassword, setShowRepassword] = useState(false);

  const googleClientId = process.env.REACT_APP_GOOGLE_APP_ID; // google client ID
  const backendURL = process.env.REACT_APP_BACKENDURL; // 首頁URL
  const apiURL = process.env.REACT_APP_APIURL; // API URL

  /* 設定國碼資料類型 */
  let coutryCodeArray = [];
  coutryCodeArray=Object.entries(CountryData.id_to_countrycode);

  const pathname = window?.location?.pathname || "";

  /******************** 表單登入 *********************/
  /* 國碼加入預設值+886 */
  if(!myform.countryCode){
    setMyform({ ...myform, countryCode: '+886' });
  }

  /* 時時記錄表單欄位值 */
  const changeFields = (event) => {
    event.preventDefault();
    setMyform({ ...myform, registerEmail: '' });
    const id = event.target.id;
    const val = event.target.value;
    // // console.log({ id, val });
    setMyform({ ...myform, [id]: val });
    setRegxpError('');
  }

  const clearMyformRegisterData = (event) => {
    event.preventDefault();
    setMyform({ ...myform, registerRepassword: '' });
    setMyform({ ...myform, registerEmail: '' });
  }
  
  // /* 檢查使用者是否參加過活動或已登記活動表單 */
  // const checkActivityAvailable = () => {
  //   const loginToken = window.localStorage.getItem('loginToken') ? window.localStorage.getItem('loginToken') : "";
  //   const BearerToken = 'Bearer ' + loginToken;
  //   // POST送出手機資料，驗證是否成功
  //   fetch('https://' + apiURL + '/api/marketing/event_user/share_yoga', {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Access-Control-Allow-Origin': '*',
  //       'Authorization': BearerToken
  //     },
  //   })
  //   .then((r) => r.json())
  //   .then((result) => {
  //     //// // console.log('result', result);
  //     if ( result.status === 200 && !! result.data ) {
  //       setRegxpError('您已經登記過此活動囉！');
  //       alert('您已經登記過此活動囉！'); 
  //       window.location.assign( window.location.protocol + "//" + window.location.host + "/CountingPage" );
  //     } else if ( result.status === 400 && result.errorCode === 1001 ) {
  //       setRegxpError('您已經參加過上次活動囉！');
  //       alert('您已經參加過上次活動囉！'); 
  //       window.location.assign( window.location.protocol + "//" + window.location.host + "/Forbidden" );
  //     } else if ( result.status === 404 && result.errorCode === 1002 ) {
  //       window.location.assign( window.location.protocol + "//" + window.location.host + "/SubmitPage" );
  //     } else {
  //       alert('伺服器忙線中，請稍後再試！');
  //       setRegxpError('伺服器忙線中，請稍後再試！');
  //     }
  //   })
  //   .catch((error) => {
  //     alert('伺服器忙線中，請稍後再試！');
  //     setRegxpError('伺服器忙線中，請稍後再試！');
  //   }); // END Fetch  
  // }

  // 官網登入window.open
  // const kidproWindowOpen = async () => {
  //   const loginToken = window.localStorage.getItem('loginToken') ? window.localStorage.getItem('loginToken') : "";
  //   let kidproLoginUrl = kidprologinURL + loginToken + "&redirectUrl=https://' + apiURL + '";
  //   // console.log( 'kidproLoginUrl', kidproLoginUrl );
  //   // 用window.open跳轉
  //   let kidproPage = window.open( kidproLoginUrl, '_blank' );
  //   let closekidproPage =  setTimeout( () => {
  //                             kidproPage.close();
  //                           }, 5000 );

  //   return () => clearTimeout(closekidproPage);      
  // }

  /* 檢查使用者是否參加過活動或已登記活動表單(成功導向官網登入) */
  // const checkActivityAvailable = async() => {

  //   const loginToken = window.localStorage.getItem('loginToken') ? window.localStorage.getItem('loginToken') : "";
  //   const BearerToken = 'Bearer ' + loginToken;

  //   // // 執行完官網登入再執行檢查活動
  //   // const asyncfunction = async () => {

  //     // 官網登入window.open
  //     // await kidproWindowOpen();

  //     // POST送出手機資料，驗證是否成功
  //     await fetch('https://' + apiURL + '/api/marketing/event_user/share_yoga', {
  //       method: 'GET',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'Access-Control-Allow-Origin': '*',
  //         'Authorization': BearerToken
  //       },
  //     })
  //     .then((r) => r.json())
  //     .then((result) => {
  //       //// // console.log('result', result);
  //       if ( result.status === 200 && !! result.data ) {
  //         setRegxpError('您已經登記過此活動囉！');
  //         // alert('您已經登記過此活動囉！'); 
  //         // console.log('Assign to CountingPage!');
  //         window.location.assign( "/CheckActivity?redir=kidpro" ); // 登入成功後導向官網登入
  //         // window.location.assign( window.location.protocol + "//" + window.location.host + "/CountingPage" );
  //       } else if ( result.status === 400 && result.errorCode === 1001 ) {
  //         setRegxpError('您已經參加過上次活動囉！');
  //         // alert('您已經參加過上次活動囉！'); 
  //         window.location.assign( window.location.protocol + "//" + window.location.host + "/Forbidden" );
  //       } else if ( result.status === 404 && result.errorCode === 1002 ) {
  //         window.location.assign( window.location.protocol + "//" + window.location.host + "/SubmitPage" );
  //       } else {
  //         // alert('伺服器忙線中，請稍後再試！');
  //         setRegxpError('伺服器忙線中，請稍後再試！');
  //       }
  //     })
  //     .catch((error) => {
  //       // alert('伺服器忙線中，請稍後再試！');
  //       setRegxpError('伺服器忙線中，請稍後再試！');
  //     }) // END Fetch          
  //   // } // END asyncfunction

  //   // asyncfunction();

  // }  

  /* 登入手機驗證 */
  const verifyPhone = () => {
    // 設定手機驗證格式
    let phoneRegxp = /^[1-9]{1}[0-9]{4,19}$/;
    let phoneRegxpWith0 = /^[0]+[0-9]{4,19}$/;
    // 手機驗證結果
    let phoneRegxpResult = phoneRegxp.test(myform.phone);
    let phoneRegxpResultWith0 = phoneRegxpWith0.test(myform.phone);
    // 若表單有自動填入值，則使用自動填入值驗證
    // console.log('verifyPhone內:myform.phone',myform.phone)
    // console.log('phoneRegxpResult',phoneRegxpResult)
    // console.log('phoneRegxpResultWith0',phoneRegxpResultWith0)
    if( !! autoPhone.current ) {
      phoneRegxpResult = phoneRegxp.test( autoPhone.current.value );
      phoneRegxpResultWith0 = phoneRegxpWith0.test( autoPhone.current.value );
    }
    // 驗證失敗顯示格式錯誤訊息
    if(LoginWay === true && phoneRegxpResult === false && phoneRegxpResultWith0 === false){
      return false;
    }
    return true;
  }

  /* 登入Email驗證 */
  const verifyEmail = () => {
    // 設定Email驗證格式
    let emailRegxp = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/;
    // Email驗證結果
    let emailRegxpResult = emailRegxp.test(myform.email); 
    // 若表單有自動填入值，則使用自動填入值驗證
    if( !! autoEmail.current ) {
      emailRegxpResult = emailRegxp.test( autoEmail.current.value ); 
    }
    // 驗證失敗顯示格式錯誤訊息
    if(LoginWay === false && emailRegxpResult ===false){
      setIsLoading(false);
      setRegxpError('請填寫正確Email資訊')
      return false;
    }
    return true;
  }

  /* 加密資料 */
  const cipherData = ( data, pkey ) => {
    let encryptData = null;
    const cipher = new nodeRSA({ b: 512 }); // 加密物件
    cipher.importKey( pkey, "pkcs1-public" ); // 公鑰放入加密物件
    encryptData = cipher.encrypt( JSON.stringify(data), "base64" );
    if( !! encryptData ) {
      return encryptData;
    }
    return false;
  }

  /* 解密資料 */
  const decipherData = ( data ) => {
    let tder = null;
    let rtder = null;
    tder = window.localStorage.getItem('tder');
    rtder = '-----BEGIN RSA PRIVATE KEY-----' + tder + '-----END RSA PRIVATE KEY-----'; // 解密密碼金鑰
    const decipher = new nodeRSA( rtder, "pkcs1" ); // 金鑰放入解密物件
    const decryptData = JSON.parse( decipher.decrypt(data, "utf8") ); // 進行解密
    // console.log( "decryptData: ", decryptData );
    const returnData = { skey: rtder, decData: decryptData };
    if( !! tder || !! rtder ) {
      return returnData;
    }
    return false;
  }

  /*** FB Pixel 相關 ***/
  const FbPixelID = process.env.REACT_APP_FB_PIXEL_ID;
  // Pixel組態設定
  const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
  };
  ReactPixel.init(FbPixelID, {}, options);
  // ReactPixel.pageView(); // For tracking page view
  // ReactPixel.trackCustom('瀏覽【完成LINE綁定送銀卡會員－活動頁】', {content_category: 'kidpro_marketing_page', content_ids: 'GrantMembership', content_name: '完成LINE綁定送銀卡會員－活動頁' }); 
  const pixelPhoneLogin = () => {
    ReactPixel.trackCustom('【手機登入】', {content_category: 'kidpro_marketing_submit', content_ids: 'phone_login', content_name: '手機登入'}); 
  }
  const pixelEmailLogin = () => {
    ReactPixel.trackCustom('【信箱登入】', {content_category: 'kidpro_marketing_submit', content_ids: 'Email_login', content_name: '信箱登入'}); 
  }
  const pixelGoogleLogin = () => {
    ReactPixel.trackCustom('【Google登入】', {content_category: 'kidpro_marketing_submit', content_ids: 'google_login', content_name: 'Google登入'}); 
  }
  const pixelFacebookLogin = () => {
    ReactPixel.trackCustom('【Facebook登入】', {content_category: 'kidpro_marketing_submit', content_ids: 'facebook_login', content_name: 'Facebook登入'}); 
  }
  // const clickCC02 = () => {
  //   ReactPixel.trackCustom('點選【嬰幼兒父母必修課】', {content_category: 'kidpro_online_course', content_ids: 'cc-0-2', content_name: '嬰幼兒父母必修課', content_type: 'product',
  //   currency: 'TWD', value: 3990 }); 
  // }
  /*** END FB Pixel 相關 ***/


  /* 手機登入時，送資料給GA4 */
  const gaPhoneLogin = () =>{
    ReactGA.event( 'login', {
      method: '手機登入'
    });
    ReactGA.send({ hitType: "event" });
    // console.log('gaPhoneLogin' );
  }
  /* 信箱登入時，送資料給GA4 */
  const gaEmailLogin = () =>{
    ReactGA.event( 'login', {
      method: '信箱登入'
    });
    ReactGA.send({ hitType: "event" });
    // console.log('gaEmailLogin' );
  }
  /* Google登入時，送資料給GA4 */
  const gaGoogleLogin = () =>{
    ReactGA.event( 'login', {
      method: 'Google登入'
    });
    ReactGA.send({ hitType: "event" });
    // console.log('gaGoogleLogin' );
  }
  /* Facebook登入時，送資料給GA4 */
  const gaFacebookLogin = () =>{
    ReactGA.event( 'login', {
      method: 'Facebook登入'
    });
    ReactGA.send({ hitType: "event" });
    // console.log('gaFacebookLogin' );
  }

  /* 使用者點選登入(加密登入) */
  const whenSubmit = (event) => {
    event.preventDefault();
    //刪除前段錯誤訊息
    setRegxpError('');
    setIsLoading(true);
    let myformTobodyformat = {};
    let santiPhone = '';
    // 判斷登入方式 手機 or email
    if(LoginWay === true){ // 手機登入
      // 若未通過驗證，顯示錯誤訊息並結束函式
      if( ! verifyPhone() ) {
        setIsLoading(false);
        setRegxpError('請填寫正確手機資訊');
        return;
      }
      // 若首號包含0，把0去除
      let phone = myform.countryCode + myform.phone; // 傳入登入API的手機號碼，預設是即時輸入值
      // // console.log('phone0',phone);
      let phoneRegxpWith0 = /^[0]+[0-9]{4,19}$/; // 包含首位0的手機正規表達式
      let phoneRegxpResultWith0 = phoneRegxpWith0.test( myform.phone ); // 即時輸入號碼是否包含0
      // // console.log('myform.phone',myform.phone);
      // // console.log('phoneRegxpResultWith0-1',phoneRegxpResultWith0);
      if( phoneRegxpResultWith0 === true ) {
        santiPhone = myform.phone.replace( /^([0]+)([0-9]{4,19})$/, '$2' ); // 銷去首位0
        // // console.log('santiPhone',santiPhone);
        phone = myform.countryCode + santiPhone; // 已淨化的即時輸入手機號碼
        // // console.log('phone1',phone);
      }
      // // console.log('autoPhone.current',autoPhone.current);
      // 若自動填入，則phone為自動填入值
      if( !! autoPhone.current ) { // 檢查自動填入是否存在
        phone = myform.countryCode + autoPhone.current.value; // 傳入登入API的手機號碼，若自動填入存在，則傳自動填入值
        // // console.log('phone2',phone);
        phoneRegxpResultWith0 = phoneRegxpWith0.test( autoPhone.current.value ); // 自動填入號碼是否包含0
        // // console.log('phoneRegxpResultWith0-2',phoneRegxpResultWith0);
        // 若首號包含0，把0去除
        if( phoneRegxpResultWith0 === true ) {
          santiPhone = autoPhone.current.value.replace( /^([0]+)([0-9]{4,19})$/, '$2' ); // 銷去首位0
          // // console.log('santiPhone',santiPhone);
          phone = myform.countryCode + santiPhone; // 已淨化的自動填入手機號碼
          // // console.log('phone3',phone);
        }
      }
      // // console.log('phone4',phone);
      let password = myform.password;
      let redirectUrl = '';
      if ( window.location.pathname.toLowerCase().indexOf('grantmembership') > -1 ) {
        redirectUrl = window.location.href + '/GrantMembership';
      } else {
        // redirectUrl = window.location.href + '/Admin';
        if( isFromCustomer ) {
          redirectUrl = '/?line_login=true';
        } else {
          redirectUrl = window.location.href + '/Admin';
        }
        // const loginToken = window.localStorage.getItem('loginToken') ?? '';
        // const isAdminLogin = window.location.path.toLowerCase() === 'adminlogin';
        // const params = new URLSearchParams(window.location.search);
        // const refParam = params.get('ref');
        // const isFromAdmin = refParam !== null && refParam.toLowerCase() === 'admin';
        // console.log('isFromAdmin',isFromAdmin);
        // if( isAdminLogin && !! loginToken && ! isFromAdmin ) {
        //   window.location.href = '/Admin?ref=AdminLogin';
        // } else {
        //     window.location.href = '/';
        // }        
      }
      // let redirectUrl = 'https://' + apiURL + '';
      // let redirectUrl = 'https://localhost:3000/SubmitPage';
      myformTobodyformat={
        phone,password,redirectUrl
      }
      // // console.log('phoneloginbodyPassword',myformTobodyformat);
    } else { // email登入
      // 若未通過驗證，顯示錯誤訊息並結束函式
      if( ! verifyEmail() ) {
        setIsLoading(false);
        setRegxpError('請填寫正確Email資訊');
        return;
      }

      let email = myform.email;
      // console.log('email',email)
              
      // 若自動填入，則email為自動填入值
      if( !! autoEmail.current ) {
        email = autoEmail.current.value;
        // console.log('Autoemail',email)
      }

      let password = myform.password;
      let redirectUrl = '';
      if ( window.location.pathname.toLowerCase().indexOf('grantmembership') > -1 ) {
        redirectUrl = window.location.hostname + '/GrantMembership';
      } else {
        redirectUrl = window.location.hostname + '/SubmitPage';
      }
      // // console.log('redirectUrl', redirectUrl);
      // let redirectUrl = 'https://' + apiURL + '';
      // let redirectUrl = 'https://localhost:3000/SubmitPage';
      myformTobodyformat={
        email,password,redirectUrl
      }
      // console.log('phoneloginbodyEmail',myformTobodyformat);
    }

    //密碼比對
    if(!myform.password){
      setIsLoading(false);
      setRegxpError('請填寫輸入密碼欄位')
      return;
    }
        
    // Fetch取得公鑰
    let pkeyID = '';
    let pkey = '';
    fetch(`${process.env.REACT_APP_UAA_URL}/api/uaa/auth/public_key`, {
      method: 'GET',
      // body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': '*',
        // 'Authorization': BearerToken
      },
    })
    .then((r) => r.json())
    .then((result) => {
      // console.log('public_key_result', result);
      if ( result.statusCode === 200 ) {
        pkeyID = result.data.id;
        pkey = result.data.key;
        // console.log('pkeyID', pkeyID);
        // console.log('pkey', pkey);
        if( !! myformTobodyformat ) {
          if( !! pkey ) {
            // 加密資料
            let cipherLoginData, cipherLoginDataJSON = null;
            cipherLoginData = { data: cipherData( myformTobodyformat, pkey ) };
            cipherLoginDataJSON = JSON.stringify(cipherLoginData)
            // console.log('myformTobodyformat',myformTobodyformat);
            // console.log('cipherLoginDataJSON',JSON.stringify(cipherLoginData));
            if( !! cipherLoginData ) { // 若資料已加密，則進行登入
              // 串接KidPro官網登入UAA
              fetch(`${process.env.REACT_APP_UAA_URL}/api/uaa/login_crypto`, {
                method: 'POST',
                body: cipherLoginDataJSON,
                headers: {
                  'Content-Type': 'application/json',
                  'x-crypt-key-id': pkeyID,
                },
              })
              .then((r) => r.json())
              .then((result) => {
                // console.log('login_crypto_result', result);
                if(result.statusCode === 200) {
                  if( LoginWay == true ) {
                    gaPhoneLogin(); // 送出手機登入事件給GA4
                    pixelPhoneLogin();
                  } else {
                    gaEmailLogin(); // 送出Email登入事件給GA4
                    pixelEmailLogin();
                  }
                  setIsLoading(false);
                  setRegxpError('登入成功');
                  window.localStorage.setItem( 'loginToken', result.data.loginToken );
                  // let loginToken = window.localStorage.getItem( 'loginToken' );
                  // 登入成功檢查使用者活動紀錄再跳轉
                  // checkActivityAvailable();
                  if ( window.location.pathname.toLowerCase().indexOf('grantmembership') > -1 ) {
                    window.location.assign( "/GrantMembership" ); // 登入成功後導向官網登入(已改成不導向直接檢查活動狀態，再導向相對應頁面)
                  } else {
                    // window.location.href = '/Admin';
                    if( isFromCustomer ) {
                      window.location.href = '/?line_login=true';
                    } else {
                      window.location.href = '/Admin';
                    }                
                    // // window.location.assign( "/CheckActivity?redir=kidpro" ); // 登入成功後導向官網登入(已改成不導向直接檢查活動狀態，再導向相對應頁面)
                  }
                  // // console.log( 'loginToken', loginToken );
                  // 登入成功跳轉
                  // // console.log( 'result.data.url', result.data.url );
                  // window.location.assign(result.data.url);
                } else if( result.statusCode === 400 ) {
                  setIsLoading(false);
                  setRegxpError('登入失敗，錯誤代碼 400');
                } else if( result.statusCode === 403 ) {
                  setIsLoading(false);
                  setRegxpError('帳號或密碼錯誤，錯誤代碼 403');
                } else {
                  setIsLoading(false);
                  setRegxpError('登入失敗，輸入的手機或Email不存在！');
                }
              })
              .catch((error) => {
                console.log(error);
                setIsLoading(false);
                setRegxpError('伺服器忙線中，請稍後再試！');
              }); // END Fetch 使用者登入
            } else {
              setRegxpError('錯誤，資料加密失敗！');
            }
          } else {
            setRegxpError('錯誤，缺少金鑰，無法加密資料！');
          }
        } else {
          setRegxpError('錯誤，未能取得表單資料！');
        }
      } else {
        setRegxpError('伺服器忙線中，請稍候再試！');
        // console.log( 'result.status!==200');
      }
    })
    .catch((error) => {
      setRegxpError('伺服器忙線中，請稍候再試！');
      // console.log(error);
    }); // END Fetch取得公鑰
  }; /* END Function 使用者點選登入 */
  /******************** END 表單登入 *********************/

  /******************** 表單註冊 *********************/
  /* 驗證手機格式 */
  const verifyPhoneFormat = ( phoneArgs, phoneArgsWithCountryCode ) => {
    const verifyResult = true;
    // 驗證手機格式
    let phoneRegxp = /^[0-9]{1}[0-9]{4,19}$/;
    // 驗證無國碼手機號碼
    let phoneRegxpResult = phoneRegxp.test( phoneArgs ); 
    // // console.log('phoneArgs',phoneArgs);

    // 利用phone module驗證含國碼手機號碼
    const veriPhoneObj = phone( phoneArgsWithCountryCode );

    // 密碼比對
    // if( myform.password == '' ) {
    //     setRegxpError('請填寫密碼欄位！');
    //     verifyResult = false;
    //     return verifyResult;
    // }
    if( ! veriPhoneObj.isValid || ! phoneRegxpResult ) {
        console.log('veriPhoneObj.isValid',veriPhoneObj.isValid)
        console.log('phoneRegxpResult',phoneRegxpResult)
        setRegxpError('請填寫正確手機資訊！');
        verifyResult = false;
        return verifyResult;
    }  
    else {
      setRegxpError('');
    }
    return [verifyResult, veriPhoneObj.phoneNumber]; // 0表示驗證結果布林值，1表示手機號碼淨化值
  } /* END 驗證手機格式 */  

  /* 寄送SMS */
  const sendSMS = (event) => {
    event.preventDefault();
    setRegxpError(''); // 點選按鈕清空錯誤訊息 
    // // console.log('sendSMS','sendSMS');

    // 重整頁面後/使用者更動欄位 之手機值
    let phone = "";
    let phoneWithCountryCode = "";
    try {
      if( !! window.localStorage.getItem( 'phoneNumber' ) ) { // 重整頁面後以localStorage值做驗證及送簡訊
        phone = window.localStorage.getItem( 'phoneNumber' );
        phoneWithCountryCode = myform.countryCode + window.localStorage.getItem( 'phoneNumber' );
      } else { // 使用者更動欄位後以myform.phone值做驗證及送簡訊
        phone = myform.phone;
        phoneWithCountryCode = myform.countryCode + myform.phone;
      }
    } catch (error) {
      // console.log('error',error);
    }

    // 驗證手機格式
    const verifyResult = verifyPhoneFormat( phone, phoneWithCountryCode )[0]; // 0表示驗證結果布林值，1表示手機號碼淨化值
    // // console.log('verifyResult',verifyResult);

    // 測試用
    // setVerifySMSBtn(false);
    // // console.log('sendSMS verifySMSBtn',verifySMSBtn);

    //// console.log('phone',phone);
    let data = {
      phone: phoneWithCountryCode,
    } 
    // // console.log('data',data);
    const loginToken = window.localStorage.getItem('loginToken');
    const BearerToken = 'Bearer ' + loginToken;

    // // console.log('verifyResult',verifyResult);
    // // console.log('Timer', Timer);
    // 若手機驗證通過，POST送出手機資料，開始傳送簡訊驗證碼
    if(verifyResult) {

      // // console.log('開始傳送簡訊驗證碼');
      // // console.log('Timer', Timer);
      // TimerObj = new Timer;
      // TimerObj.startTimer(); // 開始計時
      // // console.log('TimerObj.state.time.s', TimerObj.state.time.s);

      // 
      fetch(`${process.env.REACT_APP_UAA_URL}/api/uaa/phone_verification_code`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      })
      .then((r) => r.json())
      .then((result) => {
        // // // console.log('result', result);
        if ( result.status === 200 ) {
          setHasSendSMS(true);
          setVerifySMSBtn(false); // 等60秒才能再送簡訊
          setRegxpError('簡訊驗證碼已傳送！');
          alert('簡訊驗證碼傳送成功！'); 
        } else if ( result.status === 400 ) {
          setRegxpError('請填寫正確手機資訊！');
          alert('請填寫正確手機資訊！'); 
        } else if ( result.status === 409 ) {
          setRegxpError('此手機號碼已完成綁定！');
          alert('此手機號碼已完成綁定！'); 
          setSmsVeriStatus(true);
          window.localStorage.setItem('smsVeriStatus', 'true');
          ////// // // console.log(window.localStorage.getItem('smsVeriStatus'));
        } else if ( result.status === 429 ) {
          setRegxpError('此手機號碼已完成綁定！');
          alert('此手機號碼已完成綁定！'); 
          setSmsVeriStatus(true);
          window.localStorage.setItem('smsVeriStatus', 'true');
        }
      })
      .catch((error) => {
        alert('伺服器忙線中！');
        ////// // // console.log(error);
      }); // END Fetch
    } else {
      setRegxpError('錯誤的手機格式！');
    }
  } /* END 寄送SMS */

  /* 監聽驗證碼，當點選驗證簡訊驗證碼，即開始驗證 */
  const submitSMS = (event) => {
    event.preventDefault();
    setRegxpError(''); // 點選按鈕清空錯誤訊息 
    // const asyncSetMyform = async () => {
    //   await changeFields(event);
    //   await // // // console.log('myform.sms',myform.sms);
    // }
    // asyncSetMyform();
    // // console.log('myform.sms',myform.sms);
    // // console.log('smscode.current.value', smscode.current.value);
    if( smscode.current.value.length === 6 ) {
      verifySMS( smscode.current.value );
      setRegxpError('');
    } else {
      setRegxpError('請填入6碼簡訊驗證碼！');
      setSmsVeriStatus(false);
      window.localStorage.setItem('smsVeriStatus', 'false');
    }
    return;
  } /* END 監聽驗證碼 */

  // /* 監聽驗證碼，當輸入驗證碼數量達6個，即開始驗證 */
  // const listenCodeCounts = (event) => {
  //   event.preventDefault();
  //   const id = event.target.id;
  //   const val = event.target.value;
  //   if( val.length === 6 ) {
  //     verifySMS( event, val );
  //     setRegxpError('');
  //   } else {
  //     setRegxpError('請填入6碼簡訊驗證碼！');
  //     // setSmsVeriStatus(false);
  //     window.localStorage.setItem('smsVeriStatus', 'false');
  //   }
  //   return;
  // } /* END 監聽驗證碼 */

  /* 驗證SMS */
  const verifySMS = ( sms ) => {
    setIsLoading(true);
    // 重整頁面後/使用者更動欄位 之手機值
    let phone = "";
    if( !! window.localStorage.getItem( 'phoneNumber' ) ) {
      phone = myform.countryCode + window.localStorage.getItem( 'phoneNumber' );
    } else {
      phone = myform.countryCode + myform.phone;
    }

    let data = {
      'phone': phone,
      'code': sms
    }
    const loginToken = window.localStorage.getItem('loginToken');
    const BearerToken = 'Bearer ' + loginToken;
    // // console.log('data', data);
    fetch(`${process.env.REACT_APP_UAA_URL}/api/uaa/phone_verify`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((r) => r.json())
    .then((result) => {
      if ( result.status === 200 ) {
        // setSmsVeriStatus(true);
        window.localStorage.setItem('smsVeriStatus', 'true');
        setRegxpError('驗證成功！');
        setIsLoading(false);
        setSmsVeriStatus(true);
        window.localStorage.setItem('smsVeriStatus', 'true');
        setRegxpError('簡訊驗證成功！');
        alert('簡訊驗證成功！'); 
        // 成功驗證簡訊，則更新手機資料
        // fetch(`${process.env.REACT_APP_UAA_URL}/api/uaa/phone_verify`, {
        //   method: 'PUT',
        //   body: JSON.stringify(data),
        //   headers: {
        //     'Content-Type': 'application/json',
        //     'Authorization': BearerToken
        //   },
        // })
        // .then((r) => r.json())
        // .then((result) => {
        //   if ( result.status === 200 ) {
        //     setIsLoading(false);
        //     setSmsVeriStatus(true);
        //     window.localStorage.setItem('smsVeriStatus', 'true');
        //     setRegxpError('簡訊驗證成功！'); 
        //     alert('簡訊驗證成功！'); 
        //     window.location.reload();
        //   } else if ( result.status === 400 ) {
        //     setIsLoading(false);
        //     setSmsVeriStatus(false);
        //     window.localStorage.setItem('smsVeriStatus', 'false');
        //     setRegxpError('錯誤的簡訊驗證碼！');
        //     alert('錯誤的簡訊驗證碼！'); 
        //   } else if ( result.status === 403 ) {
        //     setIsLoading(false);
        //     setSmsVeriStatus(false);
        //     window.localStorage.setItem('smsVeriStatus', 'false');
        //     setRegxpError('此手機號碼無法被註冊！');
        //     alert('此手機號碼無法被註冊！'); 
        //   }else if ( result.status === 409 ) {
        //     setIsLoading(false);
        //     setSmsVeriStatus(true);
        //     window.localStorage.setItem('smsVeriStatus', 'true');
        //     setRegxpError('此手機號碼已完成綁定！');
        //     alert('此手機號碼已完成綁定！'); 
        //   }
        // })
        // .catch((error) => {
        //   // // console.log('error',error);
        //   setIsLoading(false);
        //   alert('伺服器忙線中，請稍後再試！')
        // });
      } else if ( result.status === 400 ) {
        setIsLoading(false);
        setSmsVeriStatus(false);
        window.localStorage.setItem('smsVeriStatus', 'false');
        setRegxpError('錯誤的簡訊驗證碼！');
        alert('錯誤的簡訊驗證碼！'); 
      } else if ( result.status === 429 ) {
        setIsLoading(false);
        setSmsVeriStatus(false);
        window.localStorage.setItem('smsVeriStatus', 'true');
        setRegxpError('此手機號碼已完成綁定！');
        alert('此手機號碼已完成綁定！'); 
      }
    })
    .catch((error) => {
      // // console.log('error',error);
      setIsLoading(false);
      alert('伺服器忙線中，請稍後再試！');
    });
  }  

  /* 60秒後將簡訊驗證按鈕打開 */
  useEffect(() => {
    let timer = "";
    const executeTimeout = async() => {
      if( ! verifySMSBtn ) {
        timer = setTimeout(() => {
          setVerifySMSBtn(true);
          // // // console.log('SetTimeout verifySMSBtn',verifySMSBtn);
        }, 60000);
      }
    }
    executeTimeout();
    return () => clearTimeout(timer);
  });

  // const sendSMS = (event) => {
  //   event.preventDefault();
  //   setRegxpError(''); // 點選按鈕清空錯誤訊息 
  //   //驗證手機格式
  //   let phoneRegxp = /^[1-9]{1}[0-9]{5,19}$/g;
  //   //驗證Email格式
  //   let emailRegxp = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/;
  //   let phoneRegxpResult = phoneRegxp.test(myform.phone); 
  //   let emailRegxpResult = emailRegxp.test(myform.email); 
  //   //密碼比對
  //   if(myform.password ==''){
  //     setRegxpError('請填寫輸入密碼欄位')
  //     return;
  //   }
  //   if(userStatus === 'register' && LoginWay === true && phoneRegxpResult === false){
  //     setRegxpError('請填寫正確手機資訊')
  //     return;
  //   }
  //   if(userStatus === 'register' && LoginWay === false && emailRegxpResult ===false){
  //     setRegxpError('請填寫正確email資訊')
  //     return;

  //   }        
  //   let phone = myform.countryCode + myform.phone;
  //   let data = {
  //     phone
  //   }
  //   fetch(`${process.env.REACT_APP_UAA_URL}/api/uaa/phone_verification_code`, {
  //     method: 'POST',
  //     body: JSON.stringify(data),
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Access-Control-Allow-Origin': '*'
  //     },
  //   })
  //   .then((r) => r.json())
  //   .then((result) => {
  //     //// // console.log('result', result);
  //     if ( result.status === 200 ) {
  //       setRegxpError('簡訊驗證碼傳送成功！');
  //     } else if ( result.status === 400 ) {
  //       setRegxpError('錯誤的手機格式！');
  //     } else if ( result.status === 429 ) {
  //       setRegxpError('此手機號碼已被註冊！');
  //     } else {
  //       setRegxpError('伺服器忙線中！');
  //     }
  //   })
  //   .catch((error) => {
  //     alert('伺服器忙線中！');
  //     //// // console.log(error);
  //   });      
  // }

  //   // 監聽驗證碼，當輸入驗證碼數量達6個，即開始驗證
  //   const listenCodeCounts = (event) => {
  //     event.preventDefault();
  //     setRegxpError(''); // 點選按鈕清空錯誤訊息 
  //     const id = event.target.id;
  //     const val = event.target.value;
  //     //// // console.log({ id, val });
  //     if( val.length === 6 ) {
  //       verifySMS( event, val );
  //       setRegxpError('');
  //     } else {
  //       setRegxpError('請填入6碼簡訊驗證碼！');
  //       setSmsVeriStatus(false);
  //       setHasSendSMS(false);
  //       setGotoSetPassword(false);
  // setShowRepassword(false);
  //     }
  //     return;
  //   }

  //   const verifySMS = ( event, sms ) => {
  //     event.preventDefault();
  //     let phoneNumber = myform.countryCode + myform.phone;
  //     let data = {
  //       'phone': phoneNumber,
  //       'code': sms
  //     }
  //     //// // console.log('data', data);
  //     fetch(`${process.env.REACT_APP_UAA_URL}/api/uaa/phone_verify`, {
  //       method: 'POST',
  //       body: JSON.stringify(data),
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //     })
  //     .then((r) => r.json())
  //     .then((result) => {
  //       //// // console.log('result', result);
  //       // 測試用
  //       // result.status = 200
  //       if ( result.status === 200 ) {
  //         setSmsVeriStatus(true);
  //         setHasSendSMS(false);
  //         setRegxpError('驗證成功！');
  //         //// // console.log('result.data.phoneVerifyToken',result.data.phoneVerifyToken);
  //         localStorage.setItem( 'phoneVerifyToken', result.data.phoneVerifyToken );
  //       } else if ( result.status === 400 ) {
  //         setSmsVeriStatus(false);
  //         setHasSendSMS(false);
  //         setGotoSetPassword(false);
  //         setShowRepassword(false);
  //         setRegxpError('錯誤的簡訊驗證碼！');
  //       } else if ( result.status === 429 ) {
  //         setSmsVeriStatus(false);
  //         setHasSendSMS(false);
  //         setGotoSetPassword(false);
  //         setShowRepassword(false);
  //         setRegxpError('此手機號碼已被註冊！');
  //       } else {
  //         setRegxpError('伺服器忙線中！');
  //       }
  //     })
  //     .catch((error) => {
  //       alert('伺服器忙線中！');
  //       //// // console.log(error);
  //     });     
  //   }   
  
  // 監聽密碼，密碼長度不小於8個，且包含英數字
  const listenPassword = (event) => {
    event.preventDefault();
    setRegxpError(''); // 清空錯誤訊息 
    const id = event.target.id;
    const val = event.target.value;
    if( val.length < 8 ) {
      setShowRepassword(false);
      setPasswordVeriStatus(false);
      setGotoSetEmail(false);
      setRegxpError('密碼長度不足（請輸入8～20位英數字）！');
    } else if( ! val.match(/[a-zA-Z]/g) || ! val.match(/[0-9]/g) ) {
      setShowRepassword(false);
      setPasswordVeriStatus(false);
      setGotoSetEmail(false);
      setRegxpError('密碼必須包含英數字！');
    } else if ( val.length > 20 ) {
      setShowRepassword(false);
      setPasswordVeriStatus(false);
      setGotoSetEmail(false);
      setRegxpError('密碼過長（請輸入8～20位英數字）！');
    } else {
      setShowRepassword(true);
      setPasswordVeriStatus(false);
      setGotoSetEmail(false);
      setRegxpError('');
    }
  }

  // 監聽確認密碼，當密碼字元與確認密碼一致，則通過驗證
  const listenRepassword = (event) => {
    event.preventDefault();
    setRegxpError(''); // 清空錯誤訊息 
    const id = event.target.id;
    const val = event.target.value;
    if( val.length === myform.registerPassword.length ) {
      setPasswordVeriStatus(false);
      setGotoSetEmail(false);
      verifyPassword( myform.registerPassword, val );
    } else {
      setPasswordVeriStatus(false);
      setGotoSetEmail(false);
      setRegxpError('密碼與確認密碼不一致！');
    }
  }
    
  // 檢查確認密碼是否與密碼一致
  const verifyPassword = ( RegisterPassword, RegisterRepassword ) => {
    setRegxpError(''); // 點選按鈕清空錯誤訊息 
    if( RegisterPassword === RegisterRepassword ) {
      setPasswordVeriStatus(true);
      setRegxpError('✔ 可使用此密碼');
    } else {
      setPasswordVeriStatus(false);
      setGotoSetEmail(false);
      setRegxpError('密碼與確認密碼不一致！');
    }
  }

  const whenRegister = (event) => {
    event.preventDefault();
    setRegxpError(''); // 點選按鈕清空錯誤訊息 
    ////// // console.log('我是發request');
    let registerPhone = myform.countryCode + myform.phone;
    let registerEmail = myform.email;
    let registerPassword = myform.registerPassword;
    let registerRepassword = myform.registerRepassword;
    let phoneVerifyToken = localStorage.getItem('phoneVerifyToken');
    let data = {
      phone: registerPhone,
      email: registerEmail,
      password: registerPassword,
      checkPassword: registerRepassword,
      phoneVerifyToken: phoneVerifyToken,
      lineToken: ""
    }
    fetch(`${process.env.REACT_APP_UAA_URL}/api/uaa/register`, {
      method: 'POST',
      body: JSON.stringify(myform),
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((r) => r.json())
    .then((result) => {
      ////// // console.log('result', result);
      if ( result.status === 201 ) {
        alert('註冊成功！');
      } else if ( result.status === 400 ) {
        alert('錯誤的Email/手機格式，或密碼與確認密碼不一致！');
      } else if ( result.status === 403 ) {
        alert('瀏覽器的存取權限受到限制，請稍後再試！');
      } else if ( result.status === 429 ) {
        alert('您的Email或手機號碼已被註冊！');
      } else {
        alert('伺服器忙線中！');
      }
    })
    .catch((error) => {
      alert('伺服器忙線中！');
      ////// // console.log(error);
    });  
  }


  const lineid = process.env.REACT_APP_LINE_APP_ID;
  // // console.log('lineid',lineid);
  let line_liff_loginData = {};

  const linelogin = async() => {
    // liff.isLoggedIn();
    // // console.log('line', liff.isLoggedIn());
    const init = await liff.init({
        liffId: lineid, // Use own liffId
        // withLoginOnExternalBrowser: true, // Enable automatic login process
    }).catch((err) => {
        // Todo: retry init.
        // // console.log('err',err);
    });

    const isLoggedIn = liff.isLoggedIn();
    // console.log('line',isLoggedIn);
  
    // if(isLoggedIn ===true){
    //   // console.log('isLoggedIn = true LoginINdex')
    //   setSheet(1);
    //   let redirectUrl = window.location.href
    //   const accessToken = liff.getAccessToken();
  
    //   line_liff_loginData= {
    //     accessToken:accessToken,
    //   }
    //   // console.log('line_bindData',line_liff_loginData);

      // const loginToken = window.localStorage.getItem('loginToken');
      // const BearerToken = 'Bearer ' + loginToken;
        // fetch(`${process.env.REACT_APP_UAA_URL}/api/uaa/line_liff_login`, {
        //   method: 'POST',
        //   body: JSON.stringify(line_liff_loginData),
        //   headers: {
        //     'Content-Type': 'application/json'
        //   },
        // })
        //   .then((r) => r.json())
        //   .then((result) => {
        //      // console.log('result', result);
        //     if(result.status === 200){
        //       window.localStorage.setItem( 'loginToken', result.data.loginToken );
        //       // console.log('成功 200'); 
              
        //     }else if(result.status === 400){
        //       // console.log('失敗，錯誤代碼 400')
        //     }else if(result.status === 403){
              
        //       // console.log('line與uaa帳號未綁定，錯誤代碼 403');
        //     }else{
        //       alert('失敗。')
        //     }
        //   });
    //  }
  }

  const BACKENDURL = process.env.REACT_APP_BACKENDURL;
  // /* Line 驗證是否登入，未登入則登入 */
  // const LineAuth = () => {
  //     const isLoggedIn = liff.isLoggedIn();
  //     // console.log('isLoggedIn',isLoggedIn);
  //     if(!isLoggedIn){
  //       liff.login({ redirectUri: "https://"+ BACKENDURL +"/LoginEncode?page=homepage" });
  //       // liff.login();
  //       // console.log('line',isLoggedIn)
  //       setLineResponse(isLoggedIn);
  //     } else {
  //       setRegxpError('您已經登入過LINE囉！');
  //     }
  // }

  /* Line 驗證是否登入，未登入則登入 */
  const LineAuth = () => {
    const isLoggedIn = liff.isLoggedIn();
    // console.log('isLoggedIn',isLoggedIn);
    if( ! isLoggedIn ){
      // console.log('window.location.pathname',window.location.pathname);
      // console.log('window.location.pathname.toLowerCase().indexOf(GrantMembership)',window.location.pathname.toLowerCase().toLowerCase().indexOf('grantmembership'));
      if( window.location.pathname.toLowerCase().indexOf('grantmembership') > -1 ) {
        liff.login({ redirectUri: "https://"+ BACKENDURL +"/LoginEncode?page=GrantMembership" });
      } else {
        liff.login({ redirectUri: "https://"+ BACKENDURL +"/LoginEncode?page=homepage" });
      }
      // liff.login({ redirectUri: "https://"+ 'kid-pro.com' +"/LoginEncode?page=homepage" });
      // liff.login();
      // console.log('line',isLoggedIn)
      setLineResponse(isLoggedIn);
    } else {
      setRegxpError('您已經登入過LINE囉！');
    }
  }
  
  /* LINE登出 */
  const linelogout = () => {
    liff.logout();
  }

  /* FB登入 */
  const FBLogin = ()=>{
  
    // 跳出 Facebook 登入的對話框
    // Scope 為臉書要資料範圍
    window.FB.login(
    function (response) {
      setFBResponse(response);
      // // console.log('handleFBLogin', response);

      let FBData = {
        id: response.authResponse.userID,
        accessToken: response.authResponse.accessToken,
      }

      if(response.status === 'connected'){
        
        // // console.log('FBData',FBData);

        fetch(`${process.env.REACT_APP_UAA_URL}/api/uaa/facebook_login`, {
          method: 'POST',
          body: JSON.stringify(FBData),
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((r) => r.json())
        .then((result) => {
          // // console.log('result',result);
          if(result.status === 200){
            gaFacebookLogin(); // 送出Facebook登入事件給GA4
            pixelFacebookLogin();
            setRegxpError('登入成功！');
            window.localStorage.setItem( 'loginToken', result.data.loginToken );
            // let loginToken = window.localStorage.getItem( 'loginToken' );
            // checkActivityAvailable();
            if ( window.location.pathname.toLowerCase().indexOf('grantmembership') > -1 ) {
              window.location.assign( "/GrantMembership" ); // 登入成功後導向官網登入(已改成不導向直接檢查活動狀態，再導向相對應頁面)
            } else {
              // // window.location.assign( "/CheckActivity?redir=kidpro" ); // 登入成功後導向官網登入(已改成不導向直接檢查活動狀態，再導向相對應頁面)
              // window.location.href = '/Admin';
              if( isFromCustomer ) {
                window.location.href = '/?line_login=true';
              } else {
                window.location.href = '/Admin';
              }         
            }
          }else{
            setRegxpError('登入失敗！（Facebook帳號尚未綁定官網或帳號無法進行驗證）');
          }
        })
        .catch((error) => {
          // // console.log('error',error);
          setRegxpError('登入失敗！（Facebook帳號無法進行驗證）');
        });

      }else{
        // console.log('FB login FAILED',);
        setRegxpError('登入失敗！（Facebook帳號無法進行驗證）');
      }
    },
    { scope: 'public_profile' }
    );
  }

  // /* FB登出 */
  const FBLogout = ()=>{
    window.FB.logout(function (response) {
      setFBResponse(response);
      // console.log('handleFBLogout', response.status);
    });
  }

  return (
    <>
    <Row>
      <Col>
      <div style={{display:'none'}}>
        <UaaLogin setSheet={setSheet} ></UaaLogin>
      </div>
      
        { userStatus === 'login' && ! gotoSetPassword && ! gotoSetEmail ? (
            <Form className={style.form} autoComplete="off">
              <Col className="mb-3">
                <div className={style.KidProLoginTitleBox}>
                  <span className={style.KidProLoginTitle}>登入Messenger</span>
                   {/* <div className={style.KidProLoginMarkBox}>
                      <img className={style.KidProLoginMark} src={KidProLoginMark}></img>
                   </div> */}
                </div>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <div className={style.LabelSwitchDiv}>
                    {LoginWay?(
                      <Form.Label className={style.mailLable}>手機號碼登入</Form.Label>
                    ):(
                      <Form.Label className={style.mailLable}>電子郵件登入</Form.Label>
                    )}
                    {LoginWay?(
                    <div className={style.loginWayDirectSec} onClick={()=>{setLoginWay(false);setRegxpError('')}}>
                      <span className={style.loginWayDirect}>改用電子郵件登入&gt;</span>
                    </div>
                    ):(
                      <div className={style.loginWayDirectSec} onClick={()=>{setLoginWay(true);setRegxpError('')}}>
                          <span className={style.loginWayDirect}>改用手機號碼登入&gt;</span>
                      </div>
                    )}
                  </div>
                  {LoginWay?(
                    <div className={style.PhoneSec}>
                      <select className={style.countryCode} id="countryCode"  onChange={changeFields} value={myform.countryCode} required>
                          {coutryCodeArray.map((v,i)=>{
                            return(
                            <>
                              <option href="#/action-1" key={i+1}>{v[1]}</option>
                            </>    
                            )
                            })}
                      </select>
                      <input className={style.inputinfiled} type="tel" id="phone" name='phone' pattern="[0-9|\-|\s]{5,20}" ref={autoPhone} onChange={changeFields} value={myform.phone} placeholder='範例：910123456'  autoComplete="false" required/>
                      
                    </div>
                    ):
                    ( <>
                        <input className={style.inputinfiled} type="email" id="email" name='email' ref={autoEmail} onChange={changeFields} value={myform.email} autoComplete="false" required/>
                      </>
                    )
                  }
                </Form.Group>

                <Form.Group className="mb-4">
                  <Form.Label className={style.pdsLable}>密碼</Form.Label>
                  <input className={style.inputinfiled} type="password" id="password" name='password' onChange={changeFields} value={myform.password} required/>               
                </Form.Group>
              </Col>
              <Col>
                <div className={style.RegxpErrorSec}>
                  <p className={style.RegxpError}>{RegxpError}</p>
                </div>
              </Col>
              <Col className="mb-4">
                <div className={style.loginbuttosec}>
                  {isLoading===true?(< LoadingSpinner></LoadingSpinner>):(<button type="submit" className={style.loginbutton} onClick={whenSubmit} >登入</button>)}
                  {/* < LoadingSpinner></LoadingSpinner> */}
                  {/* <div style={{width:'width: 15rem',padding:'0 10rem'}}>< LoadingSpinner></LoadingSpinner></div> */}
                </div>
              </Col>

 
              
              <Col className="mb-1">
                <div className={style.directSec}>
                  {/* <p className={style.regSec} >
                    <a href="https://kid-pro.com/my-account" target="_blank">立即註冊</a>
                  </p> */}
                  <p className={style.regSec} onClick={()=>{ 
                      setSheet('register');
                      // setUserStatus('register');
                      setLoginWay(true);
                      setRegxpError('');
                    }}>
                    <span>立即註冊</span>
                  </p>
                  {/* <p onClick={()=>setSheet('register')}>
                    我只是要line註冊
                  </p> */}
                  {/* <p className={style.padforgotSec}> */}
                  <p className={style.regSec}>
                    {/* <span>忘記密碼</span> */}
                    <a href="https://kid-pro.com/my-account/lost-password/" target="_blank">忘記密碼</a>
                  </p>
                </div>
              </Col>
              <Col className="mb-1">
                <div className={style.divLineSec}>
                  <img src={divLine} className={style.divLine}></img>
                </div>
              </Col>
              <Col>
                <div className={style.socialLoginSec} > 
                  < div className={style.socialLoginTitle}>
                    <div>透過社群帳號登入</div>
                    <div className={style.FbGoogleLoginTip} >Facebook/Google 請以<a style={{ 'textDecoration': 'none' }} href={`${pathname}?openExternalBrowser=1`}>【外部瀏覽器開啟】</a>再進行登入</div>
                  </div>
                </div>
                <div className={style.socialLoginSec} > 
                  <div className={style.SocialIconSec}>
                    <div className={style.SocialIconSecNoimg}>
                      <img  className={style.SocialIconNoimg}></img>
                    </div>
                    <div className={style.SocialIcon} onClick={LineAuth}>
                      <img  className={style.SocialIconImg} src={LINEIcon}></img>
                    </div>
                    <div className={style.SocialIcon} onClick={FBLogin}>
                      <img className={style.SocialIconImg} src={FBIcon}></img>
                    </div>
                    {/* <div>
                      <p onClick={FBLogout}>FBLogout</p>
                    </div> */}
                    <div className={`${style.SocialIcon} ${style.SocialIconGoogle}`} onClick={() => {}}>
                        <img className={style.SocialIconImg} src={GoogleIcon}></img>
                    </div>            
                    <GoogleOAuthProvider clientId={googleClientId}>
                      <GoogleLogin width='400px' type='icon' locale="zh_TW" shape='rectangular' logo_alignment='center'
                        onSuccess={credentialResponse => {
                          // // console.log('credentialResponse.credential', credentialResponse.credential);
                          const googleLoginBody = {
                            token: credentialResponse.credential
                          }
                          fetch(`${process.env.REACT_APP_UAA_URL}/api/uaa/google_login`, {
                            method: 'POST',
                            body: JSON.stringify( googleLoginBody ),
                            headers: {
                              'Content-Type': 'application/json',
                            },
                          })
                          .then((r) => r.json())
                          .then((result) => {
                            if(result.status === 200){
                              gaGoogleLogin(); // 送出Google登入事件給GA4
                              pixelGoogleLogin();
                              setIsLoading(false);
                              setRegxpError('登入成功');
                              // // console.log('result.data.loginToken',result.data.loginToken);
                              window.localStorage.setItem( 'loginToken', result.data.loginToken );
                              // checkActivityAvailable(); // 登入成功檢查使用者活動紀錄再跳轉
                              if ( window.location.pathname.toLowerCase().indexOf('grantmembership') > -1 ) {
                                window.location.assign( "/GrantMembership" ); // 登入成功後導向官網登入(已改成不導向直接檢查活動狀態，再導向相對應頁面)
                              } else {
                                // // window.location.assign( "/CheckActivity?redir=kidpro" ); // 登入成功後導向官網登入(已改成不導向直接檢查活動狀態，再導向相對應頁面)
                                // window.location.href = '/Admin';
                                if( isFromCustomer ) {
                                  window.location.href = '/?line_login=true';
                                } else {
                                  window.location.href = '/Admin';
                                }   
                              }
                            }else if(result.status === 400){
                              setIsLoading(false);
                              setRegxpError('登入失敗！（Google帳號尚未綁定官網或帳號無法進行驗證）');
                            }else if(result.status === 403){
                              setIsLoading(false);
                              setRegxpError('登入失敗！（Google帳號尚未綁定官網或帳號無法進行驗證）');
                            }else{
                              setIsLoading(false);
                              setRegxpError('登入失敗！（Google帳號尚未綁定官網或帳號無法進行驗證）');
                            }
                          })
                          .catch((error) => {
                            // console.log('error',error);
                            setIsLoading(false);
                            setRegxpError('伺服器忙線中，請稍後再試！');
                          });                          
                        }}
                        onError={() => {
                          // // console.log('Login Failed');
                          setRegxpError('您無法從Google登入！');
                          alert('您無法從Google登入！'); 
                        }}
                      />
                    </GoogleOAuthProvider>
                  </div> 
                  
                </div>
              </Col>
            </Form>
        ) : userStatus === 'register' && ! gotoSetPassword && ! gotoSetEmail ? (
          <Form className={style.form}>
          <Col className="mb-3">
            <div className={style.KidProLoginTitleBox}>
              <span className={style.KidProLoginTitle}>註冊</span>
               <div className={style.KidProLoginMarkBox}>
                  <img className={style.KidProLoginMark} src={KidProLoginMark}></img>
               </div>
            </div>
          </Col>
          <Col>
            <Form.Group className="mb-4">
                <Form.Label className={style.mailLable}>手機註冊</Form.Label>
                <div className={style.PhoneSec}>
                  { !! smsVeriStatus ? 
                  (
                    <>
                    <select className={style.countryCode} id="countryCode" onChange={changeFields} value={myform.countryCode} disabled required>
                        {coutryCodeArray.map((v,i)=>{
                          return(
                          <>
                            <option href="#/action-1" key={i+1}>{v[1]}</option>
                          </>    
                          )
                          })}
                    </select>
                    <Form.Control className={style.form_inputPhone} type="tel" id="phone" name='phone' pattern="[0-9|\-|\s]{5,20}" onChange={changeFields} value={myform.phone} disabled required/>
                    </>
                  ) : 
                  (
                    <>
                    <select className={style.countryCode} id="countryCode" onChange={changeFields} value={myform.countryCode} required>
                      {coutryCodeArray.map((v,i)=>{
                        return(
                        <>
                          <option href="#/action-1" key={i+1}>{v[1]}</option>
                        </>    
                        )
                        })}
                    </select>
                    <Form.Control className={style.form_inputPhone} type="tel" id="phone" name='phone' pattern="[0-9|\-|\s]{5,20}" onChange={changeFields} value={myform.phone} required/>                    
                    </>
                  )} 
                </div>  
                { !! smsVeriStatus ? 
                    (
                      <div className={style.form_reverifySec}>
                        <div className={style.form_buttonPhoneDiv}>
                          <button type="button" className={style.form_reverifyButton} onClick={()=>{setSmsVeriStatus(false);setHasSendSMS(false);}}>重新驗證</button>
                        </div>                       
                      </div>
                    ) : (
                      <></>
                )}              
            </Form.Group>
            { /* 動態顯示簡訊送出及簡訊驗證區塊 */ }
            {/* <div ref={showSmsDivRef} className={style.form_lableCardSMS}> */}
            <Col className="mb-4">
            {/* <div className={style.form_lableCardSMS}> */}
              {/* 
                * 使用者已綁定手機，不顯示發送驗證碼與驗證驗證碼區塊；
                * 未綁定，則顯示發送驗證碼按鈕；
                * 若已發送驗證碼，則顯示輸入驗證碼欄位及驗證簡訊欄位
                */}
              {/* { !! window.localStorage.getItem('phoneNumber') && !! formPhone ? ( */}
              { !! smsVeriStatus ? 
              // { false ?
                (
                  <></>
                )
                : ! smsVeriStatus && ! hasSendSMS ?
                // : false ?
                (  
                  <>
                    <div className={style.form_smsPadding}></div>
                    <div className={style.form_smsSec}>
                      <div className={style.form_buttonPhoneDiv}>
                        {/* { verifySMSBtn ? (
                          <button type="button" ref={sendSMSText} className={style.form_buttonPhone} onClick={sendSMS}>發送驗證碼</button>
                        ) : (
                          <button type="button" ref={sendSMSText} className={style.form_buttonPhone} onClick={sendSMS} disabled>發送驗證碼</button>
                        ) } */}
                        <button type="button" ref={sendSMSText} className={style.form_buttonPhone} onClick={sendSMS}>發送驗證碼</button>
                      </div>                       
                    </div>
                  </>
                )
                :
                (
                  <>
                    <div className={style.form_smsPadding}></div>
                    <div className={style.form_smsSec}>
                      <div className={style.form_buttonPhoneDiv}>
                        { verifySMSBtn ? (
                          <button type="button" ref={sendSMSText} className={style.form_buttonPhone} onClick={sendSMS}>發送驗證碼</button>
                        ) : (
                          <button type="button" ref={sendSMSText} className={style.form_buttonPhone} onClick={sendSMS} disabled><Timer></Timer></button>
                        ) }
                      </div>
                      <div id="veifySMSDiv"  className={style.form_verifySMSDiv}>
                        <div className={style.form_inputPhoneSec}>
                          <label className={style.form_labelPhone} >簡訊驗證碼（6碼）</label>
                          <input  type="text" id="sms" name="sms" maxLength="6" ref={smscode} className={style.form_inputSMS} value={myform.sms} />               
                        </div>
                        <div className={style.form_buttonPhoneDiv}>
                        {isLoading?<><LoadingSpinner></LoadingSpinner></>:<><button type="button" className={`${style.form_buttonPhone} ${style.form_buttonPhoneSubmitSMS}`} onClick={submitSMS}>開始驗證</button></>}
                        </div>
                      </div>                           
                    </div>
                  </>
                )
              }
            {/* </div>   */}
            </Col>
            {/* <Col className="mb-4">
              <div className={style.loginbuttosec}>
                <button type="button" className={style.loginbutton} onClick={sendSMS}>發送驗證碼</button>
              </div>
            </Col>   */}
            {/* <Form.Group className="mb-5">
              <Form.Label className={style.pdsLable}>簡訊驗證碼（6碼）</Form.Label>
              <Form.Control type="text" id="sms" onChange={listenCodeCounts} value={myform.sms} required/>               
            </Form.Group> */}
          </Col>        
          <Col>
                <div className={style.RegxpErrorSec}>
                  <p className={style.RegxpError}>{RegxpError}</p>
                </div>
              </Col>          
          <Col className="mb-4">
          { smsVeriStatus ? (
            <div className={style.loginbuttosec}>
              <button type="button" className={style.nextStepButton} onClick={()=>{setGotoSetPassword(true);setShowRepassword(false);setRegxpError('')}}>下一步</button>
            </div>            
          ) : (
            <div className={style.loginbuttosec}>
              <button type="button" className={style.nextStepButton} disabled>下一步</button>
            </div>
          ) }
          </Col>
          <Col className="mb-1">
            <div className={style.directSec}>
              <p className={style.regSec} onClick={ () => {
                setUserStatus( 'login' ); 
                setLoginWay( false );
                // setSmsVeriStatus(false);
                setHasSendSMS(false);
                setGotoSetPassword( false );
                setShowRepassword(false);
                setPasswordVeriStatus( false );
                setGotoSetEmail( false );  
                setRegxpError(''); 
                } }
              >
              <span>登入KidPro</span>
              </p>
            </div>
          </Col>          
        </Form>
        ) : userStatus === 'register' && gotoSetPassword && ! gotoSetEmail ? (
          <Form className={style.form}>
          <Col className="mb-4">
            <div className={style.KidProLoginTitleBox}>
              <span className={style.KidProLoginTitle}>註冊 新會員</span>
            </div>
          </Col>
          <Col>
            <Form.Group className="mb-4">
              <Form.Label className={style.pdsLable}>輸入密碼</Form.Label>
              {/* <TextField  fullWidth color='text' variant="standard" type="password"  id="registerPassword" name='registerPassword' maxlength="30" onChange={(event)=>{changeFields(event);listenPassword(event);}} value={myform.registerPassword} required /> */}
              <Form.Control type="password" id="registerPassword" name='registerPassword' maxlength="30" onChange={(event)=>{changeFields(event);listenPassword(event);}} value={myform.registerPassword} required/>          
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-4">
              { !! showRepassword ? (
                <>
                  <Form.Label className={style.pdsLable}>再次輸入密碼</Form.Label>
                  {/* <TextField  fullWidth color='text' variant="standard" type="password" id="registerRepassword" name='registerRepassword' maxlength="30" onChange={listenRepassword} value={myform.registerRepassword} required /> */}
                  <Form.Control type="password" id="registerRepassword" name='registerRepassword' maxlength="30" onChange={listenRepassword} value={myform.registerRepassword} required/>      
                </>
              ) : (
                <></>
              )}
            </Form.Group>
          </Col>        
          <Col>
            <div className={style.RegxpErrorSec}>
              <p className={style.RegxpError}>{RegxpError}</p>
            </div>
          </Col>
          <Col className="mb-4">
          { passwordVeriStatus ? (
            <div className={style.loginbuttosec}>
              <button type="button" className={style.nextStepButton} onClick={(event)=>{setGotoSetEmail(true);setGotoSetPassword(false);setShowRepassword(false);setRegxpError('');clearMyformRegisterData(event);}}>下一步</button>
            </div>            
          ) : (
            <div className={style.loginbuttosec}>
              <button type="button" className={style.nextStepButton} disabled>下一步</button>
            </div>
          ) }
          </Col>          
          <Col className="mb-1">
            <div className={style.directSec}>
              <p className={style.regSec} onClick={ () => {
                setUserStatus( 'register' ); 
                setLoginWay( false );
                // setSmsVeriStatus(false);
                setHasSendSMS(false);
                setGotoSetPassword( false );
                setShowRepassword(false);
                setPasswordVeriStatus( false );
                setGotoSetEmail( false );  
                setRegxpError(''); 
                } }
              >
              <span>返回手機驗證</span>
              </p>
            </div>
          </Col>          
          </Form>          
        ) : userStatus === 'register' && ! gotoSetPassword && gotoSetEmail ? (
          <Form className={style.form}>
          <Col className="mb-4">
            <div className={style.KidProLoginTitleBox}>
              <span className={style.KidProLoginTitle}>註冊 新會員</span>
            </div>
          </Col>
          <Col>
          <Form.Group className="mb-4">
              {/* <Form.Label className={style.pdsLableEmail}>電子郵件</Form.Label> */}
              {/* { myform.registerEmail === myform.registerRepassword ? ( */}
                {/* <Form.Control type="email" id="registerEmail" name='registerEmail' onChange={changeFields} value='' required/>       */}
              {/* ) : ( */}
              {/* <TextField  fullWidth color='text' variant="standard" label="電子郵件" type="email" id="registerEmail" name='registerEmail' maxlength="50" onChange={changeFields} value={myform.registerEmail} required /> */}
                <Form.Control type="email" id="registerEmail" name='registerEmail' maxlength="50" onChange={changeFields} value={myform.registerEmail} required/>      
              {/* ) }   */}
            </Form.Group>
          </Col> 
          <Col>
            <div className={style.RegxpErrorSec}>
              <p className={style.RegxpError}>{RegxpError}</p>
            </div>
          </Col>
          <Col className="mb-4">
          { passwordVeriStatus ? (
            <div className={style.loginbuttosec}>
              <button type="button" className={style.nextStepButton} onClick={()=>{
                let emailVeriToken = sha256(myform.registerEmail);
                //// // console.log('window.referer', document.referer);
                // sendRegisterVeriLink('shop@kid-pro.com', myform.registerEmail, 'KidPro醫學級兒童展教育團隊 - 請點選驗證連結完成註冊驗證', document.referer + emailVeriToken );
                // setRegxpError('');
                }}>寄送驗證信
              </button>
            </div>            
          ) : (
            <div className={style.loginbuttosec}>
              <button type="submit" className={style.nextStepButton} disabled>寄送驗證信</button>
            </div>
          ) }
          </Col>  
          <Col>
            <div className={style.registerEmailTipSec}>
              <p className={style.registerEmailTip}>請至Email信箱收取驗證信，並點選信件中的驗證連結以啟用您的帳號！</p>
            </div>
          </Col>                  
          <Col className="mb-1">
            <div className={style.directSec}>
              <p className={style.regSec} onClick={ () => { 
                setUserStatus( 'register' ); 
                setLoginWay( false );
                // setSmsVeriStatus(false);
                setHasSendSMS(false);
                setGotoSetPassword( true );
                setShowRepassword(false);
                setPasswordVeriStatus( false );
                setGotoSetEmail( false );  
                setRegxpError( '' ); 
                }}
              >
              <span>返回密碼設定</span>
              </p>
            </div>
          </Col>          
          </Form>       
        ) : (
          <Form>
            <Col className="mb-3">
            <div className={style.KidProLoginTitleBox}>
              <span className={style.KidProLoginTitle}>恭喜您完成註冊</span>
            </div>
            </Col>
            <Col>
                <div className={style.directSec}>
                  <p className={style.regSec} onClick={ () => { 
                    setUserStatus( 'login' ); 
                    setLoginWay( false );
                    // setSmsVeriStatus(false);
                    setHasSendSMS(false);
                    setGotoSetPassword( false );
                    setShowRepassword(false);
                    setPasswordVeriStatus( false );
                    setGotoSetEmail( false );  
                    setRegxpError( '' ); 
                    } }
                  >
                  <span>返回登入KidPro</span>
                  </p>
                </div>
            </Col>
         
          </Form> 
        )}
      </Col>
    </Row>
    </>
  )
}

export default LoginIndex;