import React, { useEffect, useState } from 'react';
import { Layout, Input, InputNumber, Radio, Checkbox, Space, DatePicker, TimePicker, Button, Dropdown, Form, List, Modal,Tag } from 'antd';
import style from './styles/LineCastByTags.module.css'
import '../styles/LineAnt.css';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import { TweenOneGroup } from 'rc-tween-one';
import dayjs from 'dayjs';

/*** Import LINE Components ***/
import LineTags from './LineComponents/LineTags';
import LineCastInterface from "./LineComponents/LineCastInterface"

function LineCastByTags() {
    const { Content } = Layout;
    const { CheckableTag } = Tag;
    const [btnLoadings, setBtnLoadings] = useState([]);
    const [sendingTime, setSendingTime] = useState(0);
    const [showInputNumber, setShowInputNumber] = useState(false);
    const [checkList, setCheckList] = useState([{}]);
    const [openTagsModal, setOpenTagsModal] = useState(false);
    const [tagsData, setTagsData] = useState([{ _id:'001', name:'測試標籤1' }, { _id:'002', name:'測試標籤2' }, { _id:'003', name:'測試標籤3' }]); // 標籤資訊
    const [recentTags, setRecentTags] = useState([]);

    /*** 載入常用標籤 ***/
    const recentTagsLoad = () => {
        const recentTagsString = window.localStorage.getItem("rctatt");
        const recentTagsArray = !! recentTagsString ? JSON.parse(recentTagsString) : [];
        setRecentTags(recentTagsArray);
    }
    useEffect(() => {
        console.log(recentTags);
    }, [recentTags]);
    useEffect(() => {
        recentTagsLoad();
    }, []);
    useEffect(() => {
        recentTagsLoad();
    }, [tagsData]); /*** END 載入常用標籤 ***/
    const handleTagsModalOk = () => {
        setOpenTagsModal(false);
    };
    const handleTagsModalCancel = () => {
        setOpenTagsModal(false);
    };

    const items = [
        {
            label: '草稿1',
            key: '1',
        },
        {
            label: '草稿2',
            key: '2',
        },
        {
            label: '草稿3',
            key: '3',
        },
        {
            label: '草稿4',
            key: '4',
        },
    ];
    const recentAttrTest =
    [
        {
            "key": "647593c28b71e19c5dac639d",
            "label": "標籤2"
        },
        {
            "key": "647593d08b71e19c5dac63a0",
            "label": "標籤3"
        },
        {
            "key": "647593e18b71e19c5dac63a3",
            "label": "標籤4"
        },
        {
            "key": "647593e18b71e19c5dac63a3",
            "label": "標籤4"
        }
    ]

    const enterLoading = (index) => {
        setBtnLoadings((prevLoadings) => {
          const newLoadings = [...prevLoadings];
          newLoadings[index] = true;
          return newLoadings;
        });
    
        setTimeout(() => {
            setBtnLoadings((prevLoadings) => {
            const newLoadings = [...prevLoadings];
            newLoadings[index] = false;
            return newLoadings;
          });
        }, 6000);
      };

    const radioOnChange = (e) => {
      console.log('radio checked', e.target.value);
      setSendingTime(e.target.value);
    };
    
    const datePickerOnChange = (time, timeString) => {
        console.log(time, timeString);
        setSendingTime(1);
    };

    const timePickerOnChange = (time, timeString) => {
        console.log(time, timeString);
        setSendingTime(1);
    };

    const checkboxOnChange = (event) => {
        const id = event?.target?.id || "";
        const checked = event?.target?.checked || false;
        const checkElement = { id, checked };
        if( Object.values(checkList[0]).length > 0 ) { // CheckList 有值，取代或新增check資料
            let checkListArray = new Array();
            let hasSameId = false;
            checkListArray = checkList.map((obj) => {
                const objId = obj?.id || "";
                if( objId === id ) {
                    obj.checked = checked; // 取代check資料
                    hasSameId = true;
                }
                return obj;
            });
            if( ! hasSameId ) { // 未比對到同ID的物件，新增check資料
                setCheckList((current) => [...current, checkElement]);
            } else {  // 有比對到同ID的物件，取代原CheckList
                setCheckList(checkListArray);
            }
        } else { // 無值則新增check資料
            let checkListArray = new Array;
            checkListArray.push(checkElement);
            setCheckList(checkListArray);
        }
        // const santiCheckList = [...new Set(checkList.map(itm => JSON.stringify(itm)))].map(i => JSON.parse(i));
        // console.log(santiCheckList);
        let msgLimitIsChecked = false;
        for( let el of checkList ) { // msgLimit前次設定的值
            if( Object.values(el).includes("msgLimit") ) {
                msgLimitIsChecked = el?.checked || false;
            }
        }
        if( ( id === "msgLimit" && checked ) || msgLimitIsChecked ) { // msgLimit事件觸發設定的值或前次設定的值
            setShowInputNumber(true);
        } else {
            setShowInputNumber(false);
        }
    };
    useEffect(() => {
        console.log(checkList);
    }, [checkList]);

    const msgLimitOnChange = (value) => {
        console.log(value);
    }

    /*** 取得標籤設定列表 ***/
    const getTagsSettingList = (e, urlArg) => {
        if( !! e ) {
            e.preventDefault();
        } 
        console.log('getTagsSettingList');
        console.log(urlArg);
        const apiUrl = process.env.REACT_APP_APIURL;
        const url = !! urlArg ? urlArg : apiUrl + "/api/message/v1/chat-tags" + '?limit=50&offset=0';
        console.log(url);
        // fetch(url, {
        //     method: 'GET',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         'Accept': 'application/json'
        //     },
        // })
        // .then((r) => r.json())
        // .then((result) => {
        //     console.log(result);
        //     /*
        //         [
        //             {
        //                 "_id": "64547fd77af19c5afde1c548",
        //                 "name": "string",
        //                 "category": "default",
        //                 "description": " ",
        //                 "priority": 0,
        //                 "__v": 0
        //             }
        //         ]
        //     */
        //     if( Array.isArray(result) && result.length > 0 ) {
        //         let resultExist = false;
        //         for( let v of result ) {
        //         const settingTagId = v?._id || "";
        //         if( !!settingTagId ) {
        //             resultExist = true;
        //             break;
        //         }
        //         }
        //         console.log(resultExist);
        //         if( resultExist ) {
        //         setTagsData(result);
        //         }
        //     }
        // })
        // .catch((error) => {
        //     console.log('error',error);
        // });                             
    }
    useEffect(() => {
        getTagsSettingList();
    }, []); 
    useEffect(() => {
        console.log(tagsData);
    }, [tagsData]); 
    /*** END 取得標籤設定列表 ***/    

    /*** 新增標籤 ***/
    const addTags = (triggerTagId, checked) => {
        console.log(triggerTagId);
        console.log(checked);
        // const globalAdminPanelSocketConnected = globalAdminPanelSocket?.connected || false;
        // if( globalAdminPanelSocketConnected ) {
        // globalAdminPanelSocket.emit('addTags', {
        //     tagIds: [triggerTagId],
        // });
        // handleAddTag(triggerTagId, checked); // 目前無法判斷是否真的新增成功(無回傳值)
        // console.log('User Detail : AddUserTags Emit !');
        // } else {
        // console.log('User Detail : GlobalAdmin【Panel】SocketConnected not Connected !');
        // }
    }/*** END 新增標籤 ***/

    /*** 刪除標籤 ***/
    const removeTags = (triggerTagId) => {
        console.log("removeTags", triggerTagId);
        // const globalAdminPanelSocketConnected = globalAdminPanelSocket?.connected || false;
        // if( globalAdminPanelSocketConnected ) {
        // globalAdminPanelSocket.emit('removeTags', {
        //     tagId: triggerTagId,
        // });
        // console.log('User Detail : removeTags Emit !');
        // handleCloseTag(triggerTagId); // 目前無法判斷是否真的刪成功(無回傳值)
        // } else {
        // console.log('User Detail : GlobalAdmin【Panel】SocketConnected not Connected !');
        // alert("刪除失敗（與伺服器連線中斷）！")
        // }
    }
    /*** END 刪除標籤 ***/    
  
    return (
      <>
        <Form id={"LineCastByTagsForm"} className={style.lineCastByTagsForm} onFinish={()=>{}}>
            <h4 className={style.lineCastByTagsH4}>傳訊給特定對象（By Tags）</h4>
            <Form.Item className={style.lineCastByTagsFormItem} name="button">
                <Space className={style.lineCastByTagsSpaceSendBtn}>
                    <Dropdown.Button
                        className={style.lineCastByTagsSendBtn}
                        type="primary"
                        icon={<DownOutlined/>}
                        // loading={btnLoadings[0]}
                        menu={{ items }}
                        placement="bottom"
                        onClick={() => enterLoading(0)}
                    >
                        儲存草稿
                    </Dropdown.Button>                    
                    <Button className={style.lineCastByTagsSendBtn} type="primary" 
                        // loading={btnLoadings[1]} 
                        onClick={() => enterLoading(1)}>
                        傳送測試訊息
                    </Button>
                </Space>
            </Form.Item>
            <Form.Item className={style.lineCastByTagsFormItem} name="tags" showCount>
                <div className={style.lineCastByTagsDivAudience} id="LineCastByTags">
                    <h6 className={style.lineCastByTagsH6}>群播對象</h6>
                    <Content className={`${style.lineCastByTagsContent} ${style.lineCastByTagsSpace}`}>
                        <section id="LineTagsSection">
                            <Space className={`${style.lineCastByTagsSpace} ${style.lineCastByTagsSpaceTags}`} direction="vertical">
                                <div className={style.lineCastByTagsDivFilterByTags}>
                                    <Button className={style.lineCastByTagsSendBtn} type="primary" 
                                        // loading={btnLoadings[3]} 
                                        onClick={() => { enterLoading(3); setOpenTagsModal(true); }}  icon={<SearchOutlined />}>
                                        篩選標籤
                                    </Button>
                                    <Dropdown.Button
                                        className={`${style.lineCastByTagsSendBtn} ${style.lineCastByTagsDropdown}`}
                                        type="primary"
                                        icon={<DownOutlined/>}
                                        // loading={btnLoadings[4]}
                                        menu={{ items:recentTags }}
                                        placement="bottom"
                                        onClick={() => enterLoading(4)}
                                    >
                                        常用標籤
                                    </Dropdown.Button>
                                </div>    
                                <Modal
                                    getContainer={document.getElementById("LineTagsSection")}
                                    className={style.lineCastByTagseModalTags}
                                    open={openTagsModal}
                                    footer={null}
                                    onOk={handleTagsModalOk}
                                    onCancel={handleTagsModalCancel}
                                >
                                    <LineTags></LineTags>
                                </Modal>    
                                <div className={`${style.lineCastByTagsTagsContainer}`}>
                                    { tagsData.length > 0 ? (
                                        tagsData.map((v, i) => {
                                            let tagsId = v?._id || "";
                                            let tagsName = v?.name || "";
                                            return (
                                            <>
                                                <TweenOneGroup
                                                enter={{
                                                    scale: 0.8,
                                                    opacity: 0,
                                                    type: 'from',
                                                    duration: 100,
                                                }}
                                                onEnd={(e) => {
                                                    if (e.type === 'appear' || e.type === 'enter') {
                                                    e.target.style = 'display: inline-block';
                                                    }
                                                }}
                                                leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
                                                appear={false}
                                                >
                                                {/* {userInfoTags.map(tagsForMap)} */}
                                                <span  style={{ display: 'inline-block' }}>
                                                    <Tag
                                                        className={`${style.lineCastByTagsTags} ${style.lineCastByTagsTagsTags}`}
                                                        color="geekblue"
                                                        closable
                                                        onClose={(e) => {
                                                            e.preventDefault();
                                                            removeTags(tagsId);
                                                        }}
                                                        >
                                                        {tagsName}
                                                    </Tag>
                                                </span>  
                                                {/* {tagsName} */}
                                                </TweenOneGroup>
                                            </>
                                            )
                                        })
                                    ) : (
                                        <div className={`${style.lineCastByTagsTag} ${style.lineCastByTagsNoTags}`}>
                                            <div className={`${style.lineCastByTagsSubTitle}`}><span>此使用者未設定任何標籤</span></div>
                                        </div>
                                    )}
                                </div>
                            </Space>  
                        </section>
                    </Content>                  
                </div>
            </Form.Item>            
            <Form.Item className={style.lineCastByTagsFormItem} name="sendingTime" rules={[{ required: true, message: `【傳送時間】為必填欄位！` }]}>
                <Radio.Group className={style.lineCastByTagsRadioGroup} onChange={radioOnChange} value={sendingTime} defaultValue={0}>
                    <h6 className={style.lineCastByTagsH6}>傳送時間</h6>
                    <div className={style.lineCastByTagsDivTime}>
                        <Space className={`${style.lineCastByTagsSpace} ${style.lineCastByTagsSpaceTime}`} direction="vertical">
                            <Radio className={style.lineCastByTagsRadio} value={0}><span className={style.lineCastByTagsRadioText}>立即傳送</span></Radio>
                            <Radio className={`${style.lineCastByTagsRadio} ${style.lineCastByTagsRadioSchedule}`} value={1}>
                                <span className={style.lineCastByTagsRadioText}>預約傳送</span>
                                <div className={`${style.lineCastByTagsRadioPicker}`}>
                                    <DatePicker 
                                        className={`${style.lineCastByTagsRadioDatePicker}`} 
                                        placeholder={"選擇傳送日期"}
                                        defaultValue={dayjs(new Date())}
                                        onChange={datePickerOnChange}
                                        suffixIcon={null}
                                    />
                                    <TimePicker 
                                        className={`${style.lineCastByTagsRadioTimePicker}`} 
                                        placeholder={"選擇傳送時間"}
                                        defaultValue={dayjs(new Date()).add(5, "minute")}
                                        onChange={timePickerOnChange}
                                        suffixIcon={null}
                                    />
                                </div>
                            </Radio>
                        </Space>
                    </div>
                </Radio.Group>
            </Form.Item>
            <Form.Item className={`${style.lineCastByTagsFormItem} ${style.lineCastByTagsFormItemAdvanceSetting}`} name="advance">
                <h6 className={style.lineCastByTagsH6}>進階設定</h6>
                <Space className={`${style.lineCastByTagsSpace} ${style.lineCastByTagsSpaceAdvanceSetting}`} direction="vertical">
                    <Checkbox className={style.lineCastByTagsSpaceCheckBox} id={"lineVoom"} onChange={checkboxOnChange}>張貼至LINE VOOM</Checkbox>
                    <Checkbox className={style.lineCastByTagsSpaceCheckBox} id={"msgLimit"} onChange={checkboxOnChange}>指定群發訊息則數的上限</Checkbox>
                        { showInputNumber ? (
                            <InputNumber className={style.lineCastByTagsSpaceCheckBoxInputNumber} min={1} max={500000} defaultValue={50000} onChange={msgLimitOnChange} />
                        ) : (
                            <></>
                        )}
                    <Checkbox className={style.lineCastByTagsSpaceCheckBox} id={"ABTesting"} onChange={checkboxOnChange}>建立A/B測試</Checkbox>
                    <Checkbox className={style.lineCastByTagsSpaceCheckBox} id={"Advertise"} onChange={checkboxOnChange}>設定廣告活動</Checkbox>
                </Space>
            </Form.Item>
            <Form.Item className={`${style.lineCastByTagsFormItem} ${style.lineCastByTagsFormItemLineCastInterface}`} name="訊息輸入欄位" rules={[{ required: true, message: `【訊息輸入欄位】為必填欄位！` }]} getFieldError={(name)=>{return `【${name}】為必填欄位！`}}>
                <Space className={style.lineCastByTagsSpaceLineCastInterface} >
                    <LineCastInterface></LineCastInterface>
                </Space>
            </Form.Item>
        </Form>
      </>
    );
}

export default LineCastByTags