import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import style from './styles/LineMessages.module.css';
import '../styles/LineAnt.css';
import './LineComponents/styles/LineComponentsRce.css'
import './LineComponents/styles/LineComponentsAnt.css';
import { Layout, Menu, Input, Space, Button, InputNumber, List, Radio, Tag, Pagination, Table, Tabs } from 'antd';
import {
  EyeOutlined,
  PlusOutlined,
  EditFilled,
  EditOutlined,
  RollbackOutlined,
  DeleteFilled,
  DeleteOutlined
} from '@ant-design/icons';

function LineMessages(props) {
  const [lineMessagesCRUD, setLineMessagesCRUD] = useState('LineMessagesRead');
  const [searchString, setSearchString] = useState(""); // 搜尋欄輸入的字串
  const [currentPage, setCurrentPage] = useState(1); // 當前頁面
  const [numberPerPage, setNumberPerPage] = useState(6); // 一頁有幾個訊息數
  const [lineMessagesDataArray, setLineMessagesDataArray] = useState([{}]); // 各訊息資料
  const [isEditMode, setIsEditMode] = useState([-1, false]); // [第n項tag資料, 第n項是否可編輯]
  const [updateFieldsOnChangeState, setUpdateFieldsOnChangeState] = useState({}); //更新欄位狀態
  const [addFieldsOnChangeState, setAddFieldsOnChangeState] = useState({ name: "", category: "", description: "", priority: 0 }); // 新增訊息欄位onChange狀態
  const lineMessagesNameRef = useRef("");
  const lineMessagesCategoryRef = useRef("");
  const lineMessagesDescriptionRef = useRef("");
  const lineMessagesPriorityRef = useRef("");
  const apiURL = process.env.REACT_APP_APIURL; // API URL

  useEffect(()=>{
    let elTargetArray = document.getElementsByClassName('ant-table-column-has-sorters');
    const elTargetArrayLength = elTargetArray?.length || 0;
    let elTarget1 = elTargetArray[0];
    let elChange1 = document.getElementsByClassName('ant-table-row-expand-icon-cell')[0];
    let elTarget2 = elTargetArray[elTargetArrayLength-1];
    let elChange2 = document.getElementsByClassName('ant-table-cell-scrollbar')[0];
    if( !! elTarget1  && !! elChange1 ) {
      //滑鼠移入時改變背景顏色（藍）
      elTarget1.addEventListener('mouseover', function () {
          elChange1.style.backgroundColor = '#f0f0f0';
      })
      //滑鼠移出時恢復背景顏色
      elTarget1.addEventListener('mouseout', function () {
          elChange1.style.backgroundColor = '';
      })
    }
    if( elTargetArrayLength > 1 && !! elTarget1 && !! elChange2 ) {
      elTarget2.addEventListener('mouseover', function () {
          elChange2.style.backgroundColor = '#f0f0f0';
      })
      elTarget2.addEventListener('mouseout', function () {
          elChange2.style.backgroundColor = '';
      })
    }
  }, []);

  /*** ANT排版 ***/
  const { Header, Content, Sider } = Layout;

  const lineMessagesHeader = [
    {
      title: '內容',
      dataIndex: '_id',
      filters: [
        {
          text: '1',
          value: '1',
        },
        {
          text: '2',
          value: '2',
        },
        {
          text: '3',
          value: '3',
        },
      ],
      // filterMode: 'tree',
      filterSearch: true,
      onFilter: (value, record) => record._id.indexOf(value) > -1,
      sorter: (a, b) => lineMessagesDataArray.indexOf(a) - lineMessagesDataArray.indexOf(b),
      width: '28%',
    },
    {
      title: '傳送對象',
      dataIndex: 'name',
      filters: [
        {
          text: '1',
          value: '1',
        },
        {
          text: '2',
          value: '2',
        },
        {
          text: '3',
          value: '3',
        },
      ],
      filterSearch: true,
      onFilter: (value, record) => record.name.indexOf(value) > -1,
      width: '18%',
    },
    {
      title: '進階設定',
      dataIndex: 'category',
      filters: [
        {
          text: '1',
          value: '1',
        },
        {
          text: '2',
          value: '2',
        },
        {
          text: '3',
          value: '3',
        },
      ],
      filterSearch: true,
      onFilter: (value, record) => record.category.indexOf(value) > -1,
      width: '18%',
    },
    {
      title: '傳送時間',
      dataIndex: 'description',
      filters: [
        {
          text: '1',
          value: '1',
        },
        {
          text: '2',
          value: '2',
        },
        {
          text: '3',
          value: '3',
        },
      ],
      filterSearch: true,
      onFilter: (value, record) => record.description.indexOf(value) > -1,
      sorter: (a, b) => lineMessagesDataArray.indexOf(a) - lineMessagesDataArray.indexOf(b),
      width: '18%',
    },
    {
      title: '更新時間',
      dataIndex: 'priority',
      filters: [
        {
          text: '0',
          value: '0',
        },
        {
          text: '1',
          value: '1',
        },
        {
          text: '2',
          value: '2',
        },
      ],
      filterSearch: true,
      onFilter: (value, record) => String(record.priority).indexOf(value) > -1,
      sorter: (a, b) => a.priority - b.priority,
      width: '18%',
    },
  ];

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      filters: [
        {
          text: 'Joe',
          value: 'Joe',
        },
        {
          text: 'Jim',
          value: 'Jim',
        },
        {
          text: 'Submenu',
          value: 'Submenu',
          children: [
            {
              text: 'Green',
              value: 'Green',
            },
            {
              text: 'Black',
              value: 'Black',
            },
          ],
        },
      ],
      onFilter: (value, record) => record.name.indexOf(value) === 0,
      sorter: (a, b) => a.name.length - b.name.length,
      sortDirections: ['descend'],
    },
    {
      title: 'Age',
      dataIndex: 'age',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.age - b.age,
    },
    {
      title: 'Address',
      dataIndex: 'address',
      filters: [
        {
          text: 'London',
          value: 'London',
        },
        {
          text: 'New York',
          value: 'New York',
        },
      ],
      onFilter: (value: string, record) => record.address.indexOf(value) === 0,
    },
  ];

  const dataTest = [
    {
      key: '1',
      name: 'John Brown',
      age: 32,
      address: 'New York No. 1 Lake Park',
    },
    {
      key: '2',
      name: 'Jim Green',
      age: 42,
      address: 'London No. 1 Lake Park',
    },
    {
      key: '3',
      name: 'Joe Black',
      age: 32,
      address: 'Sydney No. 1 Lake Park',
    },
    {
      key: '4',
      name: 'Jim Red',
      age: 32,
      address: 'London No. 2 Lake Park',
    },
  ];  

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  };

  /*** 搜尋 ***/
  const { Search } = Input;
  const searchOnChange = (event) => {
    const val = event?.target?.value || "";
    setSearchString(val);
  }
  const onSearch = () => {
    console.log(currentPage);
    requestLineMessages();
    setIsEditMode([-1, false]); // 返回非編輯模式
  }

  /*** 查詢LineMessages ***/
  const requestLineMessages = async() => {
    const url = apiURL + "/api/message/v1/chat-tags?" + new URLSearchParams({
      filter: searchString,
      // limit: numberPerPage,
      // offset: currentPage-1
    });
    console.log(url);
    console.log(searchString);
    console.log(numberPerPage);
    console.log(currentPage);
    const response = await fetch(
      url,
      {
        headers: {
          Accept: "application/json",
        },
      }
    )
    .then((res) => res.json())
    .catch((err) => {
      alert(`訊息查詢失敗！\n${err}`);
      throw new Error("RequestLineMessages failed");
    });
    console.log(response);
    setLineMessagesDataArray(response); 
    // setUpdateFieldsOnChangeState(response); 
    // try {
    //   const response = await axios.get({
    //     method: 'get',
    //     url: url,
    //     responseType: 'json',
    //   });
    //   console.log(response);
    // } catch (error) {
    //   console.error(error);
    // }
  }
  useEffect(() => {
    requestLineMessages();
  }, []);
  useEffect(() => {
    console.log(lineMessagesDataArray);
  }, [lineMessagesDataArray]);

  /*** 分頁切換 ***/
  const changePage = (pageNumber) => {
    console.log('Page: ', pageNumber);
    setCurrentPage(pageNumber);
    setIsEditMode([-1, false]); // 返回非編輯模式
  };
  const clickLineMessagesMenuItem = (e) => {
    console.log('click ', e);
    const key = e?.key || "";
    if (key.includes("LineMessagesRead")) {
      setLineMessagesCRUD("LineMessagesRead");
    } else if (key.includes("LineMessagesAdd")) {
      setLineMessagesCRUD("LineMessagesAdd");
    } else if (key.includes("LineMessagesUpdate")) {
      setLineMessagesCRUD("LineMessagesUpdate");
    } else if (key.includes("LineMessagesDelete")) {
      setLineMessagesCRUD("LineMessagesDelete");
    } else {
      setLineMessagesCRUD("");
    }
  };    

  /*** 限制搜尋數量 ***/
  const [value, setValue] = useState(50);

  /*** 更新欄位onChange ***/
  const updateFieldsOnChange = (e) => {
    e.preventDefault();
    // const value = e?.value || "";
    const name = lineMessagesNameRef?.current?.input?.value || "";
    const category = lineMessagesCategoryRef?.current?.input?.value || "";
    const description = lineMessagesDescriptionRef?.current?.input?.value || "";
    const priority = parseInt(lineMessagesPriorityRef?.current?.input?.value) || 0;
    const data = { name, category, description, priority };
    console.log(lineMessagesNameRef);
    console.log(lineMessagesNameRef.current);
    console.log(value);
    console.log(data);
    setUpdateFieldsOnChangeState(data);
  }

  /*** 更新訊息 ***/
  const updateMessages = async( e, id ) => {
    e.preventDefault();
    const url = apiURL + "/api/message/v1/chat-tags/" + id;
    console.log(url);
    console.log(updateFieldsOnChangeState);
    const response = await fetch(
      url,
      {
        method: 'PUT',
        body: JSON.stringify(updateFieldsOnChangeState),
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
      }
    )
    .then((res) => res.json())
    .catch((err) => {
      alert(`訊息更新失敗！\n${err}`);
      throw new Error("Update Messages failed");
    });
    console.log(JSON.stringify(response));
    const responseId = response?._id || "";
    if( !! responseId ) {
      if( Object.values(lineMessagesDataArray).length > 0 ) {
        for( let el of lineMessagesDataArray ) {
          if( Object.values(el).includes(id) ) {
            el['name'] = await response?.name || "";
            el['category'] = await response?.category || "";
            el['description'] = await response?.description || "";
            el['priority'] = await response?.priority || 0;
            alert(`訊息內容已更新！\n( ID：${id} )`);
          }
        }
        setIsEditMode([-1, false]); // 返回非編輯模式
      }
    }
  }

  /*** 新增欄位onChange ***/
  const addFieldsOnChange = (e) => {
    e.preventDefault();
    const fieldId = e?.target?.id || "";
    const value = e?.target?.value || "";
    if( !! fieldId ) {
      console.log(fieldId);
      console.log(value);
      switch(fieldId) {
        case "MessagesName":
          setAddFieldsOnChangeState({...addFieldsOnChangeState, ['name']: value});
          break;
        case "MessagesCategory":
          setAddFieldsOnChangeState({...addFieldsOnChangeState, ['category']: value});
          break;
        case "MessagesDescription":
          setAddFieldsOnChangeState({...addFieldsOnChangeState, ['description']: value});
          break;
        case "MessagesPriority":
          const intValue = parseInt(value);
          setAddFieldsOnChangeState({...addFieldsOnChangeState, ['priority']: intValue});
          break;
      }
    }
  }
  useEffect(() => {
    console.log(addFieldsOnChangeState);
  }, [addFieldsOnChangeState]);

  /*** 儲存最近新增的訊息 ***/
  // const recentMessagesStore = (attrObj) => {
  //   const recentMessagesData = window.localStorage.getItem('rctatt') ?? "";
  //   let recentMessagesArray = !! recentMessagesData ? JSON.parse(recentMessagesData) : []; 
  //   recentMessagesArray.push(attrObj);
  //   const messagesDataJson = JSON.stringify(recentMessagesArray);
  //   window.localStorage.setItem('rctatt', messagesDataJson);
  // }

  /*** 檢視訊息 ***/
  const seeMessages = async(e, id, name) => {
    e.preventDefault();
    console.log(id);
    console.log(name);
    const attrObj = {
      key: id, 
      label: name,
      // onClick: ({ item, key, keyPath, domEvent }) => {
      //   console.log(key);
      // }
    };
    // recentMessagesStore(attrObj);
    // const url = apiURL + "/api/message/v1/chat-tags/";
    // console.log(url);
    // console.log(addFieldsOnChangeState);
    // const response = await fetch(
    //   url,
    //   {
    //     method: 'POST',
    //     body: JSON.stringify(addFieldsOnChangeState),
    //     headers: {
    //       "Content-Type": "application/json",
    //       "Accept": "application/json",
    //     },
    //   }
    // )
    // .then((res) => res.json())
    // .catch((err) => {
    //   alert(`標籤新增失敗！\n${err}`);
    //   throw new Error("Add Messages failed");
    // });
    // console.log(JSON.stringify(response));
    // const responseId = response?._id || "";
    // const responseStatus = response?.statusCode || "";
    // if( ! responseId ) {
    //   const responseErrorMsg = response?.message || "";
    //   if( responseStatus === 500 ) {
    //     alert(`標籤新增失敗！\n( 標籤名稱已存在或標籤內容未輸入完整 )`);
    //   } else if( responseStatus === 400 ) {
    //     alert(`標籤新增失敗！\n( 標籤名稱已存在或標籤內容未輸入完整 )`);
    //   } else {
    //     alert(`標籤新增失敗！\n${responseErrorMsg}`);
    //   }
    //   return false;
    // } else {
    //   const name = await response?.name || "";
    //   const category = await response?.category || "";
    //   const description = await response?.description || "";
    //   const priority = await response?.priority || 0;
    //   const data = { _id: responseId, name, category, description, priority };
    //   tagsDataArray.push(data);
    //   alert(`【${name}】標籤已新增！\n( ID：${responseId} )`);
    //   setAddFieldsOnChangeState({}); // 清空欄位
    //   requestMessages(name); // 查詢已新增的標籤
    //   setAfterAddShow({"display":"block"});  // 顯示已新增的標籤
    // }
  }  

  /*** 查詢Messages ***/
  const requestMessages = async(searchString) => {
    const url = apiURL + "/api/message/v1/chat-tags?" + new URLSearchParams({
      filter: searchString,
    });
    console.log(url);
    console.log(searchString);
    const response = await fetch(
      url,
      {
        headers: {
          Accept: "application/json",
        },
      }
    )
    .then((res) => res.json())
    .catch((err) => {
      alert(`標籤查詢失敗！\n${err}`);
      throw new Error("RequestMessages failed");
    });
    console.log(response);
    setLineMessagesDataArray(response); 
  }
  
  /*** 刪除訊息 ***/
  const deleteMessages = async( e, id, name ) => {
    e.preventDefault();
    if( window.confirm(`確定要刪除【${name}】訊息？\n( ID：${id} )`) ) {
      const url = apiURL + "/api/message/v1/chat-tags/" + id;
      console.log(url);
      console.log(updateFieldsOnChangeState);
      const response = await fetch(
        url,
        {
          method: 'DELETE',
          headers: {
            "Accept": "*/*",
          },
        }
      )
      // .then((res) => res.json())
      .then((res) => {
        console.log(res);
        if( res.ok ) {
          for( let [index, el] of lineMessagesDataArray.entries() ) {
            if( Object.values(el).includes(id) ) {
              lineMessagesDataArray.splice(index, 1);
              alert(`【${el['name']}】訊息已刪除！\n（ID：${id}）`);
            }
          }
          setIsEditMode([-1, false]); // 返回非編輯模式
        } else {
            alert(`訊息刪除失敗！`);
            throw new Error("Delete Messages failed");
        }
      })
      .catch((err) => {
        alert(`訊息刪除失敗！\n${err}`);
        throw new Error("Delete Messages failed");
      });
      // const responseId = response?._id || "";
      // if( !! responseId ) {
      // }
    } else {
      return;
    }
  }

  /*** 分頁顯示設定 ***/
  const data = [ // 樣板資料
    {
      _id: "001",
      name: "測試訊息1",
      category: "訊息類別",
      description: "訊息描述",
      priority: 0,
      __v: 0
    },
    {
      _id: "002",
      name: "測試訊息2",
      category: "訊息類別",
      description: "訊息描述",
      priority: 0,
      __v: 0
    },
    {
      _id: "003",
      name: "測試訊息3",
      category: "訊息類別",
      description: "訊息描述",
      priority: 0,
      __v: 0
    },
    {
      _id: "004",
      name: "測試訊息4",
      category: "訊息類別",
      description: "訊息描述",
      priority: 0,
      __v: 0
    },
    {
      _id: "004",
      name: "測試訊息4",
      category: "訊息類別",
      description: "訊息描述",
      priority: 0,
      __v: 0
    },
    {
      _id: "004",
      name: "測試訊息4",
      category: "訊息類別",
      description: "訊息描述",
      priority: 0,
      __v: 0
    },
    {
      _id: "004",
      name: "測試訊息4",
      category: "訊息類別",
      description: "訊息描述",
      priority: 0,
      __v: 0
    },
    {
      _id: "004",
      name: "測試訊息4",
      category: "訊息類別",
      description: "訊息描述",
      priority: 0,
      __v: 0
    },
    {
      _id: "004",
      name: "測試訊息4",
      category: "訊息類別",
      description: "訊息描述",
      priority: 0,
      __v: 0
    },
    {
      _id: "004",
      name: "測試訊息4",
      category: "訊息類別",
      description: "訊息描述",
      priority: 0,
      __v: 0
    },
    {
      _id: "004",
      name: "測試訊息4",
      category: "訊息類別",
      description: "訊息描述",
      priority: 0,
      __v: 0
    },
    {
      _id: "004",
      name: "測試訊息4",
      category: "訊息類別",
      description: "訊息描述",
      priority: 0,
      __v: 0
    },
    {
      _id: "004",
      name: "測試訊息4",
      category: "訊息類別",
      description: "訊息描述",
      priority: 0,
      __v: 0
    }
  ];


  useEffect(()=>{
    console.log(isEditMode)
    console.log(isEditMode[0])
    console.log(isEditMode[1])
  },[isEditMode]);
  return (
    <Layout id="lineMessagesLayout" className={style.lineMessagesLayout}>
      {/* <Content className={style.lineMessagesOuterContent}>
        <Tabs
          className={style.lineMessagesTabs}
          onChange={onChange}
          type="card"
          items={new Array(3).fill(null).map((_, i) => {
            const id = String(i + 1);
            return {
              label: `Tab ${id}`,
              key: id,
              children:         
                <Content className={`${style.lineMessagesContent} ${style.lineMessagesContentTable}`}>
                  <Table 
                    className={style.lineMessagesTable}
                    columns={lineMessagesHeader} 
                    expandable={{
                      expandedRowRender: (dataEl) => <p style={{ margin: 0 }}>{dataEl.content}</p>,
                      rowExpandable: (dataEl) => !! dataEl.content,
                    }}
                    dataSource={lineMessagesDataArray} 
                    onChange={onChange} 
                    scroll={{ y: 100 }}
                  />
                </Content>,
            };
          })}
        />
      </Content> */}
      {/* <div className={`${ComponentStyle.title}`}><h1>LineMessages</h1></div> */}
      <Content className={style.lineMessagesOuterContent}>
        <Content className={style.lineMessagesContent}>
          <section id={"lineMessagesSearchSec"} className={style.lineMessagesSearchSec}>
            <div className={style.lineMessagesSearchDiv}>
              <Search className={`${style.lineMessagesInput} ${style.lineMessagesSearch}`} addonBefore={<span className={style.lineMessagesSearchTitle}>訊息查詢</span>} placeholder="請輸入欲查詢的訊息名稱" allowClear size="large" onChange={(e) => {searchOnChange(e)}} onSearch={onSearch} enterButton />
            </div>
            <List
              className={style.lineMessagesList}
              locale={{}}
              pagination={{ 
                position: "bottom", 
                align: "center",
                hideOnSinglePage: true,
                showQuickJumper: true,
                // total: 50,
                current: currentPage,
                pageSize: numberPerPage,
                onChange: changePage
              }}
              dataSource={lineMessagesDataArray}
              renderItem={(item, index) => (
                Object.keys(lineMessagesDataArray[0]).includes('_id') ? (
                  <List.Item>
                    <List.Item.Meta
                      className={style.lineMessagesListItemMeta}
                      avatar={
                        <Tag className={style.lineMessagesTagIcon} color="geekblue">{item.name}</Tag>
                      }
                      title={
                        <div className={`${style.lineMessagesDescriptionItem} ${style.lineMessagesDescriptionItemTitle}`}>
                          <span className={style.lineMessagesDescriptionID}>{"訊息ID："}</span><span className={style.lineMessagesDescriptionText}>{item._id}</span>
                          <div className={style.lineMessagesEditAndDelIconDiv}>
                            <EyeOutlined className={style.lineMessagesSeeIcon} onClick={(e) => { seeMessages(e, item._id, item.name) }} />
                            {/* <PlusOutlined className={style.lineMessagesAddIcon} onClick={(e) => { addMessages(e, item._id, item.name) }} /> */}
                            {/* <EditOutlined className={style.lineMessagesEditAndDelIcon} onClick={(e) => { setIsEditMode([index, true]) }} /> */}
                            {/* <EditFilled className={style.lineMessagesAddIcon} /> */}
                            {/* <DeleteOutlined className={style.lineMessagesEditAndDelIcon} onClick={(e) => { deleteMessages(e, item._id, item.name) }} /> */}
                            {/* <DeleteFilled className={style.lineMessagesAddIcon} /> */}
                          </div>
                        </div>
                      }
                      description={
                        <div className={style.lineMessagesDescriptionDiv}>
                          <div className={style.lineMessagesDescriptionItem}>
                            <span className={style.lineMessagesDescriptionLabel}>{"訊息名稱："}</span>
                            <span className={style.lineMessagesDescriptionText}>{item.name}</span>
                          </div>
                          <div className={style.lineMessagesDescriptionItem}>
                            <span className={style.lineMessagesDescriptionLabel}>{"訊息類別："}</span>
                            <span className={style.lineMessagesDescriptionText}>{item.category}</span>
                          </div>
                          <div className={style.lineMessagesDescriptionItem}>
                            <span className={style.lineMessagesDescriptionLabel}>{"訊息描述："}</span>
                            <span className={style.lineMessagesDescriptionText}>{item.description}</span>
                          </div>
                          <div className={style.lineMessagesDescriptionItem}>
                            <span className={style.lineMessagesDescriptionLabel}>{"優先權："}</span>
                            <span className={style.lineMessagesDescriptionText}>{item.priority}</span>
                          </div>
                          {/* <div className={style.lineMessagesDescriptionItem}>
                            <span className={style.lineMessagesDescriptionLabel}>{"其他資訊："}</span><span className={style.lineMessagesDescriptionText}>{item.__v}</span>
                          </div> */}
                        </div>
                      }
                    />
                  </List.Item>
                ) : (
                  <></>
                )
              )}
            />
          </section>
        </Content>
      </Content>
    </Layout>
  )
}

export default LineMessages