import React, {useState} from 'react'
import style from './styles/Line.module.css'
import ComponentStyle from '../styles/Components.module.css'
import { Layout, Menu } from 'antd';
import {
  GlobalOutlined,
  WifiOutlined,
  AimOutlined,
  LayoutOutlined,
  BarsOutlined
} from '@ant-design/icons';

/*** Import Line Component ***/
import LineBroadcast from './components/LineBroadcast';
import LineNarrowcast from './components/LineNarrowcast';
import LineCastByTags from './components/LineCastByTags';
import LineTemplate from './components/LineTemplate';
import LineMessages from './components/LineMessages';

function LINE() {
  const {Content} = Layout;
  const [castType, setCastType] = useState('Broadcast');
  const menuItems = [
    {
      label: '廣播模式',
      key: 'Broadcast',
      icon: <GlobalOutlined />,
    },
    {
      label: '窄播模式',
      key: 'Narrowcast',
      icon: <WifiOutlined />,
      // disabled: true,
    },
    {
      label: '依標籤傳訊給特定對象',
      key: 'CastByTags',
      icon: <AimOutlined />,
      // children: [
      //   {
      //     type: 'group',
      //     label: 'Item 1',
      //     children: [
      //       {
      //         label: 'Option 1',
      //         key: 'setting:1',
      //       },
      //       {
      //         label: 'Option 2',
      //         key: 'setting:2',
      //       },
      //     ],
      //   },
      //   {
      //     type: 'group',
      //     label: 'Item 2',
      //     children: [
      //       {
      //         label: 'Option 3',
      //         key: 'setting:3',
      //       },
      //       {
      //         label: 'Option 4',
      //         key: 'setting:4',
      //       },
      //     ],
      //   },
      // ],
    },
    {
      label: '訊息模板設定',
      key: 'Template',
      icon: <LayoutOutlined />,
      // disabled: true,
    },
    // {
    //   label: (
    //     <a href="https://ant.design" target="_blank" rel="noopener noreferrer">
    //       Navigation Four - Link
    //     </a>
    //   ),
    //   key: 'alipay',
    // },
    {
      label: '訊息一覽',
      key: 'Messages',
      icon: <BarsOutlined />,
      // disabled: true,
    }
  ];
    /*** 點選Menu Item ***/
    const clickCastTypeMenuItem = (e) => {
      console.log('click ', e);
      const key = e?.key || "";
      if (key.includes("Broadcast")) {
        setCastType("Broadcast");
      } else if (key.includes("Narrowcast")) {
        setCastType("Narrowcast");
      } else if (key.includes("CastByTags")) {
        setCastType("CastByTags");
      } else if (key.includes("Template")) {
        setCastType("Template");
      } else if (key.includes("Messages")) {
        setCastType("Messages");
      } else {
        setCastType("");
      }
    };    

  const onClick = (e) => {
    console.log('click ', e);
    setCastType(e.key);
  };    
  return (
    <Layout className={style.lineLayout}>
      <Menu style={{'borderLeft':'1px solid white'}} theme="dark" onClick={clickCastTypeMenuItem} selectedKeys={[castType]} mode="horizontal" items={menuItems} />
      <Content className={style.lineContent}>
        { castType === "Broadcast" ? (
          <Content id="Broadcast">
            <LineBroadcast id="LineBroadcast"></LineBroadcast>
          </Content>
        ) : castType === "Narrowcast" ? (
          <Content id="Narrowcast">
            <LineNarrowcast id="LineNarrowcast"></LineNarrowcast>
          </Content>
        ) : castType === "CastByTags" ? (
          <Content id="CastByTags">
            <LineCastByTags id="LineCastByTags"></LineCastByTags>
          </Content>
        ) : castType === "Template" ? (
          <Content id="Template">
            <LineTemplate id="LineTemplate"></LineTemplate>
          </Content>
        ) : castType === "Messages" ? (
          <Content id="Messages">
            <LineMessages id="LineMessages"></LineMessages>
          </Content>
        ) : (
          <></>
        )}
      </Content>
    </Layout>
  )
}

export default LINE