import React, { useState, useEffect, useContext } from 'react';
// import { GlobalAdminSocket } from "../../SocketIO/Admin/SocketAdminConnect";
import ReactDOM from 'react-dom/client';
import { SideBar } from 'react-chat-elements';
import style from './styles/admin.module.css';
import './styles/ant.css';
import liff from '@line/liff/dist/lib';
import jwt_decode from "jwt-decode";

/* Ant UI */
import {
  UserOutlined,
  TagsOutlined,
  WechatOutlined,
  WhatsAppOutlined,
  MessageFilled,
  FacebookFilled,
  MailFilled,
  SlackOutlined,
  EnvironmentFilled,
  LogoutOutlined,
  Icon
} from '@ant-design/icons';
import { Breadcrumb, Layout, Menu, theme, FloatButton, Button } from 'antd';
import { ReactComponent as LineIcon } from './imgs/line.svg'; // '*.svg' 文件路径
import { ReactComponent as WordpressIcon } from './imgs/wordpressLogo.svg'; // '*.svg' 文件路径
// ReactDOM.createRoot(mountNode).render(<Icon component={MessageSvg} />);

/* Components */
import Users from './components/Users/Users';
import Wordpress from './components/Wordpress/Wordpress';
import Tags from './components/Tags/Tags'
import ChatRoom from './components/ChatRoom/ChatRoom';
import LINE from './components/LINE/LINE';
import Wechat from './components/Wechat/Wechat';
import WhatsApp from './components/WhatsApp/WhatsApp';
import Facebook from './components/Facebook/Facebook';
import Email from './components/Email/Email';
import Slack from './components/Slack/Slack';
import Map from './components/Map/Map';

function Admin(props) {
  const [reRender, setReRender] = useState(false);
  // const globalAdminSocketContext = useContext(GlobalAdminSocket);
  // const [globalAdminSocket, setGlobalAdminSocket] = useState(globalAdminSocketContext);
  const loginToken = window.localStorage.getItem('loginToken') ? window.localStorage.getItem('loginToken') : "";
  const [collapsed, setCollapsed] = useState(false);
  const [showComponent, setShowComponent] = useState(""); // 點選Sidebar標籤可顯示不同組件畫面
  const {Header, Content, Footer, Sider} = Layout;
  const [device,setDevice]=useState("PC"); // RWD setting

  const items = [
    getItem('使用者', 'users', <UserOutlined className={`${style.adminIcon}`}/>),
    getItem('Wordpress', 'wordpress', <WordpressIcon className={`${style.adminIcon}`} width={"14px"} fill={"#DDD"}/>),
    getItem('標籤', 'tags', <TagsOutlined  className={`${style.adminIcon}`}/>),
    getItem('聊天室', 'chatroom', <MessageFilled className={`${style.adminIcon}`} />),
    getItem('LINE', 'line', <LineIcon className={`${style.adminIcon}`} width={"14px"} fill={"#DDD"}/>),
    getItem('Wechat', 'wechat', <WechatOutlined className={`${style.adminIcon}`} />),
    getItem('WhatsApp', 'whatsapp', <WhatsAppOutlined className={`${style.adminIcon}`} />),
    getItem('Facebook', 'facebook', <FacebookFilled className={`${style.adminIcon}`} />),
    getItem('Email', 'email', <MailFilled className={`${style.adminIcon}`} />),
    getItem('Slack', 'slack', <SlackOutlined className={`${style.adminIcon}`} />),
    getItem('Map', 'map', <EnvironmentFilled className={`${style.adminIcon}`} />),
    getItem('登出', 'logout', <LogoutOutlined className={`${style.adminIcon}`} />)
  ];  

  function getItem( label, key, icon, children ) {
    return { key, icon, children, label };
  };  

  /*** 登出 ***/
  const logout = () => {
    // 如果FB物件存在，且為登入中，將FB登出
    if( !! window.FB ) {
      window.FB.getLoginStatus(function (response) {
        if(response.status==='connected') {
          window.FB.logout(function (response) {
            // console.log('handleFBLogout', response);
          });
        }
      });
    }    
    const isInClient = liff.isInClient();
    if( ! isInClient ) { // 以外部伺服器開啟網頁
      // 如果LINE有登入，執行LINE登出
      const isLoggedIn = liff.isLoggedIn();
      if( isLoggedIn ) {
        liff.logout();
        window.localStorage.setItem('LIFF_STORE', ''); 
      }
      // 清除loginToken
      window.localStorage.removeItem('loginToken');
      // window.location.assign( window.location.protocol + "//" + window.location.host );
      if ( window.location.pathname.toLowerCase().indexOf('grantmembership') > -1 ) {
        window.location.href = '/GrantMembership';
      } else {
        window.location.href = '/AdminLogin';
      }
    } else { // 以Liff開啟網頁
      window.localStorage.removeItem('username');
      window.localStorage.removeItem('smsVeriStatus');
      window.localStorage.removeItem('phoneNumber');
      window.localStorage.removeItem('url');
      if( window.confirm("此頁面由LINE預設瀏覽器開啟，無法登出LINE。 請問是否要關閉LINE瀏覽器？") ) {
        liff.closeWindow(); // 關閉Liff
      } else {
        if ( window.location.pathname.toLowerCase().indexOf('grantmembership') > -1 ) {
          window.location.href = '/GrantMembership';
        } else {
          window.location.href = '/AdminLogin';
        }
      }
      // window.location.href = '/?openExternalBrowser=1';
    }
  } /*** END 登出 ***/

  /*** RWD ***/
  const handleRWD=()=>{
    if( window.innerWidth > 768 ) {
      setDevice("PC");
    } else if( window.innerWidth > 576 ) {
      setDevice("tablet");
    } else {
      setDevice("mobile");
      setCollapsed(true);
    }
  }
  useEffect(()=>{
    window.addEventListener('resize',handleRWD);
    handleRWD(); // Triger on first time
    return(()=>{
      window.removeEventListener('resize',handleRWD);
    })}, []
  ); /*** END RWD ***/

  /*** 檢視使用者權限 ***/
  useEffect(() => {
    var loginToken = window.localStorage.getItem("loginToken");
    var decoded = jwt_decode(loginToken);
    console.log(decoded);
  }, []);
  /*** END　檢視使用者權限 ***/
  
  // const [scrollTopPx, setScrollTopPx] = useState(0);
  const [scrollTopBtnShow, setScrollTopBtnShow] =useState(false);
  const getChatRoomScrollPx = (event) => {
    const chatRoomScrollPx = event.currentTarget.scrollTop;
    const screenWidth = window?.screen?.width || 2048;
    // console.log(chatRoomScrollPx);
    if( chatRoomScrollPx > 400 && screenWidth < 992 ) {
      setScrollTopBtnShow(true);
    } else {
      setScrollTopBtnShow(false);
    }
    // console.log(scrollTopBtnShow);
  }
  const setChatRoomScrollPx = (event) => {
    const chatRoomDom = document.getElementById("ChatRoom");
    chatRoomDom.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  // useEffect(() => {
  //   console.log(scrollTopPx);
  // }, [scrollTopPx]);

  return (
    <>
    {/* <GlobalAdminSocket.Provider value={[globalAdminSocket, setGlobalAdminSocket]}> */}
      { !! loginToken ? (
        <main id="admin-main" className={`${style.adminMain}`}>
          {/* <nav id="admin-navbar" className={`${style.adminNavbar}`}><h1>Messenger主控台</h1></nav>
          <section id="admin-section" className={`${style.adminSection}`}>
              <sidebar id="admin-sidbar" className={`${style.adminSidebar}`}>
                  <div><h2>使用者</h2></div>
                  <div><h2>聊天室</h2></div>
                  <div><h2>LINE</h2></div>
                  <div><h2>Facebook</h2></div>
                  <div><h2>Email</h2></div>
              </sidebar>
              <article id="admin-content" className={`${style.adminContent}`}></article>
          </section> */}
          <Layout className={`${style.adminSiderLayout}`} style={{ minHeight: '100vh' }}>
            <Sider className={`${style.adminSider}`} collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
              <div style={{ height: 8, margin: 0, background: 'rgba(255, 255, 255, 0)' }}>功能列表</div>
              <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline" items={items} onClick={({ item, key, keyPath, domEvent })=>{
                if (key.includes("users")) {
                  setCollapsed(true);
                  setShowComponent("Users");
                } else if (key.includes("wordpress")) {
                  setCollapsed(true);
                  setShowComponent("Wordpress");
                } else if (key.includes("tags")) {
                  setCollapsed(true);
                  setShowComponent("Tags");
                } else if (key.includes("chatroom")) {
                  setCollapsed(true);
                  setShowComponent("ChatRoom");
                } else if (key.includes("line")) {
                  setCollapsed(true);
                  setShowComponent("LINE");
                } else if (key.includes("wechat")) {
                  setCollapsed(true);
                  setShowComponent("Wechat");
                } else if (key.includes("whatsapp")) {
                  setCollapsed(true);
                  setShowComponent("WhatsApp");
                } else if (key.includes("facebook")) {
                  setCollapsed(true);
                  setShowComponent("Facebook");
                } else if (key.includes("email")) {
                  setCollapsed(true);
                  setShowComponent("Email");
                } else if (key.includes("slack")) {
                  setCollapsed(true);
                  setShowComponent("Slack");
                } else if (key.includes("map")) {
                  setCollapsed(true);
                  setShowComponent("Map");
                } else if (key.includes("logout")) {
                  setCollapsed(true);
                  logout();
                } else {
                  setCollapsed(true);
                  setShowComponent("");
                }
              }}/>
              {/* <Menu theme="dark" defaultSelectedKeys={['0']} mode="inline" items={items} /> */}
            </Sider>
            <Layout className={`site-layout ${style.componentLatout} ${style.adminContentLayout}`}>
              <Content id={"AdminMainContent"} className={`${style.adminContent}`} > 
              {/* style={{ margin: '0 16px' }} */}
                { showComponent === "Users" ? (
                  <Content id={"Users"}><Users></Users></Content>
                ) : showComponent === "Wordpress" ? (
                  <Content id={"Wordpress"}><Wordpress></Wordpress></Content>
                ) : showComponent === "Tags" ? (
                  <Content id={"Tags"}><Tags></Tags></Content>
                ) : showComponent === "ChatRoom" ? (
                  <Content id={"ChatRoom"} onScroll={getChatRoomScrollPx}>
                    <ChatRoom></ChatRoom>
                    {scrollTopBtnShow ? (
                      <Button type="primary" size="small" className={style.adminChatRoomScrollTop} onClick={(e) => {setChatRoomScrollPx(e)}}>^</Button>
                    ):(
                      <></>
                    )}
                  </Content>
                ) : showComponent === "LINE" ? (
                  <Content id={"LINE"}><LINE></LINE></Content>
                ) : showComponent === "Wechat" ? (
                  <Content id={"Wechat"}><Wechat></Wechat></Content>
                ) : showComponent === "WhatsApp" ? (
                  <Content id={"WhatsApp"}><WhatsApp></WhatsApp></Content>
                ) : showComponent === "Facebook" ? (
                  <Content id={"Facebook"}><Facebook></Facebook></Content>
                ) : showComponent === "Email" ? (
                  <Content id={"Email"}><Email></Email></Content>
                ) : showComponent === "Slack" ? (
                  <Content id={"Slack"}><Slack></Slack></Content>
                ) : showComponent === "Map" ? (
                  <Content id={"Map"}><Map></Map></Content>
                ) : (
                  <></>
                )}
              </Content>
              {/* <Footer className={style.adminFooter}></Footer> */}
            </Layout>
          </Layout>        
        </main>
      ) : (
        <></>
      )}
    {/* </GlobalAdminSocket.Provider> */}
    </>
  )
}

export default Admin