import React, { useState, useContext, useEffect } from 'react'
import { socketConnect } from 'socket.io-react';
import CryptoJS  from 'crypto-js';
import Compressor from "compressorjs";
import Emoji from '../../Component/Emoji/Emoji';

/*** CSS ***/
import style from "./styles/SocketCustomer.module.css";

/*** ANT UI ***/
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';
import { Button as AntButton, Upload, Form } from 'antd';

/*** React Chat Elements UI ***/
import { Input, Button, Dropdown } from 'react-chat-elements'

/*** Import Context Parameters ***/
import { GlobalSocket } from './SocketCustomerConnect';
import { GlobalUserData } from '../../Pages/Customer/Customer';

function SocketCustomerSendMessage(props) {
    // const globalSocket = useContext(GlobalSocket);
    const {globalSocket} = props;
    // console.log({GlobalSocket});
    console.log({globalSocket});
    const globalUserData = useContext(GlobalUserData);
    // console.log({GlobalUserData});
    // console.log({globalUserData});
    // const [typingText,setTypingText] =useState([]);
    const [textMsg, setTextMsg] =useState({});
    const [formStyle, setFormStyle] = useState({"marginTop": "-22px"});
    const [uploadBtnStyle, setUploadBtnStyle] = useState({"fontSize": "20px"});
    const [channelTypeState, setChannelTypeState] = useState("聊天室");
    const [showEmojiModal, setShowEmojiModal] = useState(false); // emoji顯示與否

    const allowedPhotoTypes = ['image/png', 'image/jpeg', 'image/gif']; // 允許上傳的圖片格式
    const mountId = "customerChatRoomSendSec"; // emoji要掛載的位置ID

    /*** 取得加密的使用者資料 ***/
    let defaultUserData = null;
    const[userData, setUserData] = useState({});
    const lineAppId = process.env.REACT_APP_LINE_APP_ID;
    const cpo = window.localStorage.getItem("cpo") ? window.localStorage.getItem("cpo") : "";
    const accessToken = window.localStorage.getItem( "LIFF_STORE:" + lineAppId + ":accessToken" ) ? window.localStorage.getItem( "LIFF_STORE:" + lineAppId + ":accessToken" ) : "";    
    const loginToken = window.localStorage.getItem("loginToken");
    const getCipherUserData = () => {
        if( !! accessToken && !! cpo ) {
            try {
                const userDataJson  = CryptoJS.AES.decrypt( cpo, `${accessToken}` );
                // console.log(userDataJson);
                const userDataUTF8 = userDataJson?.toString(CryptoJS.enc.Utf8);
                // const userDataUTF8 = CryptoJS.enc.Utf8.parse(userDataJson);
                const userDataDecode = JSON.parse( userDataUTF8 );
                // setUserData(userDataDecode);

                // const userDataJson  = CryptoJS.AES.decrypt(cpo, accessToken);
                // const userDataDecode = JSON.parse(userDataJson?.toString(CryptoJS.enc.Utf8));

                setUserData( userData => ({...userData, userDataDecode}));
                // console.log({userData});
                defaultUserData = userDataDecode;
                // console.log({defaultUserData});
                return userDataDecode;
            } catch (e) {
                console.log(e);
                return false;
            }   
        }   
    }
    useEffect(() => {
        getCipherUserData();
    }, [cpo])
    /*** END 取得加密的使用者資料 ***/      

    /*** 使用者在訊息欄輸入訊息，將訊息存入State ***/
    const changeFields = (event, emojiText) => {
        event?.preventDefault();
        const val = event?.target?.value || "";
        console.log({ val });
        /* 取得的使用者資料預設格式
            {
                userId: profile.userId,
                userStatus: "【一般使用者】",
                firstName: "",
                lastName: profile.displayName,
                email: "",
                phone: "",
                lineId: profile.userId,
                isLineBind: false,
                avatar: profile.pictureUrl,
                statusMessage: profile.statusMessage
            }
        */
        // const lastName = defaultUserData?.lastName || "";
        // setTypingText({
        //     ...typingText, 
        //     position: "right",
        //     type: "text",
        //     title: lastName,
        //     text: val,  
        // });
        /* socket on data format */
        // {
        //     "type": "text",
        //     "text": "string",
        //     "data": {
        //       "type": "string",
        //       "name": "string",
        //       "uri": "string",
        //       "buffer": {}
        //     }
        // }         
        // if( typeof val === "string" ) {           
        //     setTextMsg({
        //         ...textMsg, 
        //         // position: "right",
        //         type: "text",
        //         text: val,  
        //     });
        // }
        if( typeof val === "string" ) {
            // console.log("string");
            // const photoUriRegex = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/; // Photo URL Regex
            // const photoUriRegexGI = /https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b((?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*))/i;
            // const photoNameRegexGI = /[^\/]+(?=\/?$)/i;
            // const photoUriExtractArray = val?.match(photoUriRegexGI) || "";
            // const photoUriExtract = photoUriExtractArray[0] ?? "";
            // const photoNameMatch = photoUriExtractArray[1] ?. match(photoNameRegexGI);
            // const photoName = photoNameMatch ? photoNameMatch[0] : "photo-" + new Date();
            // console.log(photoUriRegex.test(val));
            // console.log({photoUriRegex});
            // console.log({photoUriExtract});
            // console.log({photoName});
            // if( photoUriRegex.test(val) || !! photoUriExtract ) { // 若為圖片URI格式
            //     setTextMsg({
            //         ...textMsg, 
            //         type: "text",
            //         text: val,  
            //         dataType: "photo",
            //         dataName: photoName,
            //         dataUri: photoUriExtract,  
            //     });
            // } else {
            if( ! emojiText ) {
                setTextMsg({
                    ...textMsg, 
                    type: "text",
                    text: val,  
                });
                console.log(textMsg);
                } else {
                setTextMsg({
                    ...textMsg, 
                    type: "text",
                    text: emojiText,  
                });
                // console.log(textMsg);
                }
            // }  
            console.log({textMsg});
        }        
    } /*** END 使用者在訊息欄輸入訊息，將訊息存入State ***/
    
    /*** 使用者點選送出訊息，將訊息以Socket Emit傳出 ***/
    const customerSendMessage = async(e) => {
        // // const userData = getCipherUserData();
        // // const userDataJson = JSON.stringify(userData);
        // // console.log({globalSocket});
        // e.preventDefault();
        // const sendText = textMsg?.text || "";
        // // const lastName = defaultUserData?.lastName || "";
        // // console.log({defaultUserData});
        // // console.log({lastName});
        // await globalSocket.emit('message', {
        //     type: 'text',
        //     text: sendText
        // })
        // // 完成送出訊息後，清空輸入欄位
        // setTextMsg({}); 
        // document.getElementById("socketCustomerSendMsgForm").reset();
        // //
        // // return;
        e.preventDefault();
        const channelType = "CHAT"; // 在ChatRoomAssign做設定
        const sendText = textMsg?.text || "";
        const type = textMsg?.type || "text";
        const dataType = textMsg?.dataType || "";
        const dataName = textMsg?.dataName || ""; 
        const dataUri = textMsg?.dataUri || "";
        const dataBuffer = textMsg?.dataBuffer || {};
        const userDataDefault = getCipherUserData();
        const data = {
            channelType: channelType,
            type: type,
            text: sendText,
            data: {
                type: dataType,
                name: dataName,
                uri: dataUri,
                // buffer: userDataDefault
            }
        }           
        console.log({data}); 
        const globalSocketConnected = globalSocket?.connected || false;
        if( globalSocketConnected && type === "text" ) {
            await globalSocket.emit('message', data );
        } else if( Object.keys(dataBuffer).length > 0 && !! channelType && !! type ) {
            syncCompressAndUpload( dataBuffer, channelType, type );
        }
        // 完成送出訊息後，重設訊息欄樣式
        setFormStyle({"marginTop": "-22px"});
        setUploadBtnStyle({"fontSize": "20px"});
        // 完成送出訊息後，清空輸入欄位
        setTextMsg({}); 
        document.getElementById("socketCustomerSendMsgForm").reset();        
    } /*** END 使用者點選送出訊息 ***/

    const clearInput = () => {
        setTextMsg({}); 
        document.getElementById("socketCustomerSendMsgForm").reset();
        // document.getElementById("chatRoomSendForm").getElementsByTagName("input")[0].value = "";
        // document.getElementById("chatRoomSendForm").getElementsByTagName("input")[1].value = "";
        // 完成送出訊息後，重設訊息欄樣式
        setFormStyle({"marginTop": "-22px"});
        setUploadBtnStyle({"fontSize": "20px"});
      } /*** END 管理者點選送出訊息 ***/

    /*** Normalizing File ***/
    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    } /*** END Normalizing File ***/

    /*** onChange時設定上傳檔案Data ***/
    const changeUploadFile = (fileItem) => {
        // document.getElementById("socketCustomerSendMsgForm").reset();
        document.getElementById("socketCustomerSendMsgForm").getElementsByTagName("input")[1].value = "";
        const fileList = fileItem?.fileList || [];
        const file = fileList[0]?.originFileObj || {};
        if( Object.keys(file).length > 0 ) {
            const fileName = file?.name || "未設定檔名";
            const fileType = file?.type || "未知的檔案類型";
            let type = "";
            if( fileType.indexOf("image/") > -1 && allowedPhotoTypes.includes(fileType) ) {
                type = "photo";
                setTextMsg({
                    ...textMsg, 
                    type: type,
                    text: fileName,
                    dataType: fileType,
                    dataName: fileName,
                    dataBuffer: file  
                });
                setFormStyle({"marginTop": "-65px"});
                setUploadBtnStyle({"display": "none"});
            } else if( fileType.indexOf("image/") > -1 ) {
                alert("不支援的圖片格式（僅支援jpg、png、gif格式）!");
                setTextMsg({});
                fileList.splice(fileList.indexOf(file), 1);
                setFormStyle({"marginTop": "-22px"});
                setUploadBtnStyle({"fontSize": "20px"});
            // } else if( fileType.indexOf("video/mp4") > -1 ) {
            //     type = "video";
            //     setTextMsg({
            //         ...textMsg, 
            //         type: type,
            //         text: fileName,
            //         dataType: fileType,
            //         dataName: fileName,
            //         dataBuffer: file  
            //     });
            //     setFormStyle({"marginTop": "-65px"});
            //     setUploadBtnStyle({"display": "none"});
            // } else if( fileType.indexOf("video/") > -1 ) {
            //     alert("不支援的影片格式（僅支援mp4格式）!");
            //     setTextMsg({});
            //     fileList.splice(fileList.indexOf(file), 1);
            //     setFormStyle({"marginTop": "-22px"});
            //     setUploadBtnStyle({"fontSize": "20px"});
            } else if( fileType.indexOf("video/") > -1 ) {
                type = "video";
                setTextMsg({
                    ...textMsg, 
                    type: type,
                    text: fileName,
                    dataType: fileType,
                    dataName: fileName,
                    dataBuffer: file  
                });
                setFormStyle({"marginTop": "-65px"});
                setUploadBtnStyle({"display": "none"});
            } else if( fileType.indexOf("audio/") > -1 ) {
                type = "audio";
                setTextMsg({
                    ...textMsg, 
                    type: type,
                    text: fileName,
                    dataType: fileType,
                    dataName: fileName,
                    dataBuffer: file  
                });
                setFormStyle({"marginTop": "-65px"});
                setUploadBtnStyle({"display": "none"});
            } else {
                type = "file";
                setTextMsg({
                    ...textMsg, 
                    type: type,
                    text: fileName,
                    dataType: fileType,
                    dataName: fileName,
                    dataBuffer: file  
                });
                setFormStyle({"marginTop": "-65px"});
                setUploadBtnStyle({"display": "none"});
            }
        } 
    }/*** END onChange時設定上傳檔案Data ***/

    /*** 壓縮上傳檔案 ***/
    const compressImg = async (file) => {
        const fileType = file?.type || "未知的檔案類型";
        if( fileType.indexOf("image/") > -1 && allowedPhotoTypes.includes(fileType) ) {
        return new Promise((resolve, reject) => {
            new Compressor(file, {
            quality: 0.6,
            success: (compressedResult) => {
                resolve(compressedResult);
            },
            error: (err) => {
                alert("錯誤，檔案壓縮失敗！");
                setTextMsg({});
                console.log(err.message);
                reject(false);
            },
            });
        });
        }
        return false;
    };/*** END 壓縮上傳檔案 ***/

    /*** 檔案上傳 ***/
    const uploadFile = async (file, channelType, messageType) => {
        console.log(file);
        const fileName = file?.name || "未設定檔名";
        const fileType = file?.type || "";
        const fileSize = file?.size || 0;
        const presignedPolicy = await fetch(
        `https://messanger.kid-pro.com/api/message/v1/upload-chat/presigned-policy?` +
            new URLSearchParams({
            name: fileName,
            fileType,
            fileSize,
            channelType,
            messageType,
            }),
        {
            headers: {
                Authorization: `Bearer ${loginToken}`,
            },
        }
        )
        .then((res) => res.json())
        .catch((err) => {
        alert("不支援的檔案名稱或檔案無法於此頻道上傳！");
            setTextMsg({});
            console.log(err);
            throw new Error(err);
        });
        const formData = new FormData();
        const policyFormData = presignedPolicy?.formData || {};
        Object.entries(policyFormData).forEach(([key, value]) => {
            formData.append(key, value);
        });
        formData.append("file", file);
        const policyPostURL = presignedPolicy?.postURL || "";
        fetch(policyPostURL, {
            method: "post",
            body: formData,
        }).then((res) => {
            // res.xml().then((xml) => console.log(xml));
            if( ! res.ok ) {
                setTextMsg({});
                console.log( "不支援的檔案格式或大小超過限制（10MB）!", res );
                throw new Error(res);
            }
        })
        .catch((err) => {
            alert("不支援的檔案格式或大小超過限制（10MB）!");
            setTextMsg({});
            console.log(err);
            throw new Error(err);
        });
    }; /*** END 檔案上傳 ***/

    /*** 同步壓縮並上傳 ***/
    const syncCompressAndUpload = async( file, channelType, msgType ) => {
        let fileTemp = file;
        const compImg = await compressImg(file);
        fileTemp = !! compImg ? compImg : file;
        uploadFile( fileTemp, channelType, msgType ).catch((err) => {
            alert("不支援的檔案格式或大小超過限制（10MB）!");
            setTextMsg({});
            console.log(err);
            throw new Error(err);
        });
    } /*** END 同步壓縮並上傳 ***/  

    /*** textMsg State改變時重新渲染 ***/
    useEffect(() => {
        return
    }, [textMsg]);
    /*** END textMsg State改變時重新渲染 ***/

    return (
        <div className={style.socketCustomerSendMsgDiv}>
            <Form id="socketCustomerSendMsgForm" className={style.socketCustomerSendMsgForm} style={formStyle} onFinish={() => {}}>
                {/* <div style={{'width': '100%', 'display': 'flex', 'flex-direction': 'column', 'color': '#FFF'}}>{JSON.stringify(textMsg)} */}
                <Form.Item
                    name="socketCustomerSendMsgFormItem"
                    valuePropName="list"
                    getValueFromEvent={normFile}
                    rules={[
                        {
                            required: true,
                            message: '請先上傳正確格式的檔案（jpg、png、gif）!',
                        },
                    ]}
                >
                    <Upload
                        beforeUpload={() => false}
                        action={window.location.protocol + "//" + window.location.hostname + ":" + window.location.port + "/upload"}
                        listType="picture"
                        maxCount={1}
                        onChange={(fileItem) => { changeUploadFile(fileItem); }}
                        onRemove={()=>{
                            // 完成送出訊息後，清空輸入欄位
                            setTextMsg({}); 
                            document.getElementById("socketCustomerSendMsgForm").reset();                            
                            setFormStyle({"marginTop": "-22px"});
                            setUploadBtnStyle({"fontSize": "20px"});
                            // console.log('onRemove');
                        }}
                        >
                        <UploadOutlined style={uploadBtnStyle}/>
                    </Upload>
                    <Input
                        id="sendMsg"
                        name="sendMsg"
                        className={`${style.socketCustomerInput}`}
                        placeholder="請在此輸入訊息..."
                        // multiline={true}
                        onChange={changeFields}
                        value={textMsg.text}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                customerSendMessage(e);
                            }
                        }}
                        rightButtons={
                            <div className={style.socketCustomerSendButtonDiv}>
                                <Emoji emojiClassName={style.socketCustomerEmoji} mountId={mountId} showEmojiModal={showEmojiModal} setShowEmojiModal={setShowEmojiModal} textMsg={textMsg} setTextMsg={setTextMsg} changeFields={changeFields}></Emoji>
                                <Button className={style.socketCustomerSendButton} text="送出訊息" title="送出訊息" type="button" onClick={(e) => {
                                    customerSendMessage(e);
                                }} />
                            </div>
                        } 
                    />
                    {/* </div> */}
                </Form.Item>
            </Form>
        </div>
    )
}

export default socketConnect(SocketCustomerSendMessage);