import React, { useEffect, useRef, useState } from 'react';
import style from './styles/Email.module.css'
import ComponentStyle from '../styles/Components.module.css'
import { PlusOutlined } from '@ant-design/icons';
import { InputRef } from 'antd';
import { Input, Tag, theme, Space } from 'antd';
import { TweenOneGroup } from 'rc-tween-one';

function Email() {
  const { CheckableTag } = Tag;
  const [selectedTags, setSelectedTags] = useState(['Books']);
  const tagsData = ['Movies', 'Books', 'Music', 'Sports'];

  const handleChange = (tag, checked) => {
    const nextSelectedTags = checked // 有選中新增此Tag，未選中則移除
      ? [...selectedTags, tag]
      : selectedTags.filter((el) => el !== tag); 
    console.log('You are interested in: ', nextSelectedTags);
    setSelectedTags(nextSelectedTags);
  };

  const { token } = theme.useToken();
  const [tags, setTags] = useState(['Tag 1', 'Tag 2', 'Tag 3']);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  const handleClose = (removedTag) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    console.log(newTags);
    setTags(newTags);
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (inputValue && tags.indexOf(inputValue) === -1) {
      setTags([...tags, inputValue]);
    }
    setInputVisible(false);
    setInputValue('');
  };
  useEffect(()=>{console.log(tags)}, [JSON.stringify(tags)]);

  const forMap = (tag) => {
    const tagElem = (
      <Tag
        closable
        onClose={(e) => {
          e.preventDefault();
          handleClose(tag);
        }}
      >
        {tag}
      </Tag>
    );
    return (
      <>
        <span style={{ marginRight: 8 }}>Categories:</span>
        <Space size={[0, 8]} wrap>
          {tagsData.map((tag) => (
            <>
              <Tag color="geekblue">{tag}</Tag>
              <CheckableTag
                className={style.emailCheckableTag}
                style={{"backgroundColor":"geekblue"}}
                key={tag}
                checked={selectedTags.includes(tag)}
                onChange={(checked) => handleChange(tag, checked)}
              >
                {tag}
              </CheckableTag>
            </>
          ))}
        </Space>
        <span key={tag} style={{ display: 'inline-block' }}>
          {tagElem}
        </span>
      </>
    );
  };

  const tagChild = tags.map(forMap);
  
  // // const [tagChild, setTagChild] = useState(null);
  // useEffect(()=>{
  //   tagChild = tags.map(forMap);
  //   // setTagChild(tags.map(forMap));
  // }, []);
  // useEffect(()=>{
  //   tagChild = tags.map(forMap);
  //   // setTagChild(tags.map(forMap));
  //   console.log(tagChild);
  // }, [JSON.stringify(tags)]);
  // useEffect(()=>{
  //   console.log(tagChild);
  // }, [JSON.stringify(tagChild)]);
  

  const tagPlusStyle = {
    background: token.colorBgContainer,
    borderStyle: 'dashed',
  };

  return (
    <div className={`${ComponentStyle.title}`}>
      <h1>Email</h1>
      {/* <div style={{ marginBottom: 16 }}>
        <TweenOneGroup
          enter={{
            scale: 0.8,
            opacity: 0,
            type: 'from',
            duration: 100,
          }}
          onEnd={(e) => {
            if (e.type === 'appear' || e.type === 'enter') {
              e.target.style = 'display: inline-block';
            }
          }}
          leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
          appear={false}
        >
          {tags.map(forMap)}
        </TweenOneGroup>
      </div>
      {inputVisible ? (
        <Input
          ref={inputRef}
          type="text"
          size="small"
          style={{ width: 78 }}
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputConfirm}
          onPressEnter={handleInputConfirm}
        />
      ) : (
        <Tag onClick={showInput} style={tagPlusStyle}>
          <PlusOutlined /> New Tag
        </Tag>
      )} */}
    </div>
  )
}

export default Email