import React, { useContext, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom'
// import Admin from './Pages/Admin/Admin';
import SocketAdminConnect from './SocketIO/Admin/SocketAdminConnect';
import SocketCustomerConnect from './SocketIO/Customer/SocketCustomerConnect';
import SocketCustomerConnectChatRoomLogin from './SocketIO/Customer/SocketCustomerConnectChatRoomLogin';
import CustomerLiffLogin from './Pages/Customer/components/CustomerLiffLogin';
import CustomerLiffRedir from './Pages/Customer/components/CustomerLiffRedir';
import AdminLogin from './UaaLogin/AppAdminLogin';

/*** UTM 設定 ***/
import { useCookies } from 'react-cookie'; // useCookies hook 用於記錄client_id
import ReactGA from "react-ga4"; // GA4
import ReactPixel from 'react-facebook-pixel'; // FB Pixel

// export const GlobalAdminSocket = React.createContext();

function App() {
  // const [globalAdminSocket, setGlobalAdminSocket] = useState({});
  // useEffect(()=>{console.log('Test in App.js')}, []);

  /*** UTM 設定 ***/
  /* GA4 設定 */
  const [cookies, setCookie] = useCookies(['cid']);
  const GA4ID = process.env.REACT_APP_GA4_ID;
  const GA4Name = process.env.REACT_APP_GA4_NAME;
  try{
    const cid = 'mkc-' + Math.floor( 1000000000 * Math.random() );
    setCookie( 'cid', cid );
    ReactGA.initialize( GA4ID, {
      gaOptions: {
        name: GA4Name,
        clientId: cid,
      }
    }); 
  }catch(e){
    console.log(e);
  }
  /* FB Pixel 設定 */
  const FbPixelID = process.env.REACT_APP_FB_PIXEL_ID;
  const options = {   // Pixel組態設定
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
  };
  ReactPixel.init(FbPixelID, {}, options);   
  /*** END UTM 設定 ***/

  return (
    <div className="App">
      {/* <GlobalAdminSocket.Provider value={[globalAdminSocket, setGlobalAdminSocket]}> */}
        <BrowserRouter>
          <Routes>
            <Route path="/AdminLogin" element={<AdminLogin />} />
            <Route path="/Admin" element={<SocketAdminConnect />} />
            <Route path="/" element={<SocketCustomerConnect />} />
            <Route path="/ChatRoomLogin" element={<SocketCustomerConnectChatRoomLogin />} />
            <Route path="/CustomerLiffLogin" element={<CustomerLiffLogin />} />
            <Route path="/CustomerLiffRedir" element={<CustomerLiffRedir />} />
          </Routes>
        </BrowserRouter>
      {/* </GlobalAdminSocket.Provider> */}
    </div>
  );
}

export default App;
