import React from 'react'
import style from "./styles/UserList.module.css"

/*** Import User List Components ***/
import UserListFilter from './UserListFilter'
import UserListFilter2 from './UserListFilter2'
import UserListContent from './UserListContent'

function UserList(props) {
  const {clickTimes, setClickTimes} = props;
  return (
    <>
      <section className={style.userListAssignSec}>
        <h6 className={style.userListAssignH6}>使用者列表</h6>
        <UserListFilter></UserListFilter>
        {/* <UserListFilter2></UserListFilter2> */}
      </section>
      <section className={style.userListContentSec}>
        <UserListContent clickTimes={clickTimes} setClickTimes={setClickTimes}></UserListContent>
      </section>
    </>
  )
}

export default UserList