import React, {useState, useEffect, useContext} from 'react'
import ChatRoomContainer from './components/ChatRoomContainer'
import UserDetail from './components/UserDetail'
import UserList from './components/UserList'
import style from './styles/ChatRoom.module.css'
import './styles/ChatRoomAnt.css'
import './styles/ChatRoomRce.css'
import { Col, Row, FloatButton } from 'antd';

/*** Import Socket Component ***/
import { GlobalAdminSocket } from '../../../../SocketIO/Admin/SocketAdminConnect'
import SocketAdminListening from '../../../../SocketIO/Admin/SocketAdminListening'

export const GlobalRoomId = React.createContext({});
export const GlobalJoinRoom = React.createContext({});
export const GlobalHasJoinedRoom = React.createContext({});
export const GlobalChannelType = React.createContext({});
export const GlobalUserInfo = React.createContext();

function ChatRoom(props) {
  const [globalRoomId , setGlobalRoomId] = useState("");
  const [globalJoinRoom , setGlobalJoinRoom] = useState(false);
  const [globalHasJoinedRoom , setGlobalHasJoinedRoom] = useState(false);
  const [globalChannelType, setGlobalChannelType] = useState('CHAT');
  const [globalUserInfo, setGlobalUserInfo] = useState({});
  useEffect(() => {
    console.log('globalRoomId', globalRoomId);
    console.log('globalJoinRoom', globalJoinRoom);
    console.log('globalHasJoinedRoom', globalHasJoinedRoom);
  }, [globalRoomId, globalJoinRoom, globalHasJoinedRoom])

  /* 依加入房間與否動態顯示Chat Room Container */
  const [showChatRoomContainer, setShowChatRoomContainer] = useState(style.chatRoomChatRoomContainerHidden);
  const [ULSpan, setULSpan] = useState(2);
  const [CRSpan, setCRSpan] = useState(0);
  const [UDSpan, setUDSpan] = useState(3);

  /* 依點選房間次數顯示欄位數 */
  const [clickTimes, setClickTimes] = useState(0);

  useEffect(() => {
    console.log(globalRoomId);
  }, [])
  useEffect(() => {
    console.log(globalRoomId);
  }, [globalRoomId])
  
  useEffect(() => {
    // if( Object.values(globalUserInfo).length > 0 ) 
    if( globalHasJoinedRoom && clickTimes > 1 ) { // 有加入房間顯示三欄，未加入房間顯示兩欄 //7 11 6
      // setShowChatRoomContainer(style.chatRoomChatRoomContainer);
      setULSpan(7);
      setCRSpan(10);
      setUDSpan(7);
    } else {
      // setShowChatRoomContainer(style.chatRoomChatRoomContainerHidden);
      setULSpan(12);
      setCRSpan(0);
      setUDSpan(12);
    }
  }, []);
  useEffect(() => {
    console.log(globalRoomId);
    if( globalHasJoinedRoom && clickTimes > 1 ) { // 有加入房間顯示三欄，未加入房間顯示兩欄
      // setShowChatRoomContainer(style.chatRoomChatRoomContainer);
      setULSpan(7);
      setCRSpan(10);
      setUDSpan(7);
    } else {
      // setShowChatRoomContainer(style.chatRoomChatRoomContainerHidden);
      setULSpan(12);
      setCRSpan(0);
      setUDSpan(12);
    }
  }, [globalRoomId, globalHasJoinedRoom, clickTimes]);

  return (
    <GlobalRoomId.Provider value={[globalRoomId, setGlobalRoomId]}>
      <GlobalJoinRoom.Provider value={[globalJoinRoom, setGlobalJoinRoom]}>
        <GlobalHasJoinedRoom.Provider value={[globalHasJoinedRoom, setGlobalHasJoinedRoom]}>
          <GlobalChannelType.Provider value={[globalChannelType, setGlobalChannelType]}>
            <GlobalUserInfo.Provider value={[globalUserInfo, setGlobalUserInfo]}>
              <SocketAdminListening />
              <Row id="liff-container" className={style.chatRoomContainer}>
                <Col className={`${style.chatRoomUserList} showChatRoomContainer`} 
                  lg={{
                    order:1,
                    span:ULSpan,
                  }}
                  md={{
                    order:1,
                    span:24,
                  }}
                  xs={{
                    order:1,
                    span:24,
                  }}
                >
                  <UserList clickTimes={clickTimes} setClickTimes={setClickTimes}></UserList>
                </Col>
                <Col className={`${style.chatRoomChatRoomContainer} showChatRoomContainer`}
                  lg={{
                    order:2,
                    span:CRSpan,
                  }}
                  md={{
                    order:3,
                    span:24,
                  }}
                  xs={{
                    order:3,
                    span:24,
                  }}
                >
                  <ChatRoomContainer></ChatRoomContainer>
                </Col>
                <Col className={`${style.chatRoomUserDetail} showChatRoomContainer`} 
                  lg={{
                    order:3,
                    span:UDSpan,
                  }}
                  md={{
                    order:2,
                    span:24,
                  }}
                  xs={{
                    order:2,
                    span:24,
                  }}
                >
                  <UserDetail></UserDetail>
                </Col>
              </Row>
            </GlobalUserInfo.Provider>
          </GlobalChannelType.Provider>
        </GlobalHasJoinedRoom.Provider>
      </GlobalJoinRoom.Provider>  
    </GlobalRoomId.Provider>
  )
}

export default ChatRoom