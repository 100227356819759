import React, { useState, useContext, useRef } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import 'bootstrap/dist/css/bootstrap.min.css'

/* 這裡是由uaa login表單整合過來的 */
import { Col, Container, Form, Row } from "react-bootstrap"
import style from '../UaaPage/login/styles/LoginIndex.module.css'
import popupStyle from './styles/popupCustomerLogin.module.css'
import './styles/popup.css'
import KidProLoginMark from '../UaaPage/login/imgs/KidProLoginMark.svg'
import divLine from '../UaaPage/login/imgs/divLine.svg'
import FBIcon from '../UaaPage/login/imgs/FBIcon.svg'
import LINEIcon from '../UaaPage/login/imgs/LINEIcon.svg'
import GoogleIcon from '../UaaPage/login/imgs/GoogleIcon.svg'
import Dropdown from 'react-bootstrap/Dropdown'
import CountryData from '../UaaPage/login/coutryCode.json'
// import { sendRegisterVeriLink } from '../components/ModuleSendEmail'
import { sha256 } from 'js-sha256'
import LoginParent from '../UaaPage/login/LoginParent'
import cart from './shopping-cart.svg'
import liff from '@line/liff/dist/lib';
import AuthContext from '../UaaPage/login/components/AuthContext'
import PopupBanner from '../UaaPage/login/imgs/PopupBanner.png';
import { useCookies } from 'react-cookie'; // 引入useCookies hook
import ReactGA from "react-ga4"; // GA4
import ReactPixel from 'react-facebook-pixel'; // FB Pixel


const PopupCustomerLogin = (props) => {

  const { authorized, setAuth, auth } = useContext(AuthContext);

  const { isFromCustomer, show, setShow, NOuaabindLine, setNOuaabindLine } = props

  // const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let loginToken = window.localStorage.getItem('loginToken');

  /* 案X清除Local Storage並登出LINE、FB */
  const clearStorage = (event) => {
    event.preventDefault();
    // 如果LINE有登入，執行LINE登出
    const isLoggedIn = liff.isLoggedIn();
    if(isLoggedIn){
      liff.logout();
    }
    // 如果FB物件存在，且為登入中，將FB登出
    if( !! window.FB ) {
      window.FB.getLoginStatus(function (response) {
        if(response.status==='connected') {
          window.FB.logout(function (response) {
            // console.log('handleFBLogout', response);
          });
        }
      });
    }
    localStorage.clear();
  }

  /*** FB Pixel 相關 ***/
  const FbPixelID = process.env.REACT_APP_FB_PIXEL_ID;
  // Pixel組態設定
  const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
  };
  ReactPixel.init(FbPixelID, {}, options);
  // ReactPixel.pageView(); // For tracking page view
  // ReactPixel.trackCustom('瀏覽【完成LINE綁定送銀卡會員－活動頁】', {content_category: 'kidpro_marketing_page', content_ids: 'GrantMembership', content_name: '完成LINE綁定送銀卡會員－活動頁' }); 
  const pixelLogout = () => {
    ReactPixel.trackCustom('【登出】', {content_category: 'kidpro_marketing_submit', content_ids: 'logout', content_name: '登出'}); 
  }
  // const clickCC02 = () => {
  //   ReactPixel.trackCustom('點選【嬰幼兒父母必修課】', {content_category: 'kidpro_online_course', content_ids: 'cc-0-2', content_name: '嬰幼兒父母必修課', content_type: 'product',
  //   currency: 'TWD', value: 3990 }); 
  // }
  /*** END FB Pixel 相關 ***/

  /* 登出時，送資料給GA4 */
  const gaLogout = () =>{
    ReactGA.event({
      category: "LogoutCategory",
      action: "logout",
      label: "Logout",
    });
    ReactGA.send({ hitType: "event" });
    // console.log('ReactGA',ReactGA);
  }

  /* 登出 */
  const logout = () => {
    gaLogout(); // 送出登出事件給GA4
    pixelLogout(); // 送出登出事件給Pixel
    // 如果FB物件存在，且為登入中，將FB登出
    if( !! window.FB ) {
      window.FB.getLoginStatus(function (response) {
        if(response.status==='connected') {
          window.FB.logout(function (response) {
            // console.log('handleFBLogout', response);
          });
        }
      });
    }    
    const isInClient = liff.isInClient();
    if( ! isInClient ) { // 以外部伺服器開啟網頁
      // 如果LINE有登入，執行LINE登出
      const isLoggedIn = liff.isLoggedIn();
      if( isLoggedIn ) {
        liff.logout();
        window.localStorage.setItem('LIFF_STORE', ''); 
      }
      // 清除所有localStorage
      localStorage.clear();
      // window.location.assign( window.location.protocol + "//" + window.location.host );
      if ( window.location.pathname.toLowerCase().indexOf('grantmembership') > -1 ) {
        // console.log('grantmembership');
        window.location.href = '/GrantMembership';
      } else {
        // console.log('/');
        window.location.href = '/AdminLogin';
      }
    } else { // 以Liff開啟網頁
      window.localStorage.removeItem('username');
      window.localStorage.removeItem('smsVeriStatus');
      window.localStorage.removeItem('phoneNumber');
      window.localStorage.removeItem('url');
      if( window.confirm("此頁面由LINE預設瀏覽器開啟，無法登出LINE。 請問是否要關閉LINE瀏覽器？") ) {
        liff.closeWindow(); // 關閉Liff
      } else {
        if ( window.location.pathname.toLowerCase().indexOf('grantmembership') > -1 ) {
          window.location.href = '/GrantMembership';
        } else {
          window.location.href = '/AdminLogin';
        }
      }
      // window.location.href = '/?openExternalBrowser=1';
    }
  }
  


  return (
    <>
     
    { ! loginToken && authorized !== true ? (
    // { ! loginToken ? (
    <>
      {/* <Button className={`btn btn-light border border-secondary ${popupStyle.loginButton}`} variant="primary" onClick={handleShow}>
       <img className={popupStyle.cartIcon} src={cart} />
       <span className={popupStyle.loginText} > 登入 / 註冊</span>
      </Button> */}

      <Modal
        show={show}
        onHide={handleClose}
        // backdrop="static"
        keyboard={false}
        centered
       >
      <Col className={popupStyle.modal}>
        {/* <Modal.Header className={popupStyle.modalHeader} closeButton>
          <Modal.Title className={popupStyle.modalTitle} ></Modal.Title>
        </Modal.Header> */}
        {/* <div className={popupStyle.modalClose} onClick={(event)=>{handleClose();clearStorage(event);}}>x</div> */}
        <div className={popupStyle.modalHeader} >
          <Modal.Title className={popupStyle.modalTitle}></Modal.Title>
          <div className={popupStyle.PopupBanner}>
            <img src={PopupBanner}></img>
           </div>
        </div>
        <Modal.Body className={popupStyle.modalBody} >
          <LoginParent className={popupStyle.modalLoginParent} isFromCustomer={isFromCustomer} handleShow={handleShow} handleClose={handleClose} setShow={setShow} NOuaabindLine={NOuaabindLine} setNOuaabindLine={setNOuaabindLine}></LoginParent>
        </Modal.Body>
      </Col>
    </Modal>
   </>
      
      ) : (
       
      <>
        <Modal
          show={show}
          onHide={handleClose}
          // backdrop="static"
          keyboard={false}
          centered
        >
          <Col className={popupStyle.modal}>
            {/* <Modal.Header className={popupStyle.modalHeader} closeButton>
              <Modal.Title className={popupStyle.modalTitle} ></Modal.Title>
            </Modal.Header> */}
            {/* <div className={popupStyle.modalClose} onClick={(event)=>{handleClose();clearStorage(event);}}>x</div> */}
            <div className={popupStyle.modalHeader} >
              <Modal.Title className={popupStyle.modalTitle}></Modal.Title>
              <div className={popupStyle.PopupBanner}>
                <img src={PopupBanner}></img>
              </div>
            </div>
            <Modal.Body className={popupStyle.modalBodyLogout} >
              <div className={style.KidProLoginTitleBox}>
                  <span className={style.KidProLoginTitle}>登出Messenger</span>
              </div>
              <button className={`${popupStyle.loginButton}`} variant="primary" onClick={logout}>
                <span className={popupStyle.loginText} >登出</span>
              </button>
            </Modal.Body>
          </Col>
        </Modal>      
      </>
      )
    }
    </>
  );
}
// }

export default PopupCustomerLogin;
