// line login encode頁面
import LoginEncode from './UaaPage/login/LoginEncode'
// 檢查使用者是否參加過活動
import CheckActivity from './UaaPage/login/CheckActivity'


import 'bootstrap/dist/css/bootstrap.min.css'
/* 由uaa整合的部分 */
import Container from 'react-bootstrap/Container'
import Navigationbar from './Components/Navigationbar'
import ThemeProvider from 'react-bootstrap/ThemeProvider'
import PopupAdminLogin from './Components/PopupAdminLogin'
import { Nav } from 'react-bootstrap'
import navStyle from './styles/navbar.module.css'

import InitFacebookSdk from './UaaPage/login/components/InitFacebookSdk'

import InitLine from './UaaPage/login/components/InitLine';

import AuthContextProvider  from './UaaPage/login/components/AuthConextProvider'


import AccountExist from './UaaPage/login/AccountExist'
import NoLineDataLogin from './UaaPage/login/NoLineDataLogin';


import ReactGA from "react-ga4"; // 引入React GA4
import { useCookies } from 'react-cookie'; // 引入useCookies hook
import { useEffect } from 'react'

// import SocketAdminConnect from '../SocketIO/Admin/SocketAdminConnect';

// wait for facebook and Line sdk before startup
InitFacebookSdk();
InitLine();


function AppAdminLogin() {
  /*** 依Login Token存在與否，決定是否導向Dashboard ***/
  const loginToken = window.localStorage.getItem('loginToken') ?? '';
  const redirToAdmin = () => {
    const params = new URLSearchParams(window.location.search);
    const refParam = params.get('ref');
    const isFromAdmin = refParam !== null && refParam.toLowerCase() === 'admin';
    console.log('isFromAdmin',isFromAdmin);
    if( !! loginToken && ! isFromAdmin ) {
      window.location.href = '/Admin?ref=AdminLogin';
    }
  }
  useEffect(() => {
    redirToAdmin();
  }, []);
  useEffect(() => {
    redirToAdmin();
  }, [loginToken]);
  /*** END 依Login Token存在與否，決定是否導向Dashboard ***/

  /*** UTM ***/
  /* Cookie設定 */
  const [cookies, setCookie] = useCookies(['cid']);
  /* GA4設定 */
  const GA4ID = process.env.REACT_APP_GA4_ID;
  const GA4Name = process.env.REACT_APP_GA4_NAME;
  // console.log('GA4ID',GA4ID);
  /* Ga4初始化 */
  try{
    const cid = 'mkc-' + Math.floor( 1000000000 * Math.random() );
    setCookie( 'cid', cid );
    ReactGA.initialize( GA4ID, {
      gaOptions: {
        name: GA4Name,
        clientId: cid,
      }
    });
    // Send pageview with a custom path
    // ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    // ReactGA.send({ hitType: "scrolls", page: window.location.pathname });
    
    // ReactGA.event('login');
    
    // Send a custom event
    // ReactGA.event({
    //   category: "testCategory",
    //   action: "testAction",
    //   label: "testLabel",
    // });
    // console.log(window.location.pathname);
    // console.log(ReactGA);
  }catch(e){
    console.log(e);
  }
  /*** END UTM ***/

  return (
    <>
      <AuthContextProvider>
        <ThemeProvider
            breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
            minBreakpoint="xxs">
              <section className={navStyle.navbarSec}>  
              {/* <Navigationbar></Navigationbar> */}
                <PopupAdminLogin></PopupAdminLogin>
              </section>
        </ThemeProvider>
      </AuthContextProvider>
    </>

  );
}
export default AppAdminLogin;
