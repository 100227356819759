import liff from '@line/liff/dist/lib';
import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { Container,Row,Col } from 'react-bootstrap';
import LoadingSpinner from './LoadingSpinner';
import style from './styles/CustomerLiffRedir.module.css'
import { useState, useEffect, useRef, useContext } from 'react';
import AuthContext from '../../../UaaLogin/UaaPage/login/components/AuthContext';
import ReactGA from "react-ga4"; // GA4

function CustomerLiffRedir() {
  /* 設定Modal 顯示與否 */
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [lineinit, setLineinit] = useState(false); // 設定Liff初始化狀態
  const [RegxpError, setRegxpError] = useState(''); // 設定錯誤訊息

  const backEndURL = process.env.REACT_APP_BACKENDURL; // Back End URL
  const apiURL = process.env.REACT_APP_APIURL; // API URL
  const fromChatRoomLogin = window.localStorage.getItem( "fromChatRoomLogin" ) ?? "false"; // 判斷是否由CustomerLiff過來
  let lineid = process.env.REACT_APP_LINE_APP_ID ? process.env.REACT_APP_LINE_APP_ID : '' ; // 由環境變數取得lineID

  /* 取得login token */
  const loginToken = !! window.localStorage.getItem('loginToken') ? window.localStorage.getItem('loginToken') : '';
  const BearerToken = 'Bearer ' + loginToken;
  /* 取得line access token */
  const lineAccessToken = !! window.localStorage.getItem('LIFF_STORE:' + lineid + ':accessToken') ? window.localStorage.getItem('LIFF_STORE:' + lineid + ':accessToken') : '';

  /* 載入頁面時popup轉圈動畫環境 */
  const Popup = (props) => {
  return (
      <>
          <>
          <Modal className={style.modalContainer}
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
              <Col className={style.modal}>
                <Modal.Body className={style.modalBody} >
                  <div style={{width:'fit-content',height:'fit-content'}}>
                    <LoadingSpinner ></LoadingSpinner>
                    <div className={style.RegxpErrorSec}>
                          <p className={style.RegxpError}>{RegxpError}</p>
                        </div>    
                  </div>
                </Modal.Body>
              </Col>
          </Modal>
        </>
      </>
    );
  }

  /* Line驗證是否登入，未登入則登入 */
  const frontEndURL = process.env.REACT_APP_FRONTENDURL;
  const liffLoginRedir = "https://" + frontEndURL + "/CustomerLiffRedir?page=homepage"; 
  const LineAuth = async (loginToken) => {
      const isLoggedIn = liff.isLoggedIn();
      // console.log('isLoggedIn',isLoggedIn);
      if( ! isLoggedIn ) {
        liff.login({ redirectUri: liffLoginRedir });
        setTimeout(() => {
          // alert('此瀏覽器無法進行LINE LIFF登入，請至首頁重新登入！');
          handleClose(); // 關閉轉圈
          // console.log('01');
          // window.location.href = "/?line_bind=false";
          window.location.href = "/";
        }, 5000 );
      } else {
        // console.log('登入成功');
        // alert('LINE登入成功！');
        handleClose(); // 關閉轉圈
        if( !! loginToken ) { // 有login token，重整後刷新登入狀態
          window.localStorage.setItem( 'loginToken', loginToken );
          window.localStorage.setItem('LIFF_STORE', true); 
        }
        // console.log('02');
        // window.location.href = "/?line_bind=false";
        window.location.href = "/";
      }
  }

  /* Line登入時，送資料給GA4 */
  const gaLineLogin = () =>{
    ReactGA.event( 'login', {
      method: 'Line登入'
    });
    ReactGA.send({ hitType: "event" });
    // console.log('gaLineLogin' );
  }

  /* 使用LINE登入API進行登入，再直接用encode()導向 */
  let line_liff_loginData = {};
  let LIFF_STOREregister = window.localStorage.getItem('LIFF_STOREregister');
  const linelogin = async () => {
    const isLoggedIn = liff.isLoggedIn();
    if( isLoggedIn === true ) {
      let redirectUrl = window.location.href;
      const accessToken = liff.getAccessToken();
      window.localStorage.setItem('lat', accessToken);  

      line_liff_loginData= {
        accessToken:accessToken,
      }

      fetch(`${process.env.REACT_APP_UAA_URL}/api/uaa/line_liff_login`, {
        method: 'POST',
        body: JSON.stringify(line_liff_loginData),
        headers: {
          'Content-Type': 'application/json'
        },
      })
      .then((r) => r.json())
      .then((result) => {
        console.log('result', result);
        if(result.status === 200){
          gaLineLogin(); // 送出Line登入事件給GA4
          let loginToken = result?.data?.loginToken || ""; 
          if( !! loginToken ) {
            window.localStorage.setItem( 'loginToken', result.data.loginToken ); // 登入成功不直接儲存，而是在判斷導向位置後才儲存
            window.localStorage.setItem( 'lineBind', true );
          }
          console.log('LINE登入成功，已綁定LINE，代碼200');
          setRegxpError('LINE登入成功！');
          
        }else if(result.statusCode === 400){
          console.log('失敗，錯誤代碼 400');
          // setRegxpError('LINE登入失敗(錯誤代碼 400)！');
          window.localStorage.setItem( 'lineBind', false );
        }else if(result.statusCode === 403){
          console.log('此LINE帳號尚未綁定，錯誤代碼 403');
          // setRegxpError('此LINE帳號尚未綁定(錯誤代碼 403)！');
          window.localStorage.setItem( 'lineBind', false );
        }else{
          console.log('LINE登入失敗！');
          // setRegxpError('LINE登入失敗！');
          window.localStorage.setItem( 'lineBind', false );
        }
        if( fromChatRoomLogin === "true" ) {
          console.log("fromChatRoomLogin is true");
          window.location.href = "/ChatRoomLogin";
        } else {
          console.log("fromChatRoomLogin is false");
          window.location.href = "/";
        }
      });
      return true;
    } else {
      console.log('L1');
      // LineAuth(loginToken);  // 重整刷新登入狀態
      return false;
    }
  }

  /* LINE登出 */
  const linelogout =  async () => {
    liff.logout();
  }

  /* 進行頁面導向 */
  // const encode = async (loginToken)=> {
  //   // console.log('lineAccessToken',lineAccessToken);
  //   if( !! lineAccessToken ) {
  //     let location = window.location.search.indexOf('page=');
  //     // console.log('location',location);
  //     // 有抓到網址liff.state 代表line登入成功
  //     if( location > -1 ) {
  //         if( window.location.search.indexOf('homepage') > -1 ) { // 點選LINE登入時返回homepage
  //           window.localStorage.removeItem('LIFF_STOREregister'); // 沒有LIFF_STOREregister為LINE登入情況
  //           // console.log({loginToken});
  //           if( !! loginToken ) { // 直接點LINE登入，且有綁定過，可存LINE login token進行登入
  //             window.localStorage.setItem( 'loginToken', loginToken );
  //             window.localStorage.setItem('LIFF_STORE', true); 
  //             handleClose(); // 關閉轉圈
  //             // window.location.href = "/CheckActivity?redir=kidpro";
  //             console.log('1');
  //             window.location.href = "/";
  //             // window.location.assign( window.location.protocol + "//" + window.location.host + "/CheckActivity?redir=kidpro" ); // 若是返回marketing首頁，會進入官網登入檢查頁面，若有"?redir=kidpro"字串則導向官網登入
  //           } else { // 直接點LINE登入，未綁定過，不存LINE login token，回首頁進入LINE未綁定表單(NoLineDataLogin)
  //             setRegxpError('您的帳號尚未綁定LINE，故無法以LINE進行登入！');
  //             setTimeout(()=>{
  //               handleClose(); // 關閉轉圈
  //               console.log('03')
  //               // window.location.href = "/?line_bind=false";
  //               // window.location.assign( window.location.protocol + "//" + window.location.host + "/" ); // 回到首頁由line login檢驗LINE綁定狀況
  //             }, 5000 );
  //           }
  //         // } else if( window.location.search.toLowerCase().indexOf('grantmembership') > -1 ) { // 在GrantMembership頁面點選LINE登入，返回GrantMembership頁面
  //         //   // console.log('導向GrantMembership');
  //         //   window.localStorage.removeItem('LIFF_STOREregister');
  //         //   if( !! loginToken ) { // 直接點LINE登入，且有綁定過，可存LINE login token進行登入
  //         //     window.localStorage.setItem( 'loginToken', loginToken );
  //         //     window.localStorage.setItem( 'LIFF_STORE', true ); 
  //         //     // checkActivityAvailable(); 
  //         //     handleClose(); // 關閉轉圈
  //         //     window.location.href = "/GrantMembership";
  //         //     // window.location.assign( window.location.protocol + "//" + window.location.host + "/CheckActivity?redir=kidpro" ); // 若是返回marketing首頁，會進入官網登入檢查頁面，若有"?redir=kidpro"字串則導向官網登入
  //         //   } else { // 直接點LINE登入，未綁定過，不存LINE login token，回GrantMembership進入LINE未綁定表單(NoLineDataLogin)
  //         //     setRegxpError('您的帳號尚未綁定LINE，故無法以LINE進行登入！');
  //         //     setTimeout(()=>{
  //         //       handleClose(); // 關閉轉圈
  //         //       window.location.href = "/GrantMembership";
  //         //       // window.location.assign( window.location.protocol + "//" + window.location.host + "/" ); // 回到首頁由line login檢驗LINE綁定狀況
  //         //     }, 5000 );
  //         //   }
  //         // } else if ( window.location.search.indexOf('SubmitPage') > -1 ) { // 在Marketing表單頁面點選LINE綁定，返回Marketing表單頁面
  //         //   // // console.log('導向SubmitPage');
  //         //   handleClose(); // 關閉轉圈
  //         //   window.location.href = "/SubmitPage";
  //         //   // window.location.assign( window.location.protocol + "//" + window.location.host + "/SubmitPage" );
  //         } else if(window.location.search.indexOf('register') > -1 ){ // 點選LINE註冊，會返回首頁進入 1.LINE未綁定表單(NoLineDataLogin) 2.LINE帳號已綁定(AccountExist)表單
  //           // console.log('register',liff.isLoggedIn());
  //           // window.localStorage.setItem( 'loginToken', loginToken ); // 回到首頁的狀況為：點選 立即註冊/透過LINE帳號註冊(若已綁定過，之後會再提示是否登入，有要登入才存login token；此階段不需LINE login token)
  //           window.localStorage.setItem('LIFF_STOREregister', true); // 點選LINE註冊狀況
  //           setTimeout(()=>{
  //             handleClose(); // 關閉轉圈
  //             if( NOuaabindLine ) { // 未綁定LINE
  //               console.log('04');
  //               // window.location.href = "/?line_bind=false";
  //             } else { // 已綁定LINE
  //               console.log('2');
  //               window.location.href = "/";
  //             }
  //           }, 5000 );
  //         } else {
  //           setTimeout(()=>{
  //             // alert('無法進行頁面導向，請重新登入！');
  //             console.log('L2');
  //             // LineAuth(loginToken);
  //           }, 5000 );
  //         }
  //     } else {
  //       setTimeout(()=>{
  //         // alert('無法取得頁面資訊，請重新登入！');
  //         console.log('L3');
  //         // LineAuth(loginToken);
  //       }, 5000 );
  //     }
  //   } else {
  //     setTimeout(()=>{
  //       // alert('LINE登入失敗，請重新登入！');
  //       console.log('L4');
  //       // LineAuth(loginToken);
  //     }, 5000 );
  //   }
  // }

  /* 取得liffID，並初始化liff物件 */
  const liffInit = () => {
    // console.log('liffInit');
    // console.log({lineid});
    liff.init({
      liffId: lineid, // Use own liffId
    })
    .then( async () => {
        await linelogin(); // 使用LINE Login API進行登入
    })
    .catch((err) => {
      // Error happens during initialization
      // console.log(err.code, err.message);
      setTimeout(()=>{
        // alert('LINE登入失敗，請至首頁重新登入！');
        handleClose(); // 關閉轉圈
        // console.log('05');
        if( fromChatRoomLogin === 'true' ) {
          console.log("fromChatRoomLogin is true");
          window.location.href = "/ChatRoomLogin";
        } else {
          console.log("fromChatRoomLogin is false");
          window.location.href = "/";
        }
        // window.location.assign( window.location.protocol + "//" + window.location.host + "/" );
      }, 5000 );
    });
  }

  useEffect(()=>{
    liffInit(); // 未登入=>liffInit=>linelogin=>LineAuth
  }, []);
  
  /* 執行載入頁面時popup轉圈動畫(預設尚未Popup) */
  return (
    <>
      <Popup></Popup>
      <div style={{textAlign:'center',display:'flex',justifyContent:'center'}}>
        <p style={{margin:'auto'}}><LoadingSpinner></LoadingSpinner></p>
      </div>
      
    </>
  )
}

export default CustomerLiffRedir