import React, { useState, useContext, useEffect } from 'react'
import { Input,Button } from 'react-chat-elements'
// import { GlobalUserData } from '../Customer';

/*** CSS ***/
import style from './styles/CustomerChatRoomSend.module.css'

/*** Import Socket Component ***/
import SocketCustomerSendMessage from '../../../SocketIO/Customer/SocketCustomerSendMessage';
import SocketCustomerJoinRoom from '../../../SocketIO/Customer/SocketCustomerJoinRoom'

function CustomerChatRoomSend(props) {
    const {globalSocket} = props;
    const [typingText, settypingText] =useState([]);

    // const GlobalUserData = useContext(GlobalUserData);
    // console.log({GlobalUserData});  

    let textArray = [
        {
          position:"left",
          type:"text",
          title:"Emre",
          text:"left message",
        },
        {
          position:"right",
          type:"text",
          title:"Emre",
          text:"right message",
        },
    ]    

    // const changeFields = (event) => {
    //     event.preventDefault();
    //     const id = event.target.id;
    //     const val = event.target.value;
    //     console.log({ typingText });
    //     settypingText({
    //         ...typingText, 
    //         position:"right",
    //         type:"text",
    //         title:"Emre",
    //         text:val,
            
    //     });
    // }

    // const whensubmit = ()=>{
    //     textArray.push(typingText);
    //     console.log('textArray',textArray);
    // };   

    return (
        <div className={style.customerChatRoomSendDiv}>
            {/* <Input
            className={style.customerChatRoomSendBtn}
            placeholder="請在此輸入訊息..."
            multiline={true}
            name="typing"
            onChange={changeFields}
            value={typingText.typingText}
            rightButtons={<Button text="送出訊息" title="Send" type="submit" onClick={whensubmit} />} //className={style.sendButton}
            /> */}
            <SocketCustomerJoinRoom globalSocket={globalSocket}/>
            <SocketCustomerSendMessage globalSocket={globalSocket}/>
        </div>
    )
}

export default CustomerChatRoomSend