import React, {useState, useEffect} from 'react'
import { Modal, Dropdown, Button, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import style from './styles/WpApplyAbsenceModal.module.css'
import dayjs from 'dayjs';
import jwt_decode from "jwt-decode";

function WpApplyAbsenceModal(props) {
    const {showApplyAbsenceModal, setShowApplyAbsenceModal, wpReadParentChildClass, selectedChildId, selectedChildName, sidHasAppliedAbsence, setSidHasAppliedAbsence, setBtnLoadings, triggerStuIdChange, requestStudents} = props;
    useEffect(()=>{console.log(wpReadParentChildClass, selectedChildId, selectedChildName)},[wpReadParentChildClass, selectedChildId, selectedChildName]);
    useEffect(()=>{console.log(sidHasAppliedAbsence)},[sidHasAppliedAbsence]);
    const [courseDataArray, setCourseDataArray] = useState([]); // 課程資料
    const [filterFieldsOnChangeState, setFilterFieldsOnChangeState] = useState({ studentId: selectedChildId, leaveClassId: wpReadParentChildClass?._id, makeupClassId: "", memo: "no memo", operator: ""}); // 篩選欄位onChange狀態
    const [makeupClassIdState, setMakeupClassIdState] =useState("");
    useEffect(()=>{console.log(filterFieldsOnChangeState)},[filterFieldsOnChangeState,selectedChildId]);
    useEffect(()=>{setFilterFieldsOnChangeState({ ...filterFieldsOnChangeState, ["studentId"]: selectedChildId,  ["makeupClassId"]: makeupClassIdState });},[selectedChildId, makeupClassIdState]);
    const [dropdownTextState, setDropdownTextState] = useState("請選擇尚未額滿的課堂進行補課");
    const [classArray, setClassArray] = useState([]);
    const [errMsg, setErrMsg] = useState("");
    useEffect(()=>{console.log(errMsg)},[errMsg]);
    const wpApiURL = process.env.REACT_APP_WPAPIURL; // API URL
    const loginToken = window.localStorage.getItem("loginToken");

    /*** 取得管理者資料 ***/
    const getAdminData = async() => {
        const decoded = await jwt_decode(loginToken);
        console.log(decoded);
        const id = await decoded?.id || "";
        const email = await decoded?.email || "";
        const atIndex = await email.indexOf('@');
        const name = await atIndex !== -1 ? email.substring(0, atIndex) : "";
        const data = {id, name, email};
        console.log(name);
        // setAdminData(data);
        setFilterFieldsOnChangeState({ ...filterFieldsOnChangeState, ["operator"]:await name });
    }
    useEffect(() => {
        getAdminData();
    }, []);
    useEffect(() => {
        getAdminData();
    }, [loginToken]);
    /*** END 取得管理者資料 ***/        

    /*** 查詢可補課的課堂 ***/
    const requestAvailableClass = async(e) => {
        e?.preventDefault();
        const courseName = wpReadParentChildClass?.name || "";
        const leaveClassId = wpReadParentChildClass?._id || "";
        // const courseName=""
        let url = "";
        if( !! courseName ) {
            url = wpApiURL + `/kidproCourse/findCourseFromCoursesWhenCompNumGt0?classId=${leaveClassId}&studentId=${selectedChildId}`;
        } else {
            setErrMsg("未能找到可補課的同類型課程。");
            return false;
        }
        console.log(url);
        const response = await fetch(
            url,
            {
                headers: {
                    "Accept": "application/json",
                },
            }
        )
        .then((res) => res.json())
        .catch((err) => {
            // alert(`課程查詢失敗！\n${err}`);
            console.log(err);
            throw new Error("Request Course failed");
        });
        console.log(response);
        // if( response.code === 200 ) {
        if( Array.isArray(response) ) {
            // const responseData = response?.data || [];
            // console.log(response);
            // console.log(wpReadParentChildClass?._id);
            // console.log(item?.students);
            console.log(selectedChildId);
            console.log(! response[0]?.students.includes(selectedChildId));       
            const filteredResponse = response.filter((item) => {
                console.log(item?.startTime, item?.flag);
                return( item?.flag > -1 && item?._id !== wpReadParentChildClass?._id && ! item?.students.includes(selectedChildId) ); // 從可補課名單中移除已刪除/選中課堂及學生本身為此堂課正式生的課堂
            }); 
                // console.log(filteredResponse);
            setCourseDataArray(filteredResponse); 
            console.log(selectedChildId, leaveClassId);
            const classDropdown = filteredResponse.map((el)=>{ // 設為Dropdown Item物件格式
                return  {
                    label: `【${el?.name}】${!!el.startTime?dayjs(el.startTime).format("YYYY-MM-DD　HH:mm"):"undefined"}～${!!el.endTime?dayjs(el.endTime).format("HH:mm"):"undefined"}　${el?.location}`,
                    key: el?._id,
                    onClick: ({ item, key, keyPath, domEvent })=>{
                        // console.log({ item, key, keyPath, domEvent });
                        // setFilterFieldsOnChangeState({ ...filterFieldsOnChangeState, ["studentId"]:selectedChildId, ["leaveClassId"]:leaveClassId, ["makeupClassId"]:key });
                        setMakeupClassIdState(key);
                        setDropdownTextState(`【${el?.name}】${!!el.startTime?dayjs(el.startTime).format("YYYY-MM-DD HH:mm"):"undefined"}～${!!el.endTime?dayjs(el.endTime).format("HH:mm"):"undefined"} ${el?.location}`);
                    }
                }
            });
            setClassArray(classDropdown);
        } else {
            console.log("Response is not an array!");
            const classDropdown =  // 設為Dropdown Item物件格式
            [{
                label: `目前無可供補課的課堂`,
                key: ``,
                // onClick: ({ item, key, keyPath, domEvent })=>{
                //     setMakeupClassIdState(key);
                //     setDropdownTextState(``);
                // }
            }];
            setClassArray(classDropdown);
        }
    }
    // useEffect(() => {
    //     requestAvailableClass();
    // }, []);
    useEffect(() => {
        requestAvailableClass();
    }, [selectedChildId, wpReadParentChildClass, sidHasAppliedAbsence]); // 請補課完成courseDataArray會改變，要改變下拉選單預設值

    /*** 請假補課 ***/
    const applyAbsence = async() => {
        enterLoading(parseInt(selectedChildId?.replace(/\D/g, "")));
        // console.log(filterFieldsOnChangeState);
        // const urlParams = new URLSearchParams(filterFieldsOnChangeState);
        const url = wpApiURL + `/kidproCourse/addStudentLMClass`;
        console.log(url);
        // await setTimeout(()=>{
        const response = await fetch(
            url,
            {
                method: "POST",
                body: JSON.stringify(filterFieldsOnChangeState),
                headers: {
                    "Accept": "*/*",
                    "Content-Type": "application/json"
                }
            }
        )
        .then((res) => res.json())
        .catch((err) => {
            console.log(err);
            alert(`【${selectedChildName}】請假/補課失敗！\nError：${response?.error}, ${response?.message}`);
            throw new Error("Apply absence failed");
        });
        //     return response;
        // }, 5000);

        console.log(response);
        // if( response?.code === 200 ) {
        if( response?.studentId && response?.leaveClassId && response?.makeupClassId ) {
            console.log(response);
            alert(`【${selectedChildName}】已完成請假/補課！`);
            setSidHasAppliedAbsence(current => [...current, filterFieldsOnChangeState?.studentId]);
        } else {
            alert(`【${selectedChildName}】請假/補課失敗！\nError：${response?.error}, ${response?.message}`);
        }
        requestStudents(null, wpReadParentChildClass?._id || "" );
        endLoading(parseInt(selectedChildId?.replace(/\D/g, "")));
    }

    const enterLoading = (index) => {
        setBtnLoadings((prevLoadings) => {
          const newLoadings = [...prevLoadings];
          newLoadings[index] = true;
          return newLoadings;
        });
    };

    const endLoading = (index) => {
        setBtnLoadings((prevLoadings) => {
            const newLoadings = [...prevLoadings];
            newLoadings[index] = false;
            return newLoadings;
        });
    }

    return (
        <Modal
            id="WpApplyAbsenceModal"
            className={style.wpApplyAbsenceModal}
            getContainer={document.getElementById("WpApplyAbsenceModalSpace")}
            centered
            open={showApplyAbsenceModal}
            onOk={() => {
                applyAbsence();
                setDropdownTextState("請選擇尚未額滿的課堂進行補課"); // 初始化下拉預設選項
                setShowApplyAbsenceModal(false);
            }}
            okText="確認"
            onCancel={() => {
                setShowApplyAbsenceModal(false);
                setDropdownTextState("請選擇尚未額滿的課堂進行補課"); // 初始化下拉預設選項
            }}
            cancelText="取消"
        >
            <div id="WpApplyAbsenceModalContainer">
                <h5 className={style.wpApplyAbsenceModalH5}>{`【${selectedChildName}】請假/補課`}</h5>
                <div className={`${style.wpApplyAbsenceModalDiv} ${style.wpApplyAbsenceModalMakeUp}`}>
                    <span className={style.wpApplyAbsenceModalMakeUpLabel}>{"請假課堂："}</span>
                    <span className={style.wpApplyAbsenceModalMakeUpText}>{`【${wpReadParentChildClass?.name}】${!!wpReadParentChildClass.startTime?dayjs(wpReadParentChildClass.startTime).format("YYYY-MM-DD HH:mm"):"undefined"}～${!!wpReadParentChildClass.endTime?dayjs(wpReadParentChildClass.endTime).format("HH:mm"):"undefined"} ${wpReadParentChildClass.location}`}</span>
                </div>
                <div id="wpApplyAbsenceModalDropdown" className={`${style.wpApplyAbsenceModalDiv} ${style.wpApplyAbsenceModalApplyAbsence}`}>
                    <span className={style.wpApplyAbsenceModalMakeUpLabel}>{"補課課堂："}</span>
                    <Dropdown
                        className={`${style.wpApplyAbsenceModalDropdown}`}
                        menu={{ items: classArray }}
                        placement="bottom"
                    >
                        <Button>
                            <Space>
                                {dropdownTextState}
                                <DownOutlined />
                            </Space>
                        </Button>    
                    </Dropdown>
                    {errMsg ? (<div className={style.wpApplyAbsenceModalErrMsg} >{errMsg}</div>) : (<></>)}
                </div>
            </div>
        </Modal>
    )
}

export default WpApplyAbsenceModal