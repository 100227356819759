import React, { useContext, useState, useEffect } from 'react';
import { SocketProvider } from 'socket.io-react';
import io from 'socket.io-client';
import CustomerDemo from '../../Pages/Customer/CustomerDemo';
import liff from '@line/liff';

export const GlobalSocket = React.createContext({});

function SocketCustomerConnect() {
  const loginToken = window.localStorage.getItem('loginToken') ? window.localStorage.getItem('loginToken') : "";
  const liffId = process.env.REACT_APP_LINE_APP_ID;
  const host = process.env.REACT_APP_HOST;
  const isWss = process.env.REACT_APP_ISWSS;
  const [globalSocket, setGlobalSocket] = useState(null);
  const [userData, setUserData] = useState({});

  useEffect(() => { window.localStorage.setItem('fromChatRoomLogin', "false") }, []);

  /*** LIFF登入後，Socket才進行連線 ***/
  const initLiff = async () => {
    const init = await liff.init({
      liffId: liffId,
      permissions: ["profile"]
    })
    .then(() => {
      const isLoggedIn = liff.isLoggedIn();
      if(isLoggedIn) {
        const accessToken = liff.getAccessToken();
        // console.log('accessToken', accessToken);
        if( !! accessToken ) {
          /* Socket連線 */
          console.log(loginToken);
          const socket = io.connect(`${isWss ? 'wss' : 'ws'}://${host}/ws/line-chat`, {
            transports: ['websocket'],
            auth: {
              LineAccessToken: accessToken,
              Authorization: `Bearer ${loginToken}`,
            },
          }); /* END Socket連線 */
          console.log(socket);
          // /* Socket監聽事件 */
          // socket.on("joinRoom", (data) => {
          //   console.log(data);
          // });
          // socket.on("message", (data) => {
          //   console.log(data);
          //   if (data.type === 'photo') {
          //     const image = document.getElementById('messagePhoto');
          //     image.setAttribute('src', data.data.url);
          //     const image2 = document.getElementById('thumbnailPhoto');
          //     image2.setAttribute('src', data.data.thumbnailUrl);
          //   }
          // });
          // socket.on('unReadMessage', (data) => {
          //   console.log('unReadMessage', data);
          // })
          // socket.on("error", (data) => {
          //   console.error(data);
          //   // if(data.code === 'logout'){
          //   //     liff.logout();
          //   // }
          // }); /* END Socket監聽事件 */
          setGlobalSocket(socket);
        }
      }
    })
    .catch((err) => {
      console.log(err);
    });
  } 
  useEffect(() => {
    initLiff();
  }, [loginToken, host, isWss, liffId]);
  /*** END LIFF登入後，Socket才進行連線 ***/

  if ( ! globalSocket ) {
    return <CustomerDemo />
  }
  
  return (
    <GlobalSocket.Provider value={globalSocket}>
      <CustomerDemo />
    </GlobalSocket.Provider>
  )
}

export default SocketCustomerConnect;