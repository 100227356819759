import React, { useState, useEffect, useContext } from 'react';
import { GlobalSocket } from './SocketCustomerConnect';
import { socketConnect } from 'socket.io-react';
import style from "./styles/SocketCustomer.module.css";
import liff from '@line/liff';
import CryptoJS  from 'crypto-js';

function SocketCustomerJoinRoom(props) {
    // const globalSocket = useContext(GlobalSocket);
    const {globalSocket} = props;

    /*** 取得加密的使用者資料 ***/
    let defaultUserData = null;
    const[userData, setUserData] = useState({});
    const[joinRoom, setJoinRoom] = useState(false);
    /* 
      userData = {
        const userStatus = defaultUserData?.userStatus || "【一般使用者】";
        const firstName = defaultUserData?.firstName || "";
        const lastName = defaultUserData?.lastName || userStatus ||  "【未連線使用者】"; // displayName
        const email = defaultUserData?.email || "";
        const phone = defaultUserData?.phone || "";
        const lineId = defaultUserData?.lineId || ""; // userId
        const isLineBind = defaultUserData?.isLineBind || false;
        const avatar = defaultUserData?.avatar || "";
        const statusMessage = defaultUserData?.statusMessage || "";
        const date = data?.date || new Date();
      }
    */
    const lineAppId = process.env.REACT_APP_LINE_APP_ID;
    const cpo = window.localStorage.getItem("cpo") ? window.localStorage.getItem("cpo") : "";
    const getCipherUserData = async() => {
        const accessToken = window.localStorage.getItem( "LIFF_STORE:" + lineAppId + ":accessToken" ) ? window.localStorage.getItem( "LIFF_STORE:" + lineAppId + ":accessToken" ) : "";
        if( !! accessToken && !! cpo ) {
            try {
                console.log(cpo);
                console.log(accessToken);
                const userDataJson  = CryptoJS.AES.decrypt( cpo, `${accessToken}` );
                // console.log(userDataJson);
                const userDataUTF8 = userDataJson?.toString(CryptoJS.enc.Utf8);
                // const userDataUTF8 = CryptoJS.enc.Utf8.parse(userDataJson);
                const userDataDecode = JSON.parse( userDataUTF8 );
                // setUserData(userDataDecode);

                // const userDataJson  = CryptoJS.AES.decrypt(cpo, accessToken);
                // const userDataDecode = JSON.parse(userDataJson?.toString(CryptoJS.enc.Utf8));
                
                setUserData( userData => ({...userData, userDataDecode}));
                console.log(userData);
                defaultUserData = userDataDecode;
                return defaultUserData;
            } catch (e) {
                console.log(e);
                return false;
            }   
        }   
    }
    useEffect(() => {
        getCipherUserData();
    }, [cpo])
    /*** END 取得加密的使用者資料 ***/   
    
    const liffId = process.env.REACT_APP_LINE_APP_ID;
    const customerJoinRoom = async() => {
        if( ! joinRoom ) {
            defaultUserData = await getCipherUserData();
            // const roomId = process.env.REACT_APP_ROOMID;
            await liff.init({
                liffId: liffId, // Use own liffId
            })
            .then(() => {
                const isLoggedIn = liff.isLoggedIn();
                if( !! isLoggedIn && !! globalSocket ) {
                    console.log({globalSocket});
                    console.log({defaultUserData});
                    const lineId = defaultUserData?.lineId || "";
                    console.log({lineId});
                    globalSocket.emit('joinRoom', {
                        'lineAccessToken': liff.getAccessToken(), 
                        // 'roomId' : lineId
                        // Begin reading the message ID, and if it is not defined, read the last message
                        // messageId: '63e22131d1a6e4c8a66fb902'
                    })
                    console.log('emit join room!');
                }
            })
            .catch((err) => {
                console.log(err);
            });
        }
    }
    useEffect(() => {
        customerJoinRoom();
    }, [globalSocket]);
    // useEffect(() => {
    //     customerJoinRoom();
    // }, [globalSocket]);
    // useEffect(() => {
    //     customerJoinRoom();
    // }, [cpo]);
    // return (
    //     <button className={style.socketCustomerBtn} onClick={customerJoinRoom}>
    //         加入聊天室
    //     </button>
    // )
}

export default socketConnect(SocketCustomerJoinRoom);